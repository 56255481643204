import { useContext, useEffect, useState } from "react";
import MapContext from "./map.context";

type Props = {
  lat: number;
  lng: number;
  circleOptions?: google.maps.CircleOptions;
  isVisible?: boolean;
};

const Circle = ({ lat, lng, circleOptions, isVisible = true }: Props) => {
  const map = useContext(MapContext);
  const [circle, setCircle] = useState<google.maps.Circle | null>(null);

  useEffect(() => {
    const coords = { lat, lng };

    const newCircle = new google.maps.Circle({
      map: isVisible ? map : null,
      center: coords,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.3,
      ...circleOptions,
    });

    setCircle(newCircle);
  }, [map]);

  useEffect(() => {
    if (circle) circle.setMap(isVisible ? map : null);
  }, [isVisible]);

  return null;
};

export default Circle;
