import { getPropertyScoreBadge } from "@mc/common/blocks/property-score/property-score.utils";
import { Badge } from "@mc/components/badge/badge.styles";
import { SpaceBetween } from "@mc/components/core/spacing.styles";
import { DataList } from "@mc/components/tables/data-list";

const PropertyScore = ({ propertyScore }: any) => {
  const overallBadge = getPropertyScoreBadge({ propertyScoreOverallValue: 78 });

  const propertyScoreValues = [
    {
      label: "Convenience",
      score: 18,
      badge: getPropertyScoreBadge({ propertyScoreValue: 18 }),
    },
    {
      label: "Options available",
      score: 16,
      badge: getPropertyScoreBadge({ propertyScoreValue: 16 }),
    },
    {
      label: "Character",
      score: 14,
      badge: getPropertyScoreBadge({ propertyScoreValue: 14 }),
    },
    {
      label: "Property",
      score: 12,
      badge: getPropertyScoreBadge({ propertyScoreValue: 12 }),
    },
  ];

  return (
    <>
      <DataList
        data={[
          {
            label: "Overall",
            value: (
              <SpaceBetween>
                <span>{propertyScore}</span>
                <Badge>{overallBadge.label}</Badge>
              </SpaceBetween>
            ),
            subValues: {
              defaultCollapsed: true,
              values: propertyScoreValues.map((v) => ({
                label: v.label,
                value: (
                  <SpaceBetween>
                    <span>{v.score}</span>
                    <Badge {...v.badge.badgeProps}>{v.badge.label}</Badge>
                  </SpaceBetween>
                ),
              })),
            },
          },
        ]}
      />
    </>
  );
};

export default PropertyScore;
