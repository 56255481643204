import { useState, ReactNode } from "react";
import { fontSize14, fontSize16 } from "../core/typography.styles";
import * as Styled from "./data-list.styles";
import { DataListCellProps } from "./data-list.styles";

type LabelAndValue = {
  label: string;
  value?: ReactNode;
  valueSet?: ReactNode[];
};

export type DataListData = LabelAndValue & {
  subValues?: {
    defaultCollapsed: boolean;
    values: LabelAndValue[];
  };
  isEmphasised?: boolean;
};

type Props = {
  headers?: string[];
  data: (DataListData | undefined)[];
};

const Rows = (data: DataListData) => {
  const [isCollapsed, setIsCollapsed] = useState(data?.subValues?.defaultCollapsed);

  const isClickable = !!data.subValues;

  const defaultLabelFontProps: Pick<
    DataListCellProps,
    "fontWeight" | "fontSize" | "isDarkGrey" | "isUnderline" | "isLargeCell"
  > = {
    fontWeight: "normal",
    fontSize: fontSize14,
    isDarkGrey: true,
    isUnderline: false,
    isLargeCell: false,
  };

  const firstCellFontProps: Pick<
    DataListCellProps,
    "fontWeight" | "fontSize" | "isDarkGrey" | "isUnderline" | "isLargeCell"
  > = (() => {
    switch (true) {
      case isClickable && !data.isEmphasised:
        return {
          fontWeight: "medium",
          fontSize: fontSize16,
          isDarkGrey: false,
          isUnderline: true,
          isLargeCell: false,
        };

      case isClickable && data.isEmphasised:
        return {
          fontWeight: "medium",
          fontSize: fontSize16,
          isDarkGrey: false,
          isUnderline: true,
          isLargeCell: true,
        };

      case !isClickable && data.isEmphasised:
        return {
          fontWeight: "medium",
          fontSize: fontSize16,
          isDarkGrey: false,
          isUnderline: false,
          isLargeCell: true,
        };

      case !isClickable && !data.isEmphasised:
      default:
        return defaultLabelFontProps;
    }
  })();

  if (data.value && data.valueSet) {
    console.error('"value" and "valueSet" props can not be both provided in DataList.');
    return null;
  }

  const placeholder = "-";

  return (
    <>
      <Styled.DataListRow>
        <Styled.DataListCell
          {...firstCellFontProps}
          onClick={() => setIsCollapsed(!isCollapsed)}
          isClickable={isClickable}
        >
          {data.label}
        </Styled.DataListCell>

        {data.value && (
          <Styled.DataListCell fontWeight="medium" isLargeCell={data.isEmphasised}>
            {data.value ?? placeholder}
          </Styled.DataListCell>
        )}

        {data.valueSet &&
          data.valueSet.map((v, i) => (
            <Styled.DataListCell key={i} fontWeight="medium" isLargeCell={data.isEmphasised}>
              {v ?? placeholder}
            </Styled.DataListCell>
          ))}
      </Styled.DataListRow>

      {!isCollapsed &&
        data.subValues?.values.map((sv, i) => (
          <Styled.DataListRow key={i}>
            <Styled.DataListCell {...defaultLabelFontProps} isIndented>
              {sv.label}
            </Styled.DataListCell>

            {sv.value && <Styled.DataListCell fontWeight="medium">{sv.value ?? placeholder}</Styled.DataListCell>}

            {sv.valueSet &&
              sv.valueSet.map((v, j) => (
                <Styled.DataListCell key={j} fontWeight="medium">
                  {v ?? placeholder}
                </Styled.DataListCell>
              ))}
          </Styled.DataListRow>
        ))}
    </>
  );
};

export const DataList = ({ data, headers }: Props) => {
  return (
    <Styled.DataList>
      {headers && (
        <Styled.DataListGroup>
          <Styled.DataListRow>
            {headers.map((header) => (
              <Styled.DataListCell key={header} isDarkGrey fontSize={fontSize14}>
                {header}
              </Styled.DataListCell>
            ))}
          </Styled.DataListRow>
        </Styled.DataListGroup>
      )}
      {data.map((d) => {
        if (!d) return null;
        return (
          <Styled.DataListGroup key={d.label} blackBorders={d.isEmphasised}>
            <Rows {...d} />
          </Styled.DataListGroup>
        );
      })}
    </Styled.DataList>
  );
};
