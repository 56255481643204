import { rem } from "@mc/components/core/styles";
import { anchorLinkBase, fontSize14, fontSize14LineHeight } from "@mc/components/core/typography.styles";
import styled from "styled-components";

type GenericLogoContainerProps = {
  center?: boolean;
};
export const GenericLogoContainer = styled.div<GenericLogoContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ center }) => center && `justify-content: center`}
`;

export const LogoContainer = styled.div`
  margin-left: ${rem(8)};
  width: ${rem(100)};
`;

export const FooterAnchor = styled.a`
  ${anchorLinkBase}
  font-size: ${fontSize14};
  line-height: ${fontSize14LineHeight};
`;
