import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ROOT } from "../routes.constants";

export default function useLogout() {
  const navigate = useNavigate();

  const logout = useCallback(() => {
    window.localStorage.removeItem(process.env.REACT_APP_STAFF_TOKEN_KEY_NAME_IN_LOCALSTORAGE as string);

    navigate(ROOT);
  }, [navigate]);

  return { logout };
}
