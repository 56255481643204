import { Flex } from "@mc/components/grid/grid.styles";
import { Modal, useModalState } from "@mc/components/modal/modal";
import { useEffect, useState } from "react";
import PurchaseRadio from "./purchase-radio";
import { SpaceBetween } from "@mc/components/core/spacing.styles";
import { ButtonLink, PrimaryLoadingButton } from "@mc/components/buttons";
import {
  CreateActivePortfolioPropertyMutation,
  CreateActivePortfolioPropertyMutationVariables,
  GetPurchasesQuery,
} from "@mc/common/gql/types";
import { H2 } from "@mc/components/core/typography.styles";
import useActiveProperty from "./use-active-portfolio";
import useGetPurchases from "./use-get-purchases";
import { useMutation } from "@apollo/client";
import { CREATE_PROPERTY } from "./active-portfolio.gql";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ACTIVE_PORTFOLIO_PROPERTY_VIEW } from "../../../routes.constants";

interface FormData {
  property: string;
}

const CreatePropertyModal = () => {
  const { isVisible, open, close } = useModalState();
  const { data, loading } = useActiveProperty();
  const { data: purchaseData, loading: purchaseLoading } = useGetPurchases();
  const [createProperty, { loading: creating }] = useMutation<
    CreateActivePortfolioPropertyMutation,
    CreateActivePortfolioPropertyMutationVariables
  >(CREATE_PROPERTY);
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();
  const [purchaseProperty, setPurchaseProperty] = useState<GetPurchasesQuery["client"]["purchases"] | undefined>(
    undefined
  );
  useEffect(() => {
    const activeIds = data?.activePortfolio?.properties.map((active) => active.purchaseId) || [];

    const returnedData = purchaseData?.client?.purchases.filter((property) => !activeIds.includes(property._id));

    setPurchaseProperty(returnedData);
  }, [purchaseLoading, loading, purchaseData, data]);

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>();
  const onSubmit = handleSubmit(async (data) => {
    await createProperty({
      variables: {
        createActivePortfolioPropertyInput: {
          purchaseId: data.property,
        },
      },
    }).then((propertySave) => {
      if (propertySave.data?.createActivePortfolioProperty._id) {
        const proprtyId = propertySave.data?.createActivePortfolioProperty._id;
        clientId &&
          navigate(
            generatePath(ACTIVE_PORTFOLIO_PROPERTY_VIEW, {
              clientId,
              propertyId: `${proprtyId}`,
            })
          );
      }
    });
  });
  return (
    <Flex justifyContent="flex-end">
      {isVisible && (
        <Modal isVisible={true} close={close}>
          <form onSubmit={onSubmit}>
            <div>
              <H2 isPrimary={true}>Purchase list</H2>
              <PurchaseRadio data={purchaseProperty} register={register} />
              <SpaceBetween $marginTop={40}>
                <ButtonLink onClick={() => close()}>Cancel</ButtonLink>
                <PrimaryLoadingButton isLoading={creating} isDisabled={!isValid}>
                  Save
                </PrimaryLoadingButton>
              </SpaceBetween>
            </div>
          </form>
        </Modal>
      )}
      <ButtonLink onClick={open}>Add</ButtonLink>
    </Flex>
  );
};

export default CreatePropertyModal;
