import { gql } from "@apollo/client";
import { SHORTLIST_PROPERTY_STAGES_FRAGMENT } from "../property.gql";

export const PUBLISH_PURCHASE_TRACKER_STAGE_OR_ITEM = gql`
  mutation PublishPurchaseTrackerStageOrItem(
    $shortlistPropertyId: String!
    $stageType: PurchaseTrackerStageType
    $itemType: PurchaseTrackerItemType
    $triggerEmail: Boolean
  ) {
    publishPurchaseTrackerStageOrItem(
      shortlistPropertyId: $shortlistPropertyId
      stageType: $stageType
      itemType: $itemType
      triggerEmail: $triggerEmail
    ) {
      _id
      ...ShortlistPropertyStagesFragment
    }
  }
  ${SHORTLIST_PROPERTY_STAGES_FRAGMENT}
`;
