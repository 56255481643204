import styled from "styled-components";
import { Link } from "react-router-dom";
import { rem } from "../core/styles";
import { black } from "../core/colours.styles";
import { anchorLinkBase, fontSize14, fontWeightMedium } from "../core/typography.styles";

export const BreadcrumbsStyled = styled.nav`
  margin-bottom: ${rem(40)};

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;
  }
`;

export const BreadcrumbGapStyled = styled.div`
  display: inline-block;
  font-size: ${fontSize14};
  font-weight: ${fontWeightMedium};
  padding: ${rem(1)};
  margin: 0 ${rem(4)};
`;

export const BreadcrumbLinkStyled = styled(Link)`
  ${anchorLinkBase};
  display: inline-block;
  font-size: ${fontSize14};
  padding: ${rem(1)};
`;

export const BreadcrumbStyled = styled.span`
  color: ${black};
  display: inline-block;
  font-size: ${fontSize14};
  padding: ${rem(1)};
`;
