import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getAuthToken, removeAuthToken } from "../shared/auth-token";

const cache = new InMemoryCache({
  typePolicies: {
    DateType: { merge: true },
    Money: { merge: true },
    Percentage: { merge: true },
    Address: { merge: true },
    CoreLogicPropertyAdditionalAttributes: { merge: true },
    CoreLogicPropertyCoreAttributes: { merge: true },
    CoreLogicPropertyImages: { merge: true },
    CoreLogicPropertyImagesPropertyPhoto: { merge: true },
    CoreLogicPropertyLocation: { merge: true },
    CoreLogicPropertyOccupancy: { merge: true },
    CoreLogicPropertySalesLast: { merge: true },
    DaisyProperty: { merge: true },
    DaisySummary: { merge: true },
    PropertyOtm: { merge: true },
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken() || "";
  const options: {
    Authorization?: string;
  } = {};
  if (token) {
    options["Authorization"] = `Bearer ${token}`;
  }
  return {
    headers: {
      ...headers,
      ...options,
    },
  };
});

const errorLink = onError((error) => {
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (!message.includes("Access denied")) {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(path)}`
        );
      } else {
        // redirect to login page
        removeAuthToken();
        window.location.assign("/login");
      }
    });

  if (networkError) console.error(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  cache: cache,
  connectToDevTools: process.env.NODE_ENV !== "production",
  link: ApolloLink.from([
    authLink,
    errorLink,
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URI,
      credentials: "same-origin",
    }),
  ]),
});
