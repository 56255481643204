import { PurchaseTrackerItemStatus } from "@mc/common/gql/types";
import { ReactNode } from "react";
import { InlineSvgIds } from "../icons/Icon";
import IconSuccess from "../icons/icon-success";

import {
  StepList,
  StepItem as StepItemStyled,
  StepItemLink as StepItemLinkStyled,
  StepItemButton,
  StepItemLabel,
  StepAccordionToggle as StepAccordionToggleStyled,
  StepHeading,
  StepContentWrapper,
  StepExtraText,
  StepItemContainer,
  StepItemIcon,
} from "./step-list.styles";

type Props = {
  children: ReactNode;
  isActive?: boolean;
  isComplete?: boolean;
  isClickable?: boolean;
  icon?: InlineSvgIds;
  backgroundColour?: string;
  purchaseTrackerItemStatus?: PurchaseTrackerItemStatus;
  extraText?: string;
};

const handleStepListStateIcon = (state?: PurchaseTrackerItemStatus): InlineSvgIds => {
  switch (state) {
    case PurchaseTrackerItemStatus.PENDING:
    case PurchaseTrackerItemStatus.NOT_STARTED:
      return "timeline-pending";

    case PurchaseTrackerItemStatus.ACTIVE:
    case PurchaseTrackerItemStatus.ACTIVE_REQUIRED_ACTION:
    case PurchaseTrackerItemStatus.READY_FOR_CLIENT:
      return "timeline-active";

    case PurchaseTrackerItemStatus.DONE:
    case PurchaseTrackerItemStatus.IN_PROGRESS:
      return "timeline-done";

    case PurchaseTrackerItemStatus.COMPLETE:
      return "timeline-complete";

    default:
      return "timeline-pending";
  }
};

export const StepItem = ({
  children,
  isActive,
  isComplete,
  icon = "tick",
  backgroundColour,
  extraText,
  purchaseTrackerItemStatus,
}: Props) => (
  <StepItemStyled
    $isActive={isActive}
    $isComplete={isComplete}
    $backgroundColour={backgroundColour}
    purchaseTrackerItemStatus={purchaseTrackerItemStatus}
    $hasBackground={!!extraText}
  >
    {purchaseTrackerItemStatus && (
      <StepItemIcon id={handleStepListStateIcon(purchaseTrackerItemStatus) || icon} height={20} width={20} />
    )}
    <StepItemContainer>
      {children}
      {extraText && <StepExtraText>{extraText}</StepExtraText>}
    </StepItemContainer>
  </StepItemStyled>
);

type StepAccordionToggleProps = {
  children: ReactNode;
  title: string;
  isComplete?: boolean;
  defaultExpanded?: boolean;
  hasBorderBottom?: boolean;
};
const StepAccordionToggle = ({
  children,
  title,
  isComplete,
  defaultExpanded,
  hasBorderBottom,
}: StepAccordionToggleProps) => (
  <StepAccordionToggleStyled
    hasBorderBottom={hasBorderBottom}
    defaultExpanded={defaultExpanded}
    labelComponent={
      <>
        {isComplete && <IconSuccess />}
        <StepHeading>{title}</StepHeading>
      </>
    }
  >
    <StepContentWrapper>{children}</StepContentWrapper>
  </StepAccordionToggleStyled>
);

type StepItemLinkProps = {
  to: string;
  label: string;
  isClickable?: boolean;
};
const StepItemLink = ({ to, label, isClickable = true }: StepItemLinkProps) =>
  isClickable ? (
    <StepItemLinkStyled to={to}>{label}</StepItemLinkStyled>
  ) : (
    <StepItemLabel $isActive={isClickable}>{label}</StepItemLabel>
  );

export { StepHeading, StepList, StepItemLink, StepItemButton, StepItemLabel, StepAccordionToggle };
