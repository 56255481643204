import styled from "styled-components/macro";
import { darkGrey, black, scaleYellow, scaleGreen, scaleRed } from "../core/colours.styles";

const SliderWrap = styled.div`
  text-align: left;
  width: 100%;
  max-width: 100%;
  color: ${darkGrey};
`;

const RangeInput = styled.input`
  -webkit-appearance: none;
  z-index: 1000 !important;
  border-radius: 6px;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(255, 255, 255, 0.3);
  background: transparent;
  height: 14px;
  border-radius: 13px;
  width: 100%;
  background: linear-gradient(90deg, ${scaleGreen} 0%, ${scaleYellow} 50%, ${scaleRed} 100%);

  &:active {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    z-index: 1000 !important;
    cursor: pointer;
    width: 20px;
    height: 10px;
    border-bottom: solid 10px ${black};
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    margin-top: 28px !important;
    position: relative;
  }

  &::-moz-range-thumb {
    cursor: pointer;
    width: 60px;
    height: 30px;
    border-bottom: solid 30px ${black};
    border-left: solid 30px transparent;
    border-right: solid 30px transparent;
  }

  &::-ms-thumb {
    cursor: pointer;
    width: 60px;
    height: 30px;
    border-bottom: solid 30px ${black};
    border-left: solid 30px transparent;
    border-right: solid 30px transparent;
  }
`;

const Label = styled.label`
  color: ${black};
`;

export { SliderWrap, RangeInput, Label };
