import { BreadcrumbProps as Props } from "./breadcrumb.constants";
import * as Styled from "./breadcrumbs.style";

const Breadcrumbs = ({ breadcrumbs, homePath }: Props) => (
  <Styled.BreadcrumbsStyled aria-label="Breadcrumb">
    <ul>
      <li>
        {breadcrumbs.length > 0 ? (
          <Styled.BreadcrumbLinkStyled to={homePath || "/"}>Home</Styled.BreadcrumbLinkStyled>
        ) : (
          <Styled.BreadcrumbStyled aria-current="page">Home</Styled.BreadcrumbStyled>
        )}
      </li>
      {breadcrumbs.map((b) => {
        return (
          <li key={b.href}>
            <Styled.BreadcrumbGapStyled>/</Styled.BreadcrumbGapStyled>
            {b.href === "" ? (
              <Styled.BreadcrumbStyled aria-current="page">{b.label}</Styled.BreadcrumbStyled>
            ) : (
              <Styled.BreadcrumbLinkStyled to={b.href}>{b.label}</Styled.BreadcrumbLinkStyled>
            )}
          </li>
        );
      })}
    </ul>
  </Styled.BreadcrumbsStyled>
);

export default Breadcrumbs;
