import styled from "styled-components/macro";
import { mediaUp, rem } from "@mc/components/core/styles";
import { fontSize16 } from "@mc/components/core/typography.styles";

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${rem(64)};
`;

export const LoginLogo = styled.div`
  margin-bottom: ${rem(56)};
  img {
    width: ${rem(50)};
  }

  span {
    font-size: ${fontSize16};
    margin-left: ${rem(8)};
  }
`;

export const LoginImage = styled.div`
  text-align: center;
  img {
    max-width: ${rem(200)};
    margin-top: -${rem(70)};
  }

  ${mediaUp.sm`
    img {
      width: 90%;
      max-width: ${rem(440)};
    }
  `}
`;
