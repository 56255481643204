import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { rem } from "../core/styles";
import { black, darkGrey, green, softGrey, white } from "../core/colours.styles";
import AccordionToggle from "../accordion/accordion-toggle";
import {
  anchorLinkBase,
  fontSize16,
  fontWeightSemiBold,
  fontWeightNormal,
  H4,
  PStyledSecondary,
} from "../core/typography.styles";
import Icon from "../icons/Icon";
import { PurchaseTrackerItemStatus } from "@mc/common/gql/types";

export const StepList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const getSvgFillColourByStatus = (purchaseTrackerItemStatus?: PurchaseTrackerItemStatus) => {
  switch (purchaseTrackerItemStatus) {
    case PurchaseTrackerItemStatus.COMPLETE:
      return `
        fill: ${green};
      `;

    case PurchaseTrackerItemStatus.ACTIVE:
    case PurchaseTrackerItemStatus.ACTIVE_REQUIRED_ACTION:
    case PurchaseTrackerItemStatus.DONE:
    case PurchaseTrackerItemStatus.PENDING:
    default:
      return `
        fill: ${black};
      `;
  }
};

type StepItemProps = {
  purchaseTrackerItemStatus?: PurchaseTrackerItemStatus;
  $isActive?: boolean;
  $isComplete?: boolean;
  $backgroundColour?: string;
  $hasBackground?: boolean;
};
export const StepItem = styled.li<StepItemProps>`
  margin-bottom: ${rem(8)};
  padding: ${rem(8)} 0 ${rem(8)} ${rem(48)};
  position: relative;
  ${({ $hasBackground }) =>
    $hasBackground &&
    `
      background-color: ${softGrey};
      border-radius: ${rem(2)};
  `}

  &:before {
    content: "";
    background-color: ${white};
    border-radius: ${rem(20)};
    display: inline-block;
    width: ${rem(20)};
    height: ${rem(20)};
    vertical-align: middle;
    position: absolute;
    left: ${rem(10)};
    z-index: 2;
  }
  &:after {
    content: "";
    background-color: ${black};
    display: block;
    position: absolute;
    width: 2px;
    bottom: -${rem(16)};
    top: ${rem(8)};
    left: ${rem(19)};
    z-index: 1;
  }

  &:nth-last-child(1) {
    top: -${rem(2)};
  }

  &:last-child:after {
    display: none;
  }

  ${({ $isActive }) =>
    $isActive &&
    `
      &:before {
        content: '';
        background-color: ${black};
      }
      ${StepItemLink}, ${StepItemButton}, ${StepItemLabel} {
        font-weight: ${fontWeightSemiBold};
      }
  `}

  ${({ $isComplete }) =>
    $isComplete &&
    `
      &:before {
        content: '';
        background-color: ${black};
      }
  `}

  ${({ $backgroundColour }) =>
    $backgroundColour &&
    `
      &:before {
        content: '';
        background-color: ${$backgroundColour};
      }
  `}

  svg {
    position: absolute;
    left: ${rem(4)};
    z-index: 3;

    use {
      ${({ purchaseTrackerItemStatus }) => getSvgFillColourByStatus(purchaseTrackerItemStatus)};
    }
  }
`;

type StepItemLabelProps = {
  $isActive?: boolean;
};
export const StepItemLabel = styled.span<StepItemLabelProps>`
  font-size: ${fontSize16};
  font-weight: ${fontWeightNormal};
  line-height: inherit;
  text-decoration: none;

  ${({ $isActive = true }) =>
    !$isActive &&
    `
    cursor: not-allowed;
    opacity: 0.3;
  `}
`;

export const StepItemLink = styled(Link)`
  ${anchorLinkBase}
  font-weight: ${fontWeightNormal};
  line-height: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

export const StepItemButton = styled.button`
  ${anchorLinkBase}
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-weight: ${fontWeightNormal};
  line-height: inherit;
  text-decoration: none;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;

export const StepAccordionToggle = styled(AccordionToggle)`
  padding-left: ${rem(16)};
`;

export const StepContentWrapper = styled.div`
  padding: 0 ${rem(8)};
`;

export const StepHeading = styled(H4).attrs({
  as: "h2",
  marginBottom: 0,
})``;

export const StepExtraText = styled(PStyledSecondary)`
  color: ${darkGrey};
  margin-bottom: 0;
  margin-right: ${rem(12)};
  white-space: nowrap;
`;

export const StepItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StepItemIcon = styled(Icon)`
  margin-left: ${rem(6)};
  border-radius: 50%;
`;
