import { useMutation } from "@apollo/client";
import {
  MultipleRangesSettingFragmentFragment,
  UpdateMultipleRangesSettingsMutation,
  UpdateMultipleRangesSettingsMutationVariables,
} from "@mc/common/gql/types";
import { ButtonLink, PrimaryLoadingButton } from "@mc/components/buttons";
import { NumberInput } from "@mc/components/inputs/inputs.styles";
import { isNumber } from "lodash";
import { useCallback } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { UPDATE_MULTIPLE_RANGES_SETTINGS } from "./setting.gql";
import { OneLineWithGap, SettingTable } from "./settings.styles";

type RangesEditFormProps = {
  setting: MultipleRangesSettingFragmentFragment;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

type RangesEditFormData = {
  ranges: {
    from: number | undefined;
    to: number | undefined;
    value: number | undefined;
  }[];
};

const RangesEditForm = (props: RangesEditFormProps) => {
  const { setting, setEditMode } = props;

  const { handleSubmit, control } = useForm<RangesEditFormData>({
    defaultValues: {
      ranges: setting.ranges.map((range) => ({
        from: range.from,
        to: range.to,
        value: range.value,
      })),
    },
  });

  const { fields, append, remove } = useFieldArray({ control, name: "ranges" });

  const [update, { loading: isUpdating }] = useMutation<
    UpdateMultipleRangesSettingsMutation,
    UpdateMultipleRangesSettingsMutationVariables
  >(UPDATE_MULTIPLE_RANGES_SETTINGS, {
    onCompleted: () => {
      setEditMode(false);
    },
  });

  const onSubmit = useCallback(
    async (formData: RangesEditFormData) => {
      await update({
        variables: {
          input: [
            {
              key: setting.key,
              ranges: formData.ranges
                .filter((range) => isNumber(range.from) && isNumber(range.to) && isNumber(range.value))
                .map((range) => ({
                  from: range.from!,
                  to: range.to!,
                  value: range.value!,
                  unit: setting.ranges[0].unit,
                })),
            },
          ],
        },
      });
      setEditMode(false);
    },
    [update, setting, setEditMode]
  );

  if (!setting) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SettingTable>
        <tbody>
          {fields.map((field, index) => (
            <tr key={field.id}>
              <td>Between</td>
              <td>
                <Controller
                  control={control}
                  name={`ranges.${index}.from`}
                  defaultValue={field.from}
                  render={({ field }) => (
                    <NumberInput value={field.value} onChange={(e) => field.onChange(Number(e.target.value))} />
                  )}
                />
              </td>

              <td>
                <Controller
                  control={control}
                  name={`ranges.${index}.to`}
                  defaultValue={field.to}
                  render={({ field }) => (
                    <NumberInput value={field.value} onChange={(e) => field.onChange(Number(e.target.value))} />
                  )}
                />
              </td>

              <td>
                <Controller
                  control={control}
                  name={`ranges.${index}.value`}
                  defaultValue={field.value}
                  render={({ field }) => (
                    <NumberInput value={field.value} onChange={(e) => field.onChange(Number(e.target.value))} />
                  )}
                />
              </td>

              <td>
                <ButtonLink onClick={() => remove(index)}>remove</ButtonLink>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={4} />
            <td>
              <ButtonLink onClick={() => append({ from: undefined, to: undefined, value: undefined })}>Add</ButtonLink>
            </td>
          </tr>
          <tr>
            <td>
              <OneLineWithGap>
                <PrimaryLoadingButton isLoading={isUpdating}>Save</PrimaryLoadingButton>
                <ButtonLink onClick={() => setEditMode(false)}>Cancel</ButtonLink>
              </OneLineWithGap>
            </td>
          </tr>
        </tbody>
      </SettingTable>
    </form>
  );
};

export default RangesEditForm;
