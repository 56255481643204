import { useState, useCallback, useRef, KeyboardEventHandler, MouseEventHandler } from "react";
import { useQueryState } from "react-router-use-location-state";
import {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
  END,
  ENTER,
  HOME,
  SPACEBAR,
} from "../keyboard/keyboard.constants";
import { Props, TabOption } from "./tabs.constants";

export const useTabState = (defaultTab?: TabOption) => {
  const [tab, setTab] = useState<TabOption | undefined>(defaultTab);

  return {
    tab,
    setTab,
  };
};

/**
 * Use this hook instead of useTabState when the tab value needs to be persisted in the URL search params.
 */
export const useTabQueryState = (tabName: string, defaultTabValue: string | number) => {
  const [tabValue, setTabValue] = useQueryState(tabName, defaultTabValue);

  return {
    tabValue,
    setTabValue,
  };
};

export const useTabLogic = (props: Props) => {
  const { options, activeTab, contentPanelId, onSelect } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const getTabs = useCallback(() => (ref?.current ? [].slice.call(ref.current.childNodes) : []), []);

  const focusFirst = useCallback(() => setActiveIndex(0), [setActiveIndex]);

  const focusLast = useCallback(() => setActiveIndex(options.length - 1), [setActiveIndex, options.length]);

  const focusNext = useCallback(
    (currentIndex: number) => {
      if (currentIndex === options.length - 1) {
        focusFirst();
      } else {
        setActiveIndex(currentIndex + 1);
      }
    },
    [focusFirst, options.length]
  );

  const focusPrevious = useCallback(
    (currentIndex: number) => {
      if (currentIndex === 0) {
        focusLast();
      } else {
        setActiveIndex(currentIndex - 1);
      }
    },
    [focusLast, setActiveIndex]
  );

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      const { key } = event;

      switch (key) {
        case END: {
          event.preventDefault();
          focusLast();
          break;
        }
        case HOME: {
          event.preventDefault();
          focusFirst();
          break;
        }
        case ARROW_DOWN:
        case ARROW_RIGHT: {
          event.preventDefault();
          focusNext(activeIndex);
          break;
        }
        case ARROW_UP:
        case ARROW_LEFT: {
          event.preventDefault();
          focusPrevious(activeIndex);
          break;
        }
        case ENTER:
        case SPACEBAR: {
          event.preventDefault();
          if (!options[activeIndex].isDisabled) {
            onSelect(options[activeIndex]);
            const el = document.getElementById(contentPanelId);
            el?.focus({ preventScroll: true });
            setActiveIndex(-1);
          }
          break;
        }
        default:
          break;
      }
    },
    [activeIndex, contentPanelId, setActiveIndex, onSelect, options, focusNext, focusPrevious, focusFirst, focusLast]
  );

  const handleOnClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      const target = event.target;
      // @ts-ignore
      if (target.hasAttribute("disabled")) {
        return false;
      }

      const tabs = getTabs() as HTMLElement[];
      const index = tabs.findIndex((t) => t === target);
      if (index !== -1) {
        setActiveIndex(-1);
        onSelect(options[index]);
      }
    },
    [getTabs, onSelect, options, setActiveIndex]
  );

  const handleOnFocus = useCallback(() => {
    const index = options.findIndex((option) => option.value === activeTab);
    setActiveIndex(index);
  }, [options, activeTab, setActiveIndex]);

  return {
    ref,
    activeIndex,
    setActiveIndex,
    handleKeyDown,
    handleOnClick,
    handleOnFocus,
  };
};
