import { PStyled } from "@mc/components/core/typography.styles";
import { white } from "../core/colours.styles";
import {
  BouncerWrapperStyled,
  BouncerDotStyled,
  VisuallyHiddenText,
  MessageStyled,
  BouncerGlobalWrapperStyled,
} from "./bouncer.styles";

type Props = {
  light?: boolean;
  message?: string;
  isGlobal?: boolean;
};

export const Bouncer = ({ light, message, isGlobal }: Props) => {
  return (
    <BouncerGlobalWrapperStyled isGlobal={isGlobal ?? false} onClick={(e) => e.preventDefault()}>
      <BouncerWrapperStyled role="alert" aria-busy="true">
        <VisuallyHiddenText>Content is loading</VisuallyHiddenText>
        <BouncerDotStyled light={light} />
        <BouncerDotStyled light={light} />
        <BouncerDotStyled light={light} />
      </BouncerWrapperStyled>
      {message && (
        <MessageStyled>
          <PStyled colour={isGlobal ? white : ""}>{message}</PStyled>
        </MessageStyled>
      )}
    </BouncerGlobalWrapperStyled>
  );
};
