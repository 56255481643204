import styled from "styled-components/macro";
import { rem, standardFocusState } from "../core/styles";
import { fontSize14, fontSize20, fontSize28, fontWeightSemiBold } from "../core/typography.styles";
import { black, white } from "../core/colours.styles";

export const AVATAR_SMALL = 32;
export const AVATAR_MEDIUM = 48;

export type AvatarSizes = typeof AVATAR_SMALL | typeof AVATAR_MEDIUM;

type AvatarProps = {
  $size?: AvatarSizes;
};
export const AvatarStyled = styled.button<AvatarProps>`
  background-color: ${black};
  border-radius: 60px;
  border: none;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fontSize28};
  font-weight: ${fontWeightSemiBold};
  height: ${({ $size = 64 }) => rem($size)};
  text-align: center;
  text-transform: uppercase;
  width: ${({ $size = 64 }) => rem($size)};

  &:hover {
    cursor: pointer;
  }

  ${standardFocusState}

  ${({ $size }) =>
    $size === AVATAR_MEDIUM &&
    `
      font-size: ${fontSize20};
  `}

  ${({ $size }) =>
    $size === AVATAR_SMALL &&
    `
      font-size: ${fontSize14};
  `}
`;
