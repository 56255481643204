import { MouseEventHandler } from "react";
import { Props } from "./property-card-list.constants";
import {
  PropertyCardImage,
  PropertyCardProps,
  PropertyCardProp,
  PropertyCardImageDetailTop,
} from "./property-card.styles";
import * as Styled from "./property-card-list.styles";
import { useImageLoad } from "@mc/components/images/images.hooks";
import { GET_CORELOGIC_PROPERTY_IMAGES } from "./property-card-list.gql";
import { useLazyQuery } from "@apollo/client";
import ImageCarousel from "@mc/components/image-carousel/image-carousel";

const PropertyCardList = ({
  bedrooms,
  bathrooms,
  carSpaces,
  landSize,
  thumbnail,
  addressFormatted,
  preHeading,
  propertyDetails,
  isHighlighted,
  imageDetailTop,
  onClick,
  topRightAction,
  canLoadMoreImages = false,
  propertyId,
  isCompact = false,
}: Props) => {
  const { imageHasLoaded } = useImageLoad(thumbnail);

  const [fetchImages, { data, loading }] = useLazyQuery(GET_CORELOGIC_PROPERTY_IMAGES);

  const loadImages: MouseEventHandler = (e) => {
    e.stopPropagation();
    if (propertyId && canLoadMoreImages) {
      fetchImages({ variables: { propertyId: Number(propertyId) } });
    }
  };

  const images = data?.coreLogicPropertyimages?.allImages?.map((i: { largePhotoUrl: string }) => i.largePhotoUrl) ?? [];

  return (
    <Styled.CardRow isHighlighted={isHighlighted} isCompact={isCompact}>
      {images.length === 0 && (
        <Styled.CardImageContainer isCompact={isCompact}>
          {imageDetailTop && <PropertyCardImageDetailTop>{imageDetailTop}</PropertyCardImageDetailTop>}
          <PropertyCardImage imageHasLoaded={imageHasLoaded}>
            {thumbnail && <img src={thumbnail} alt="" />}
          </PropertyCardImage>
          {canLoadMoreImages && (
            <Styled.LoadMoreImagesButton onClick={loadImages} disabled={loading}>
              {loading ? "Loading..." : "Load more"}
            </Styled.LoadMoreImagesButton>
          )}
        </Styled.CardImageContainer>
      )}

      {images.length > 0 && (
        <Styled.CardImageContainer isCompact={isCompact}>
          <ImageCarousel images={images} />
        </Styled.CardImageContainer>
      )}

      <Styled.CardContent onClick={onClick} isCompact={isCompact}>
        {preHeading && <Styled.PropertyCardPreHeading>{preHeading}</Styled.PropertyCardPreHeading>}

        {topRightAction && <Styled.TopRightActionContainer>{topRightAction}</Styled.TopRightActionContainer>}

        <Styled.AddressWrapper marginBottom={12}>{addressFormatted}</Styled.AddressWrapper>

        {(bedrooms != null || bathrooms != null || carSpaces != null || landSize != null) && (
          <PropertyCardProps>
            {typeof bedrooms === "number" && (
              <PropertyCardProp>
                <Styled.PropertyCardIcon id="bed" width={24} height={24} />
                {bedrooms}
              </PropertyCardProp>
            )}
            {typeof bathrooms === "number" && (
              <PropertyCardProp>
                <Styled.PropertyCardIcon id="bath" width={24} height={24} />
                {bathrooms}
              </PropertyCardProp>
            )}
            {typeof carSpaces === "number" && (
              <PropertyCardProp>
                <Styled.PropertyCardIcon id="car" width={24} height={24} />
                {carSpaces}
              </PropertyCardProp>
            )}
            {typeof landSize === "number" && (
              <PropertyCardProp>
                <Styled.PropertyCardIcon id="landsize" width={24} height={24} />
                {landSize}m<sup>2</sup>
              </PropertyCardProp>
            )}
          </PropertyCardProps>
        )}
        {propertyDetails && <Styled.CardDetails>{propertyDetails}</Styled.CardDetails>}
      </Styled.CardContent>
    </Styled.CardRow>
  );
};

export default PropertyCardList;
