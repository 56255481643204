import { useContext, useEffect, useState } from "react";
import MapContext from "./map.context";

type Props = {
  lat: number;
  lng: number;
  markerOptions?: google.maps.MarkerOptions;
  isVisible?: boolean;
  title?: string;
  centerOnClick?: boolean;
  onClick?: React.MouseEventHandler | undefined;
};

const Marker = ({ lat, lng, markerOptions, isVisible = true, title, centerOnClick, onClick }: Props) => {
  const map = useContext(MapContext);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  useEffect(() => {
    const coords = { lat, lng };

    const newMarker = new google.maps.Marker({
      position: coords,
      map: isVisible ? map : null,
      title: title || "",
      ...markerOptions,
    });

    setMarker(newMarker);
  }, [map]);

  useEffect(() => {
    if (marker) marker.setOptions({ ...markerOptions });
  }, [markerOptions]);

  useEffect(() => {
    if (marker && onClick) {
      google.maps.event.clearListeners(marker, "click");
      if (map && centerOnClick) {
        marker.addListener("click", () => {
          map?.setZoom(18);
          map?.setCenter(marker.getPosition() as google.maps.LatLng);
        });
      }
      marker.addListener("click", onClick);
    }
  }, [marker, onClick]);

  useEffect(() => {
    if (marker) marker.setMap(isVisible ? map : null);
  }, [isVisible]);

  return null;
};

export default Marker;
