import { useMutation } from "@apollo/client";
import { RefreshStaffTokenMutation } from "@mc/common/gql/types";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CLIENTS, LOGIN } from "../../routes.constants";
import { getAuthToken, removeAuthToken, setAuthToken } from "../../shared/auth-token";
import { REFRESH_STAFF_TOKEN } from "./refresh-staff-token.gql";

export default function useAuth() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [refreshToken] = useMutation<RefreshStaffTokenMutation>(REFRESH_STAFF_TOKEN, {
    onCompleted: (data) => {
      setAuthToken(data.refreshStaffToken?.token);
    },
  });

  const staffToken = getAuthToken();

  const isValid = validateToken(staffToken || null);

  useEffect(() => {
    if (pathname === "/core-logic-disclaimer") {
      return;
    }

    // remove token and redirect to login page if token invalid
    if (!isValid && pathname !== "/login") {
      removeAuthToken();
      navigate(LOGIN);
      return;
    }

    if (isValid && isNearExpired(staffToken as string)) {
      refreshToken();
    }

    // redirect to clients page if in root or login page
    if (isValid && ["/", "/login"].includes(pathname)) {
      navigate(CLIENTS);
      return;
    }
  }, [isValid, navigate, pathname, refreshToken, staffToken]);
}

function validateToken(token: string | null): boolean {
  if (!token) return false;

  const decoded = jwtDecode<{ staffId: string; exp: number }>(token);

  if (!decoded.staffId) return false;

  const expiryDate = dayjs.unix(decoded.exp);

  const isExpired = dayjs().isAfter(expiryDate);

  return !isExpired;
}

// check whether the token is about to be expired within {bufferInSeconds} seconds
function isNearExpired(
  token: string,
  bufferInSeconds = 7200 // 60*60*2 = 2 hours
): boolean {
  const decoded = jwtDecode<{ exp: number }>(token);

  const expiryDate = dayjs.unix(decoded.exp);

  return expiryDate.diff(dayjs(), "seconds") < bufferInSeconds;
}
