import { ButtonLink } from "@mc/components/buttons";
import { Margin } from "@mc/components/core/spacing.styles";
import { H3, PStyled } from "@mc/components/core/typography.styles";
import { Modal, useModalState } from "@mc/components/modal/modal";

type Props = {
  subject: string | null | undefined;
  body: string | null | undefined;
  time: string | null | undefined;
};

const NotificationModal = ({ subject, body, time }: Props) => {
  const { isVisible, open, close } = useModalState();

  return (
    <div>
      <ButtonLink onClick={open}>View</ButtonLink>
      {isVisible && (
        <Modal isVisible={true} close={close} maxWidth="700px">
          <H3>Subject</H3>
          <PStyled>{subject}</PStyled>
          <H3>Sent date</H3>
          <PStyled>{time}</PStyled>
          <H3>Body</H3>
          <Margin top={20}>
            {body?.split("\n").map((item, key) => {
              return <PStyled key={key}>{item}</PStyled>;
            })}
          </Margin>
        </Modal>
      )}
    </div>
  );
};

export default NotificationModal;
