import { gql } from "@apollo/client";
import { FILE_PREVIEW_FRAGMENT } from "@mc/common/fragments/fragments.gql";

export const GET_CLIENT_PROPERTIES = gql`
  query GetClientProperties($clientId: String!) {
    client(id: $clientId) {
      _id
      properties {
        ... on OwnedProperty {
          _id
          __typename
          details {
            id
            coreAttributes {
              id
              baths
              beds
              carSpaces
              landArea
            }
            images {
              id
              defaultImage {
                basePhotoUrl
              }
            }
            location {
              id
              singleLine
            }
          }
        }
        ... on PurchasingProperty {
          _id
          __typename
          label
          event {
            _id
            property {
              _id
              purchaseType
              budget {
                formatted
              }
            }
          }
          purchase {
            _id
            status
            topResultProperties: shortlistProperties(status: TOP_RESULT) {
              _id
              details {
                id
                images {
                  id
                  defaultImage {
                    basePhotoUrl
                  }
                }
                location {
                  id
                  singleLine
                }
                coreAttributes {
                  id
                  baths
                  beds
                  carSpaces
                  landArea
                }
              }
              stages {
                number
                type
                published
              }
            }
          }
        }
      }
    }
    gamePlan(clientId: $clientId) {
      _id
      chosenOption {
        _id
        timeline {
          items {
            year
            events {
              _id
              type
              label
              endAt {
                year: formatted(format: "YYYY")
              }
              purchase {
                _id
                status
                stages {
                  label
                  number
                  type
                  published
                  items {
                    label
                    slug
                    status
                    type
                    published
                  }
                }
                topResults: shortlistProperties(status: TOP_RESULT) {
                  _id
                  saleOfferType
                  details {
                    id
                    location {
                      id
                      singleLine
                    }
                    images {
                      id
                      defaultImage {
                        mediumPhotoUrl
                      }
                    }
                  }
                  domainListing {
                    id
                    bedrooms
                    bathrooms
                    carspaces
                    landAreaSqm
                    addressParts {
                      displayAddress
                    }
                    media {
                      url
                    }
                  }
                  stages {
                    number
                    type
                    label
                    published
                    items {
                      label
                      slug
                      status
                      type
                      published
                    }
                  }
                }
              }
              property {
                _id
                purchaseType
                propertyType
                budget {
                  formatted
                }
              }
              project {
                _id
                propertyId
                type
                budget {
                  formatted
                }
                budgetWithCPI {
                  formatted
                }
              }
              sale {
                _id
                price {
                  formatted
                }
              }
              loanAdjustment {
                _id
                type
                propertyId
                fund {
                  formatted
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DOMAIN_LISTING_FRAGMENT = gql`
  fragment DomainListingFragment on DomainListing {
    id
    propertyId
    priceDetails {
      displayPrice
    }
    dateListed {
      formatted(format: "DD MMM YYYY")
      fromNow
    }
    propertyTypes
    addressParts {
      displayAddress
    }
    geoLocation {
      latitude
      longitude
    }
    bedrooms
    bathrooms
    carspaces
    landAreaSqm
    headline
    description
    seoUrl
    media {
      url
    }
  }
`;

export const PURCHASE_FRAGMENT = gql`
  fragment PurchaseFragment on Purchase {
    _id
    status
    dataSource
    isFamilyHome
    pausedAt {
      formatted(format: "ddd D MMMM YYYY")
    }
    resumedAt {
      formatted(format: "ddd D MMMM YYYY")
    }
    pausedForDays
    stages {
      number
      type
      label
      items {
        type
        label
        slug
        status
      }
    }
    propertySearchCondition {
      budget {
        min {
          amount
          formatted
        }
        max {
          amount
          formatted
        }
      }
      beds {
        min
        max
      }
      baths {
        min
      }
      cars {
        min
      }
      landArea {
        min
        max
      }
      propertyType
      domainListingPropertyTypes
    }
    yourMarket {
      _id
      published
      status
    }
    yourSuburbs {
      _id
      published
      status
      suburbs {
        _id
        coreLogicLocalityId
        name
        state
        postcode
        isActive
        isSelectedByClient
      }
      familyHomeDescription
    }
    readProperties
    shortlistProperties {
      _id
      status
      rating
      isOffMarket
      clientPropertyOpinion
      details {
        id
        coreAttributes {
          id
          beds
          baths
          carSpaces
          landArea
          propertyType
        }
        images {
          id
          defaultImage {
            mediumPhotoUrl
          }
        }
        location {
          id
          singleLine
          latitude
          longitude
          street {
            nameAndNumber
          }
        }
        otmSales {
          id
          forSalePropertyCampaign {
            activeCampaign {
              daysOnMarket
            }
          }
        }
      }
      domainListing {
        ...DomainListingFragment
      }
      stages {
        number
        type
        items {
          type
          label
          slug
          status
        }
      }
      inspectionDate {
        formatted(format: "DD/MM/YYYY, h:mma")
      }
    }
    preApproval {
      _id
      status
      loans {
        _id
        loanType
        lender
        nameOfContact
        originalInterestRate {
          value
        }
        originalLoanTermInYears
        repaymentType
        expiry {
          formatted(format: "DD/MM/YYYY")
        }
      }
    }
    idVerification {
      _id
      status
      purchasers {
        _id
        fullName
        primaryIdentity {
          _id
          type
          number
          expiry {
            formatted(format: "DD/MM/YYYY")
          }
          photo {
            ...FilePreviewFragment
          }
        }
        secondaryIdentity {
          _id
          type
          number
          expiry {
            formatted(format: "DD/MM/YYYY")
          }
          photo {
            ...FilePreviewFragment
          }
        }
      }
    }
    briefRequirements {
      _id
      status
    }
  }
  ${FILE_PREVIEW_FRAGMENT}
  ${DOMAIN_LISTING_FRAGMENT}
`;

export const GET_PURCHASE = gql`
  query GetPurchase($purchaseId: String!, $page: Int, $sortOption: ActiveListingsSortOption) {
    purchase(purchaseId: $purchaseId) {
      ...PurchaseFragment
      activeListings(page: $page) {
        resources {
          page {
            totalElements
          }
        }
        combinedList(sortOption: $sortOption) {
          id
          address {
            singleLineAddress
          }
          coordinate {
            latitude
            longitude
          }
          otmForSaleDetail {
            agency
            agent
            method
            priceDescription
            date {
              formatted
              daysFromNowFormatted
            }
          }
          propertyPhoto {
            mediumPhotoUrl
          }
          attributes {
            bathrooms
            bedrooms
            carSpaces
            landArea
          }
        }
        pagesSummary {
          minTotalPages
          maxTotalPages
          sumTotalElements
          remainingElements
        }
      }
      domainListings {
        listing {
          id
          propertyDetails {
            displayableAddress
            bedrooms
            bathrooms
            carspaces
            landArea
          }
          dateListed {
            fromNow
          }
          media {
            url
          }
        }
      }
      gamePlanEvents {
        _id
        projects {
          _id
          startAt {
            formatted(format: "YYYY")
            monthAndYear: formatted(format: "MMM YYYY")
          }
          project {
            type
            budget {
              amount
              formatted
            }
            assumedROI {
              formatted
            }
            depositAmount {
              amount
              formatted
            }
            type
            loan {
              originalAmount {
                amount
                formatted
              }
            }
          }
        }
      }
    }
  }
  ${PURCHASE_FRAGMENT}
`;

export const GET_DOMAIN_LISTING = gql`
  query GetDomainListing($domainListingId: Int!) {
    domainListing(id: $domainListingId) {
      ...DomainListingFragment
    }
  }
  ${DOMAIN_LISTING_FRAGMENT}
`;

export const CREATE_PURCHASE = gql`
  mutation CreatePurchase($gamePlanPropertyId: String!) {
    createPurchase(gamePlanPropertyId: $gamePlanPropertyId) {
      ...PurchaseFragment
    }
  }
  ${PURCHASE_FRAGMENT}
`;

export const UPDATE_PURCHASE_STATUS = gql`
  mutation UpdatePurchaseStatus($purchaseId: String!, $status: PurchaseStatus!) {
    updatePurchaseStatus(purchaseId: $purchaseId, status: $status) {
      ...PurchaseFragment
    }
  }
  ${PURCHASE_FRAGMENT}
`;

export const READ_PROPERTY_IN_PURCHASE = gql`
  mutation ReadPropertyInPurchase($purchaseId: String!, $coreLogicPropertyId: Int!) {
    readPropertyInPurchase(purchaseId: $purchaseId, coreLogicPropertyId: $coreLogicPropertyId) {
      ...PurchaseFragment
    }
  }
  ${PURCHASE_FRAGMENT}
`;

export const SEARCH_CORELOGIC_SUBURB = gql`
  query SearchCoreLogicSuburb($text: String!) {
    searchCoreLogicAddress(type: SUBURB, text: $text) {
      suggestions {
        localityId
        suggestion
      }
    }
  }
`;
