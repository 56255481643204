import { ReactNode } from "react";

import TooltipModal from "@mc/components/tooltips/tooltip-modal";
import { TooltipInfoStyled } from "@mc/components/tooltips/tooltip.style";

type Props = {
  text: ReactNode;
  title?: string;
  heading?: string;
};

const TooltipInfoModal = ({ text, title = "Help", heading = "" }: Props) => (
  <TooltipModal text={text} heading={heading}>
    <TooltipInfoStyled type="button">{title}</TooltipInfoStyled>
  </TooltipModal>
);

export default TooltipInfoModal;
