import { useState } from "react";
import { useFormContext, RegisterOptions } from "react-hook-form";

import {
  PasswordInputStyled,
  PasswordInputWrapperStyled,
  PasswordInputSecondaryStyled,
  PasswordInputShowButtonStyled,
} from "./password-input.styles";

type Props = {
  name: string;
  hideCharacterToggle?: boolean;
  isError?: boolean;
} & RegisterOptions;

const PasswordInput = ({ name, hideCharacterToggle = false, isError, ...registerOptions }: Props) => {
  const { register } = useFormContext();
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  return (
    <PasswordInputWrapperStyled>
      <PasswordInputStyled
        type={isShowPassword ? "text" : "password"}
        id={name}
        {...register(name, {
          ...registerOptions,
          required: "Please enter a password.",
          minLength: {
            value: 8,
            message: "Must be at least 8 characters",
          },
        })}
        isError={isError}
      />
      {!hideCharacterToggle && (
        <PasswordInputSecondaryStyled>
          <PasswordInputShowButtonStyled
            type="button"
            aria-label={isShowPassword ? "Hide password character" : "Show password characters"}
            onClick={() => setIsShowPassword(!isShowPassword)}
          >
            {isShowPassword ? "Hide" : "Show"}
          </PasswordInputShowButtonStyled>
        </PasswordInputSecondaryStyled>
      )}
    </PasswordInputWrapperStyled>
  );
};

export default PasswordInput;
