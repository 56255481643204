import { notificationMap } from "@mc/common/utils/enum-maps";
import { Bouncer } from "@mc/components/bouncer/bouncer";
import { Margin } from "@mc/components/core/spacing.styles";
import { Table } from "@mc/components/tables/table.styles";
import Layout, { LayoutBreadcrumb, LayoutTitle } from "../../../layout/layout";
import NotificationModal from "./notification-modal";
import useClientNotifications from "./use-client-notifications";
import { Flex } from "@mc/components/grid/grid.styles";
import { green } from "@mc/components/core/colours.styles";
import { MdCheck } from "react-icons/md";

const Notifications = () => {
  const { loading, data } = useClientNotifications();
  return (
    <Layout>
      <LayoutBreadcrumb
        crumbs={[
          {
            label: "Notifications",
            href: "",
          },
        ]}
      />
      <LayoutTitle title="Notifications" />

      <Margin top={8} bottom={8}>
        {loading && <Bouncer />}
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Sent date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.client.notifications.map((mails, index) => {
              const label = mails?.type ? notificationMap[mails.type].label : "";
              return (
                <tr key={index}>
                  <td>
                    <Flex alignItems="center" gap={16}>
                      <MdCheck size={24} fill={green} /> {label}
                    </Flex>
                  </td>
                  <td>{mails.sentAt.formatted}</td>
                  <td>
                    <NotificationModal subject={mails.subject} body={mails.body} time={mails.sentAt.formatted} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Margin>
    </Layout>
  );
};

export default Notifications;
