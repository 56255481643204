import { ReactNode, useEffect, useState } from "react";
import { SpacingValue } from "../core/spacing.styles";
import MapContext from "./map.context";

import * as Styled from "./map.styles";

type Bound = {
  lat: number;
  lng: number;
};

type Props = {
  children?: ReactNode;
  lat?: number;
  lng?: number;
  zoom?: number;
  id?: string;
  height?: number;
  marginBottom?: SpacingValue;
  mapOptions?: google.maps.MapOptions;
  onClick?: React.MouseEventHandler | undefined;
  bounds?: Bound[];
};

export const Canvas = ({
  children,
  lat,
  lng,
  zoom = 12,
  id = "map",
  height,
  marginBottom,
  mapOptions,
  onClick,
  bounds = [],
}: Props) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    if (google) {
      const map = new google.maps.Map(document.getElementById(id) as HTMLElement, {
        center: lat && lng ? { lat, lng } : null,
        zoom: zoom,
        ...mapOptions,
      });

      setMap(map);
    }
  }, []);

  useEffect(() => {
    if (map) map.setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    let addedListener: google.maps.MapsEventListener;
    if (map && onClick) {
      addedListener = google.maps.event.addListener(map, "click", onClick);
    }
    return () => {
      addedListener && addedListener.remove();
    };
  }, [map, onClick]);

  useEffect(() => {
    if (bounds.length > 0 && map) {
      const _bounds = new google.maps.LatLngBounds();

      bounds.forEach((bound) => {
        _bounds.extend(new google.maps.LatLng(bound.lat, bound.lng));
        map.fitBounds(_bounds, 100); // 100 padding
      });
    }
  }, [map, bounds]);

  return (
    <MapContext.Provider value={map}>
      <Styled.MapContainer $height={height} $marginBottom={marginBottom}>
        <Styled.MapInner id={id}>{children}</Styled.MapInner>
      </Styled.MapContainer>
    </MapContext.Provider>
  );
};
