import styled from "styled-components";
import { black, green, lightGrey, white } from "../core/colours.styles";
import { fontSize16, fontSize16LineHeight } from "../core/typography.styles";

type SwitchButtonProps = {
  checked: boolean;
  isDisabled?: boolean;
  isNeutral?: boolean;
};

export const SwitchButton = styled.button<SwitchButtonProps>`
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  height: 24px;
  width: 40px;
  background-color: ${({ checked, isDisabled, isNeutral }) => {
    if (isDisabled) return lightGrey;
    if (isNeutral) return black;
    return checked ? green : lightGrey;
  }};
  border: 2px solid ${lightGrey};
  border-radius: 12px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  span {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: ${({ checked }) => (checked ? "15px" : "1px")};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${white};
    transition: all 100ms ease;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    font-size: ${fontSize16};
    line-height: ${fontSize16LineHeight};
  }
`;
