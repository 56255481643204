import { H4, PStyled } from "@mc/components/core/typography.styles";

export const PROPERTY_SCORE_HELP_TEXT = (
  <>
    <PStyled>
      Overall, we prioritise a range of factors when it comes to buying a high-performance investment property that goes
      far beyond timing. Considerations like location, asset selection, real estate fundamentals, and owner-occupier
      resale potential should always form the basis of any purchasing decision. And if your not in it, to flip it, then
      property investing should be considered a long-term strategy.
    </PStyled>

    <PStyled>
      Likewise, if you’re buying a family home, would you really want to hold off buying the dream home if you found it
      now, and risking it to wait a few more months! The answer is likely, NO. Your dream home is your dream home, and
      if it meets all of your requirements, it will be very rare that you will be able to replicate given the nature of
      owner-occupier home selection. You are also more than likely to live in this home for at least 7-10 years.
    </PStyled>

    <PStyled>
      The bespoke nature of our service at Milk Chocolate means we don’t apply a “one size fits all” approach to our
      investment strategies. Each client’s personal circumstances, financial position, and objectives dictate every
      facet of their bespoke Game Plan and/or purchase, including location.
    </PStyled>

    <H4 marginBottom={0}>Location</H4>
    <PStyled>
      This takes into account multiple factors such as land size, site elevation, shape of block, orientation,
      topography, easement, road activity, distance to local amenties and public transport access, to name a few. Milk
      Chocolate has created it&apos;s own scoring mechanics which we apply to each property that has been reviewed and
      presented to you.
    </PStyled>

    <H4 marginBottom={0}>Options available</H4>
    <PStyled>
      Key to a Milk Chocolate asset is the options available. Adding value by increasing the spec of the home by adding
      extra floor space, adding bedrooms and bathrooms, building an additional structure such as a granny flat, or
      subdividing to build multiple dwellings. Irrespective of executing on these, the asset is underpinned by the
      opportunities provided and will assist to maintain and maximise ceiling for growth. These can be executed to
      offset a cooler market, enabling you to continue the asset growth of your portfolio by manufacturing equity ,
      likewise, expanding the size of your buying pool, providing flexibility with your portfolio in the future.
    </PStyled>

    <H4 marginBottom={0}>Character</H4>
    <PStyled>
      We will generally target homes that will present with character, or repransentative of a quality home in the
      subject asset location.
    </PStyled>

    <PStyled>
      Home with character not only retain a lot of their original charm, but they’re also generally built to a
      higher-quality standard and tend to perform better from a resale perspective. These are more attractive to family
      home buyers who will buy with their heart, and not their head. They are bigger boned and well apportioned which is
      certainly a key driver for better functional living and attractive rental demand. Character is something that
      can’t be reproduced, and as they become the minority, their value increases given the difficulty to re-produce.
    </PStyled>

    <H4 marginBottom={0}>Property</H4>
    <PStyled>
      Not only do our professional licensed agents view all of the homes presented, we encourage the commisioning of
      building & pest, electrical safety, invasive plumbing and structural engineering reports. We will assess the home
      in relation to the age of the building to ensure a satisfactory rating when comparing current condition with age.
    </PStyled>

    <H4 marginBottom={0}>Overall</H4>
    <PStyled>
      This is the combined value of all minor scores, providing a high-level understanding factoring multiple
      influences.
    </PStyled>
  </>
);
