import { gql } from "@apollo/client";

export const GAME_PLAN_FEEDBACK_HOME_PURCHASE_DETAILS_PROFILE_FRAGMENT = gql`
  fragment GamePlanFeedbackHomePurchaseDetailsProfileFragment on Profile {
    _id
    services
    isFamilyHomePurchasedFirst
    familyHome {
      state
      purchaseAmount
      purchaseAmountFormatted
      purchaseYear
      propertyType
      isRenovationRequired
    }
  }
`;

export const GAME_PLAN_FEEDBACK_HOME_PURCHASE_DETAILS_FRAGMENT = gql`
  fragment GamePlanFeedbackHomePurchaseDetailsFragment on GamePlanFeedback {
    _id
    familyHomeState
    familyHomeBudget {
      amount
    }
    familyHomePurchaseYear
    familyHomePropertyType
    isRenovationRequired
  }
`;
