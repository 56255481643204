import { Controller } from "react-hook-form";
import { ButtonLink, PrimaryLoadingButton } from "@mc/components/buttons";
import { NumberInput, SelectInput } from "@mc/components/inputs/inputs.styles";
import { Margin } from "@mc/components/core/spacing.styles";
import { H3 } from "@mc/components/core/typography.styles";
import { OneLineWithGap, SettingDate, SettingTable } from "./settings.styles";
import useMultipleValuesSetting from "./use-multiple-values-setting";

type MultipleValuesSettingProps = { keyName: string };

const MultipleValuesSetting = (props: MultipleValuesSettingProps) => {
  const { keyName } = props;

  const {
    settingGroup,
    settings,
    hasFilters,
    filters,
    selectedFilterValues,
    onFilterUpdate,
    editMode,
    setEditMode,
    control,
    onSubmit,
    isUpdating,
  } = useMultipleValuesSetting({ keyName });

  if (!settingGroup) return null;

  return (
    <Margin bottom={64}>
      <Margin bottom={32}>
        <OneLineWithGap>
          <H3 marginBottom={0} id={keyName}>
            {settingGroup.name}
          </H3>

          {hasFilters && (
            <OneLineWithGap>
              {filters?.map((filter, i) => (
                <SelectInput key={i} onChange={onFilterUpdate(i)} value={selectedFilterValues[i]}>
                  {filter?.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </SelectInput>
              ))}
            </OneLineWithGap>
          )}

          <ButtonLink onClick={() => setEditMode(true)}>Edit</ButtonLink>

          <SettingDate>
            Last updated <span>{settings[0].updatedAt.formatted}</span>
          </SettingDate>
        </OneLineWithGap>
      </Margin>

      <div>
        <form onSubmit={onSubmit}>
          <SettingTable>
            <thead>
              <tr>
                <th></th>
                {settings[0].values.map((value) => (
                  <th key={value.key}>{value.name}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {settings.map((setting) => (
                <tr key={setting.key}>
                  <td>{setting.name}</td>
                  {setting.values.map((value) => (
                    <td key={value.key}>
                      {editMode ? (
                        <Controller
                          control={control}
                          name={`${setting.key}.${value.key}`}
                          defaultValue={value.value}
                          render={({ field }) => (
                            <NumberInput value={field.value} onChange={(e) => field.onChange(Number(e.target.value))} />
                          )}
                        />
                      ) : (
                        value.formattedValue
                      )}
                    </td>
                  ))}
                </tr>
              ))}
              {editMode && (
                <tr>
                  <td>
                    <OneLineWithGap>
                      <PrimaryLoadingButton isLoading={isUpdating}>Save</PrimaryLoadingButton>
                      <ButtonLink onClick={() => setEditMode(false)}>Cancel</ButtonLink>
                    </OneLineWithGap>
                  </td>
                </tr>
              )}
            </tbody>
          </SettingTable>
        </form>
      </div>
    </Margin>
  );
};

export default MultipleValuesSetting;
