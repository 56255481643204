import { gql } from "@apollo/client";

export const PROPERTY_DETAILS = gql`
  query ActivePortfolioProperty($propertyId: String!) {
    activePortfolioProperty(propertyId: $propertyId) {
      _id
      details {
        id
        coreAttributes {
          id
          baths
          beds
          carSpaces
          landArea
          propertyType
          propertySubTypeShort
          propertySubType
          landAreaSource
        }
        images {
          id
          allImages {
            largePhotoUrl
          }
        }
        location {
          id
          singleLine
          longitude
          latitude
        }
        additionalAttributes {
          id
          yearBuilt
        }
        lastSales {
          id
          lastSale {
            zoneDescriptionLocal
          }
        }
      }
      purchaseShortlistProperty {
        _id
        photos {
          _id
          link
        }
      }
      updatedAt {
        formatted
      }
    }
  }
`;
