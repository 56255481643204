import { useMutation, useQuery } from "@apollo/client";
import {
  GetSettingGroupQuery,
  GetSettingGroupQueryVariables,
  MeasurementUnit,
  MultipleValuesSettingFragmentFragment,
  UpdateMultipleValuesSettingsMutation,
  UpdateMultipleValuesSettingsMutationVariables,
} from "@mc/common/gql/types";
import { useForm } from "react-hook-form";
import { GET_SETTING_GROUP, UPDATE_MULTIPLE_VALUES_SETTINGS } from "./setting.gql";
import useSetting from "./use-setting";

export default function useMultipleValuesSetting({ keyName }: { keyName: string }) {
  const { handleSubmit, control } = useForm();

  const [update, { loading: isUpdating }] = useMutation<
    UpdateMultipleValuesSettingsMutation,
    UpdateMultipleValuesSettingsMutationVariables
  >(UPDATE_MULTIPLE_VALUES_SETTINGS, {
    onCompleted: () => {
      setEditMode(false);
    },
  });

  const { data } = useQuery<GetSettingGroupQuery, GetSettingGroupQueryVariables>(GET_SETTING_GROUP, {
    variables: { key: keyName },
    onCompleted: () => {
      setSelectedFilterValues(filters.map((filter) => filter[0]));
    },
  });

  const {
    editMode,
    setEditMode,
    selectedFilterValues,
    setSelectedFilterValues,
    hasFilters,
    filters,
    onFilterUpdate,
  } = useSetting({ data });

  const filterSettings = data?.settingGroup?.settings.filter(
    (setting) =>
      (selectedFilterValues.length === 0 ||
        selectedFilterValues.every((selectedValue) => setting.filters?.includes(selectedValue))) &&
      setting.__typename === "MultipleValuesSetting"
  ) as MultipleValuesSettingFragmentFragment[];

  const onSubmit = handleSubmit(async (formData) => {
    const transformed = Object.keys(formData).map((settingKey) => {
      const foundSetting = filterSettings?.find((setting) => setting.key === settingKey);
      return {
        key: settingKey,
        values: Object.keys(formData[settingKey]).map((valueKey) => {
          const foundValue = foundSetting?.values.find((value) => value.key === valueKey);
          return {
            key: valueKey,
            name: foundValue?.name || "",
            value: formData[settingKey][valueKey],
            unit: foundValue?.unit || MeasurementUnit.PERCENT,
          };
        }),
      };
    });
    await update({
      variables: {
        input: transformed,
      },
    });
  });

  return {
    settingGroup: data?.settingGroup,
    settings: filterSettings as MultipleValuesSettingFragmentFragment[],
    hasFilters,
    filters,
    selectedFilterValues,
    onFilterUpdate,
    editMode,
    setEditMode,
    control,
    onSubmit,
    isUpdating,
  };
}
