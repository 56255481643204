import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_PURCHASE } from "./properties.gql";
import { ActiveListingsSortOption, GetPurchaseQuery, GetPurchaseQueryVariables } from "@mc/common/gql/types";

export default function usePurchase(args?: {
  page?: number;
  sortOption?: ActiveListingsSortOption;
  onCompleted?: (data: GetPurchaseQuery) => void;
}) {
  const { onCompleted, page = 1, sortOption = ActiveListingsSortOption.LISTED_DATE_DESC } = args ?? {};

  const { purchaseId } = useParams<{ purchaseId: string }>();

  const { data, loading } = useQuery<GetPurchaseQuery, GetPurchaseQueryVariables>(GET_PURCHASE, {
    variables: { purchaseId: purchaseId!, page, sortOption },
    skip: !(purchaseId && page > 0),
    onCompleted,
    fetchPolicy: "network-only",
  });

  return { loading, purchase: data?.purchase };
}
