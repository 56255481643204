import { useState, useCallback, ReactNode } from "react";
import ReactModal from "react-modal";

import { ButtonIcon } from "../buttons";
import { ModalClose, ModalContent } from "./modal.styles";

export const useModalState = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const open = useCallback(() => setIsVisible(true), []);
  const close = useCallback(() => setIsVisible(false), []);

  return {
    isVisible,
    open,
    close,
  };
};

type ModalProps = {
  isVisible: boolean;
  children?: ReactNode;
  maxWidth?: string;
  close?: () => void;
  render?: ReactNode;
};

export const Modal = ({ isVisible, children, maxWidth, close, render }: ModalProps) => {
  if (render && children) throw "Modal has both render and children";

  return (
    <ReactModal
      isOpen={isVisible}
      style={{
        content: {
          background: "#fff",
          borderRadius: "4px",
          border: "none",
          boxSizing: "border-box",
          boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
          inset: "initial",
          maxWidth: maxWidth || "440px",
          margin: "auto",
          padding: "0",
          position: "absolute",
          overflow: "auto",
          outline: "none",
          WebkitOverflowScrolling: "touch",
          width: "100%",
        },
      }}
      ariaHideApp={false}
      onRequestClose={() => (close ? close() : false)}
    >
      {close && (
        <ModalClose>
          <ButtonIcon noBorder iconId="close" onClick={close} />
        </ModalClose>
      )}
      {render ? render : <ModalContent>{children}</ModalContent>}
    </ReactModal>
  );
};
