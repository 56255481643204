import * as Styled from "./property/purchase-tracker-sidebar.styles";
import { Accordion } from "@mc/components/accordion/accordion";
import { StepList, StepItem, StepItemLink, StepAccordionToggle } from "@mc/components/step-list/step-list";
import usePurchase from "../use-purchase";
import { generatePath, useParams } from "react-router-dom";
import { PROPERTIES, PROPERTIES_PURCHASE } from "../../../../routes.constants";

const SearchTrackerSidebar = () => {
  const { clientId, purchaseId } = useParams<{ clientId: string; purchaseId: string }>();

  const propertiesURL = generatePath(PROPERTIES, { clientId });

  const purchaseURL = generatePath(PROPERTIES_PURCHASE, { clientId, purchaseId });

  const { loading, purchase } = usePurchase();

  if (loading) return null;

  return (
    <Styled.Nav>
      <Styled.NavHeader>
        <Styled.NavAnchor to={propertiesURL} $fontWeight="normal" $marginLeft={8}>
          <Styled.NavIcon id="arrow-left" />
          Back to Properties
        </Styled.NavAnchor>
      </Styled.NavHeader>
      <Accordion id="search-tracker">
        {purchase?.stages.map((stage) => (
          <StepAccordionToggle key={stage.number} title={stage.label} defaultExpanded isComplete={false}>
            <StepList>
              {stage.items.map((item) => (
                <StepItem key={item.type} purchaseTrackerItemStatus={item.status}>
                  <StepItemLink to={`${purchaseURL}/${item.slug}`} label={item.label} />
                </StepItem>
              ))}
            </StepList>
          </StepAccordionToggle>
        ))}
      </Accordion>
    </Styled.Nav>
  );
};

export default SearchTrackerSidebar;
