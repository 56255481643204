import Avatar from "@mc/components/avatar/avatar";
import { AVATAR_SMALL } from "@mc/components/avatar/avatar.styles";
import { getUserData } from "../shared/auth-token";
import * as Styled from "./navigation.styles";

const NavigationStaffProfile = () => {
  const userData = getUserData();
  const firstName = userData?.staff.firstName;
  const lastName = userData?.staff.lastName;

  return (
    <Styled.NavigationSecondary>
      <Styled.NavigationProfile>
        <Avatar firstName={firstName} lastName={lastName} size={AVATAR_SMALL} />
        <Styled.NavigationProfileName>
          {firstName} {lastName}
        </Styled.NavigationProfileName>
      </Styled.NavigationProfile>
    </Styled.NavigationSecondary>
  );
};

export default NavigationStaffProfile;
