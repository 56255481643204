import { black, darkGrey, tan } from "@mc/components/core/colours.styles";
import { TextAreaInput } from "@mc/components/inputs/inputs.styles";
import styled from "styled-components";

export const WidgetButton = styled.button`
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: none;
  background-color: ${black};
  &:hover {
    cursor: pointer;
  }
`;

export type WidgetContainerProps = {
  size: "small" | "large";
};

const sizeMap: Record<WidgetContainerProps["size"], { width: string; top: string }> = {
  small: {
    width: "350px",
    top: "50vh",
  },
  large: {
    width: "600px",
    top: "16px",
  },
};

export const WidgetContainer = styled.div<WidgetContainerProps>`
  position: fixed;
  bottom: 16px;
  right: 16px;
  top: ${({ size }) => sizeMap[size].top};
  width: ${({ size }) => sizeMap[size].width};
  background-color: ${tan};
  z-index: 1000;
  box-shadow: 0 0 16px ${darkGrey};
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
`;

export const WidgetControlButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;

export const NotesForm = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const TextAreaInputAlt = styled(TextAreaInput)`
  resize: none;
  flex-grow: 1;
  margin-bottom: 16px;
`;
