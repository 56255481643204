import { ButtonLink } from "@mc/components/buttons";
import { Margin } from "@mc/components/core/spacing.styles";
import { H3 } from "@mc/components/core/typography.styles";
import { SelectInput } from "@mc/components/inputs/inputs.styles";
import RangesEditForm from "./ranges-edit-form";
import { OneLineWithGap, SettingDate, SettingTable } from "./settings.styles";
import useMultipleRangesSetting from "./use-multiple-ranges-setting";

type MultipleRangesSettingProps = { keyName: string };

const MultipleRangesSetting = (props: MultipleRangesSettingProps) => {
  const { keyName } = props;

  const {
    settingGroup,
    setting,
    hasFilters,
    filters,
    selectedFilterValues,
    editMode,
    setEditMode,
    onFilterUpdate,
  } = useMultipleRangesSetting({ keyName });

  if (!settingGroup) return null;

  return (
    <Margin bottom={64}>
      <Margin bottom={32}>
        <OneLineWithGap>
          <H3 marginBottom={0} id={keyName}>
            {settingGroup.name}
          </H3>

          {hasFilters && (
            <OneLineWithGap>
              {filters.map((filter, i) => (
                <SelectInput key={i} onChange={onFilterUpdate(i)} value={selectedFilterValues[i]}>
                  {filter?.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </SelectInput>
              ))}
            </OneLineWithGap>
          )}

          <ButtonLink onClick={() => setEditMode(true)}>Edit</ButtonLink>

          <SettingDate>
            Last updated <span>{setting.updatedAt.formatted}</span>
          </SettingDate>
        </OneLineWithGap>
      </Margin>

      <div>
        {editMode && setting ? (
          <RangesEditForm setting={setting} setEditMode={setEditMode} />
        ) : (
          <SettingTable>
            <tbody>
              {setting &&
                setting.ranges.map((range, i) => (
                  <tr key={i}>
                    <td>Between</td>
                    <td>{range.formattedFrom}</td>
                    <td>{range.formattedTo}</td>
                    <td>{range.formattedValue}</td>
                  </tr>
                ))}
            </tbody>
          </SettingTable>
        )}
      </div>
    </Margin>
  );
};

export default MultipleRangesSetting;
