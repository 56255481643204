import { GetSettingGroupQuery } from "@mc/common/gql/types";
import { uniq } from "lodash";
import { ChangeEvent, useCallback, useState } from "react";

export default function useSetting({ data }: { data: GetSettingGroupQuery | undefined }) {
  const [editMode, setEditMode] = useState<boolean>(false);

  const [selectedFilterValues, setSelectedFilterValues] = useState<string[]>([]);

  const hasFilters = data?.settingGroup?.settings.some((setting) => (setting?.filters?.length || 0) > 0);

  const filters = (data?.settingGroup?.settings[0].filters || []).map((_filter, index) => {
    return uniq(data?.settingGroup?.settings.map((setting) => (setting.filters || [])[index]));
  });

  const onFilterUpdate = useCallback(
    (i: number) => (e: ChangeEvent<HTMLSelectElement>) => {
      setSelectedFilterValues(selectedFilterValues.map((v, j) => (i === j ? e.target.value : v)));
      setEditMode(false);
    },
    [selectedFilterValues, setSelectedFilterValues]
  );

  return {
    editMode,
    setEditMode,
    selectedFilterValues,
    setSelectedFilterValues,
    hasFilters,
    filters,
    onFilterUpdate,
  };
}
