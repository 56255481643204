import { useQuery } from "@apollo/client";
import { CoreLogicLegalContentQuery } from "@mc/common/gql/types";
import { Margin } from "@mc/components/core/spacing.styles";
import { H4, H2, H1, PStyled } from "@mc/components/core/typography.styles";
import { Col, Row } from "@mc/components/grid/grid.styles";
import { GET_CORE_LOGIC_LEGAL_CONTENT } from "./core-logic-disclaimer.gql";
import { DisclaimerContainer } from "./core-logic-disclaimer.styles";

const CoreLogicDisclaimer = () => {
  const { data } = useQuery<CoreLogicLegalContentQuery>(GET_CORE_LOGIC_LEGAL_CONTENT);

  return (
    <DisclaimerContainer as="main">
      <Row>
        <Col xsOffset={1} xs={9}>
          <H2 marginBottom={64}>Milk Chocolate&trade;</H2>

          <Margin bottom={48}>
            <H1>Core Logic disclaimer</H1>
            <PStyled>
              Whilst all reasonable effort is made to ensure the information in this publication is current, CoreLogic
              does not warrant or represent the accuracy or completeness of the Data and commentary contained in this
              publication and to the full extent not prohibited by law excludes all loss or damage arising in connection
              with the Data and commentary contained in this publication.
            </PStyled>
          </Margin>

          <Margin bottom={48}>
            <H4>Copyright Australia</H4>
            <PStyled
              dangerouslySetInnerHTML={{
                __html: data?.copyrightContent?.content ?? "",
              }}
            ></PStyled>
          </Margin>

          <Margin bottom={48}>
            <H4>Data Australia and New Zealand</H4>
            <PStyled
              dangerouslySetInnerHTML={{
                __html: data?.dataContent?.content ?? "",
              }}
            ></PStyled>
          </Margin>

          <Margin bottom={48}>
            <H4>Queensland Data</H4>{" "}
            <PStyled
              dangerouslySetInnerHTML={{
                __html: data?.qldContent?.content ?? "",
              }}
            ></PStyled>
          </Margin>

          <Margin bottom={48}>
            <H4>South Australian Data</H4>{" "}
            <PStyled
              dangerouslySetInnerHTML={{
                __html: data?.saContent?.content ?? "",
              }}
            ></PStyled>
          </Margin>

          <Margin bottom={48}>
            <H4>New South Wales Data</H4>{" "}
            <PStyled
              dangerouslySetInnerHTML={{
                __html: data?.nswContent?.content ?? "",
              }}
            ></PStyled>
          </Margin>

          <Margin bottom={48}>
            <H4>Victorian Data</H4>
            <PStyled
              dangerouslySetInnerHTML={{
                __html: data?.vicContent?.content ?? "",
              }}
            ></PStyled>
          </Margin>

          <Margin bottom={48}>
            <H4>Western Australian Data</H4>
            <PStyled
              dangerouslySetInnerHTML={{
                __html: data?.waContent?.content ?? "",
              }}
            ></PStyled>
          </Margin>

          <Margin bottom={48}>
            <H4>Australian Capital Territory Data</H4>
            <PStyled
              dangerouslySetInnerHTML={{
                __html: data?.actContent?.content ?? "",
              }}
            ></PStyled>
          </Margin>

          <H4>Tasmanian Data</H4>
          <PStyled
            dangerouslySetInnerHTML={{
              __html: data?.tasContent?.content ?? "",
            }}
          ></PStyled>
        </Col>
      </Row>
    </DisclaimerContainer>
  );
};

export default CoreLogicDisclaimer;
