import { Card } from "@mc/components/card/card.styles";
import { Margin, SpaceBetween } from "@mc/components/core/spacing.styles";
import { H3 } from "@mc/components/core/typography.styles";
import { Col, Row } from "@mc/components/grid/grid.styles";
import { Table } from "@mc/components/tables/table.styles";
import { Tabs } from "@mc/components/tabs/tabs";
import { useTabQueryState } from "@mc/components/tabs/tabs.hooks";
import { useParams } from "react-router-dom";
import Layout, { LayoutBreadcrumb, LayoutTitle } from "../../../../layout/layout";
import Ledger from "./ledger/ledger";
import Maintenance from "./maintenance/maintenance";
import Overview from "./overview/overview";
import Performance from "./performance/performance";
import Tenants from "./tenants/tenants";
import usePropertyDetails from "./use-property-details";

const Property = () => {
  const ACCOUNT_TABS = [
    {
      label: "Overview",
      value: "overview",
    },
    {
      label: "Performance",
      value: "performance",
    },
    {
      label: "Ledger",
      value: "ledger",
    },
    {
      label: "Due diligence",
      value: "due_diligence",
    },
    {
      label: "Maintenance",
      value: "maintenance",
    },
    {
      label: "Tenants",
      value: "tenants",
    },
    {
      label: "Inventory",
      value: "inventory",
    },
  ];
  const { data, loading } = usePropertyDetails();
  const PROPERTY_ADDERSS = loading ? "..." : data?.activePortfolioProperty?.details?.location.singleLine;

  const { clientId } = useParams<{ clientId: string }>();
  const { tabValue, setTabValue } = useTabQueryState("tab", ACCOUNT_TABS[0].value);

  const MONTHLY_RECORDS = [
    {
      date: "June 2024",
      money_in: "$xxx",
      monry_out: "$xxx",
    },
    {
      date: "May 2024",
      money_in: "$xxx",
      monry_out: "$xxx",
    },
    {
      date: "April 2024",
      money_in: "$xxx",
      monry_out: "$xxx",
    },
    {
      date: "March 2024",
      money_in: "$xxx",
      monry_out: "$xxx",
    },
  ];

  return (
    <Layout>
      <LayoutBreadcrumb
        crumbs={[
          {
            label: "Active Portfolio",
            href: `/client/${clientId}/active-portfolio`,
          },
          {
            label: `${PROPERTY_ADDERSS}`,
            href: "",
          },
        ]}
      />
      <LayoutTitle title={`${PROPERTY_ADDERSS}`} />

      <Row>
        <Col lg={12}>
          <Margin top={8} bottom={8}>
            <Card>
              <SpaceBetween>
                <Tabs
                  activeTab={tabValue}
                  options={ACCOUNT_TABS}
                  contentPanelId="content"
                  onSelect={(option) => setTabValue(option.value)}
                  ariaDescription="Tabs"
                  marginBottom={10}
                />
              </SpaceBetween>

              {tabValue === ACCOUNT_TABS[0].value && (
                <>
                  <Overview activePortfolioProperty={data?.activePortfolioProperty} />
                </>
              )}
              {tabValue === ACCOUNT_TABS[1].value && (
                <>
                  <Performance />
                </>
              )}
              {tabValue === ACCOUNT_TABS[2].value && <Ledger />}
              {tabValue === ACCOUNT_TABS[3].value && <>Due Diligence</>}
              {tabValue === ACCOUNT_TABS[4].value && <Maintenance />}
              {tabValue === ACCOUNT_TABS[5].value && <Tenants />}
              {tabValue === ACCOUNT_TABS[6].value && <>Inventory</>}
            </Card>
          </Margin>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Margin top={8} bottom={8}>
            <Card>
              <section>
                <H3>Monthly Records</H3>

                <Table>
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Money in</th>
                      <th>Money out</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {MONTHLY_RECORDS.map((record, index) => (
                      <tr key={index}>
                        <td>{record.date}</td>
                        <td>{record.money_in}</td>
                        <td>{record.monry_out}</td>
                        <td>Edit Delete</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </section>
            </Card>
          </Margin>
        </Col>
      </Row>
    </Layout>
  );
};

export default Property;
