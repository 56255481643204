import { gql } from "@apollo/client";

export const GET_CORE_LOGIC_LEGAL_CONTENT = gql`
  query CoreLogicLegalContent {
    copyrightContent: coreLogicLegalContent(key: COPYRIGHT_AU) {
      category
      content
    }
    dataContent: coreLogicLegalContent(key: DATA_ANZ) {
      category
      content
    }
    qldContent: coreLogicLegalContent(key: STATE_QLD_AU) {
      category
      content
    }
    saContent: coreLogicLegalContent(key: STATE_SA_AU) {
      category
      content
    }
    nswContent: coreLogicLegalContent(key: STATE_NSW_AU) {
      category
      content
    }
    vicContent: coreLogicLegalContent(key: STATE_VIC_AU) {
      category
      content
    }
    waContent: coreLogicLegalContent(key: STATE_WA_AU) {
      category
      content
    }
    actContent: coreLogicLegalContent(key: STATE_ACT_AU) {
      category
      content
    }
    tasContent: coreLogicLegalContent(key: STATE_TAS_AU) {
      category
      content
    }
  }
`;
