import { Coordinates } from "./polygon";

export default function convertCoordinatesToGooglePaths(coordinates: Coordinates): { lat: number; lng: number }[][] {
  return (
    coordinates?.[0]?.map((path) =>
      (path ?? [])
        .map((points) => {
          if (!points[0] || !points[1]) return undefined;
          return { lat: points[1], lng: points[0] };
        })
        .filter((points): points is { lat: number; lng: number } => !!points)
    ) ?? []
  );
}
