import { PropertyCardList } from "@mc/common/blocks/property-cards";
import { Bouncer } from "@mc/components/bouncer/bouncer";
import { Card } from "@mc/components/card/card.styles";
import { Margin, SpaceBetween } from "@mc/components/core/spacing.styles";
import { H2 } from "@mc/components/core/typography.styles";
import { Col, Row } from "@mc/components/grid/grid.styles";
import { Stack } from "@mc/components/stack/stack.styles";
import { Tabs } from "@mc/components/tabs/tabs";
import { useTabQueryState } from "@mc/components/tabs/tabs.hooks";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import Layout, { LayoutBreadcrumb, LayoutTitle } from "../../../layout/layout";
import { ACTIVE_PORTFOLIO_PROPERTY_VIEW } from "../../../routes.constants";
import useActiveProperty from "./use-active-portfolio";
import CreatePropertyModal from "./create-property-modal";
export interface FormData {
  property: string;
}

const ActivePortfolio = () => {
  const ACCOUNT_TABS = [
    {
      label: "Tab1",
      value: "tab1",
    },
    {
      label: "Tab 2",
      value: "tab2",
    },
    {
      label: "Tab 3",
      value: "tab3",
    },
  ];

  const { data, loading } = useActiveProperty();
  const { tabValue, setTabValue } = useTabQueryState("tab", ACCOUNT_TABS[0].value);
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();

  return (
    <Layout>
      <LayoutBreadcrumb
        crumbs={[
          {
            label: "Active Portfolio",
            href: "",
          },
        ]}
      />
      <LayoutTitle title="Active Portfolio" />

      <Row>
        <Col lg={6}>
          {/* OVERVIEW CONTAINER SECTION */}

          <Margin top={8} bottom={8}>
            <Card>
              <H2 isPrimary={true}>Overview section</H2>

              <SpaceBetween>
                <Tabs
                  activeTab={tabValue}
                  options={ACCOUNT_TABS}
                  contentPanelId="content"
                  onSelect={(option) => setTabValue(option.value)}
                  ariaDescription="Tabs"
                  marginBottom={10}
                />
              </SpaceBetween>

              {tabValue === ACCOUNT_TABS[0].value && <>Tab1</>}
              {tabValue === ACCOUNT_TABS[1].value && <>Tab 2</>}
              {tabValue === ACCOUNT_TABS[2].value && <>Tab 3</>}
            </Card>
          </Margin>
        </Col>
        <Col lg={6}>
          {/* PROPERTY SECTION */}

          <Margin top={8} bottom={8}>
            <Card>
              <SpaceBetween $marginBottom={12}>
                <H2 marginBottom={0}>Property list</H2>
                <CreatePropertyModal />
              </SpaceBetween>

              {loading && <Bouncer />}

              {data && (
                <Stack spacing={16} marginBottom={40} as="ul">
                  {data?.activePortfolio?.properties.map((property) => {
                    return (
                      <PropertyCardList
                        key={property?.details?.id}
                        thumbnail={property?.details?.images.defaultImage.basePhotoUrl}
                        addressFormatted={property?.details?.location.singleLine}
                        bedrooms={property?.details?.coreAttributes.beds}
                        bathrooms={property?.details?.coreAttributes.baths}
                        carSpaces={property?.details?.coreAttributes.carSpaces}
                        landSize={property?.details?.coreAttributes.landArea}
                        onClick={() =>
                          clientId &&
                          navigate(
                            generatePath(ACTIVE_PORTFOLIO_PROPERTY_VIEW, {
                              clientId,
                              propertyId: `${property._id}`,
                            })
                          )
                        }
                      />
                    );
                  })}
                </Stack>
              )}
            </Card>
          </Margin>
        </Col>
      </Row>
    </Layout>
  );
};

export default ActivePortfolio;
