import { Suspense } from "react";
import { ApolloProvider } from "@apollo/client";
import Modal from "react-modal";
import { client } from "./libs/apollo";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./routes";
import useAuth from "./hooks/use-auth/use-auth";
import { GlobalStyles } from "@mc/components/core/global.styles";
import { ModalStyles } from "@mc/components/modal/modal.styles";
import Tooltip from "@mc/components/tooltips/tooltip";
import { NotificationContextProvider } from "@mc/components/notification/notification.context";
import Notification from "@mc/components/notification/notification";
import { Wrapper as GoogleMapWrapper } from "@googlemaps/react-wrapper";
import { ScrollToTop } from "@mc/common/utils";
import ClientWidget from "./shared/client-widget/client-widget";

Modal.setAppElement("#root");

const MainWrap = () => (
  <ApolloProvider client={client}>
    <GoogleMapWrapper apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY!} libraries={["drawing", "places"]}>
      <GlobalStyles />
      <ModalStyles />
      <Tooltip />
      <NotificationContextProvider>
        <Notification />
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </NotificationContextProvider>
    </GoogleMapWrapper>
  </ApolloProvider>
);

const App = () => {
  useAuth();

  return (
    <>
      <Routes>
        {routes.map(({ path, sidebar: Sidebar }, index) => (
          <Route key={index} path={path} element={Sidebar ? <Sidebar /> : <div />} />
        ))}
      </Routes>
      <Suspense fallback={""}>
        <Routes>
          {routes.map(({ path, main: Main }, index) => (
            <Route key={index} path={path} element={<Main />} />
          ))}
        </Routes>
      </Suspense>
      <ClientWidget />
    </>
  );
};

export default MainWrap;
