import { useState, useEffect, ReactNode } from "react";
import { SliderWrap, RangeInput, Label } from "./scale-bar.styles";
import { Flex } from "../grid/grid.styles";

type Props = {
  label?: ReactNode;
  leftText: ReactNode;
  rightText: ReactNode;
  percentage: number;
};

const ScaleBar = ({ label, leftText, rightText, percentage }: Props) => {
  const [percentageValue, setPercentageValue] = useState(0);
  useEffect(() => {
    setPercentageValue(percentage <= 0 ? 0 : percentage >= 1 ? 100 : percentage * 100);
  }, [percentage]);

  return (
    <SliderWrap>
      <div>
        <label>{label}</label>
        <RangeInput type="range" min={0} max={100} value={percentageValue} />
        <Flex justifyContent="space-between" style={{ marginTop: 8 }}>
          <Label>{leftText}</Label>
          <Label>{rightText}</Label>
        </Flex>
      </div>
    </SliderWrap>
  );
};

export default ScaleBar;
