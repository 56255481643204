import { gql } from "@apollo/client";

const MONEY_FRAGMENT = gql`
  fragment MoneyFragment on Money {
    __typename
    formatted(wrapNegativeInBrackets: true)
    defaultFormatted: formatted
    amount
    isNegative
  }
`;

const PERCENTAGE_FRAGMENT = gql`
  fragment PercentageFragment on Percentage {
    __typename
    formatted
    value
  }
`;

export const DETAILED_BREAKDOWN_FRAGMENT = gql`
  fragment DetailedBreakdownFragment on Daisy {
    properties {
      _id
      year
      propertyName
      totalFundsRequired {
        ...MoneyFragment
      }
      renovationBudgetIncPmFee {
        ...MoneyFragment
      }
      valueAddedRenovation {
        ...MoneyFragment
      }
      banksValuation {
        ...MoneyFragment
      }
      marketValue {
        ...MoneyFragment
      }
      debt {
        ...MoneyFragment
      }
      redrawAmounts {
        ...MoneyFragment
      }
      totalEquityList {
        ...MoneyFragment
      }
      equityAvailableToRedraw {
        ...MoneyFragment
      }
      fundsHeldInOffset {
        ...MoneyFragment
      }
      accessibleConstructionLoanAmountList {
        ...MoneyFragment
      }
      fundsAvailableAfterSale {
        ...MoneyFragment
      }
      ifSold {
        ...PercentageFragment
      }
      landValueAssumption {
        ...MoneyFragment
      }
      landToValueRatio {
        ...PercentageFragment
      }
      rentReceived {
        ...MoneyFragment
      }
      holdingCosts {
        ...MoneyFragment
      }
      loanCostsTotal {
        ...MoneyFragment
      }
      vacancyBuffer {
        ...MoneyFragment
      }
      maintenanceBuffer {
        ...MoneyFragment
      }
      grossIncomeWorst {
        ...MoneyFragment
      }
      grossIncomeBest {
        ...MoneyFragment
      }
      surplusFunds {
        ...MoneyFragment
      }
      averageHistoricalCapitalGrowth {
        ...PercentageFragment
      }
      discountedCapitalGrowth {
        ...PercentageFragment
      }
      discountOnAverageHistoricalCapitalGrowth {
        ...PercentageFragment
      }
      grossRentalYield {
        ...PercentageFragment
      }
      totalGrossReturn {
        ...PercentageFragment
      }
      interestRatePurchase {
        ...PercentageFragment
      }
      loanType
    }
    summary {
      _id
      year
      fundsHeldInOffset {
        ...MoneyFragment
      }
      totalAvailableEquityToRedraw {
        ...MoneyFragment
      }
      assumedBankAppraisal {
        ...MoneyFragment
      }
      assumedMarketAppraisal {
        ...MoneyFragment
      }
      debt {
        ...MoneyFragment
      }
      redrawAmounts {
        ...MoneyFragment
      }
      totalEquity {
        ...MoneyFragment
      }
      lvr {
        ...PercentageFragment
      }
      ifSoldFundsAvailable {
        ...MoneyFragment
      }
      ifSoldRoi {
        ...PercentageFragment
      }
      rentReceived {
        ...MoneyFragment
      }
      holdingCost {
        ...MoneyFragment
      }
      loanCost {
        ...MoneyFragment
      }
      assumedGrossIncomeWorst {
        ...MoneyFragment
      }
      assumedGrossIncomeBest {
        ...MoneyFragment
      }
      assumedGrossMonthlyIncomeWorst {
        ...MoneyFragment
      }
      assumedGrossMonthlyIncomeBest {
        ...MoneyFragment
      }
    }
  }
  ${MONEY_FRAGMENT}
  ${PERCENTAGE_FRAGMENT}
`;

export const GAME_PLAN_OPTION_CASH_FLOW_FIELDS_FRAGMENT = gql`
  fragment GamePlanOptionCashFlowFieldsFragment on GamePlanOption {
    _id
    balances {
      formatted
      amount
    }
    annualAllowances {
      formatted
    }
    ownerContributions {
      formatted
    }
  }
  ${MONEY_FRAGMENT}
  ${PERCENTAGE_FRAGMENT}
`;
