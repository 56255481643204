import { Props } from "./tabs.constants";
import { TabsSecondaryStyled, TabsTabSecondaryStyled } from "./tabs.style";
import { useTabLogic, useTabState } from "./tabs.hooks";

const TabsSecondary = (props: Props) => {
  const { ref, activeIndex, handleOnClick, handleOnFocus, handleKeyDown, setActiveIndex } = useTabLogic(props);

  return (
    <TabsSecondaryStyled
      ref={ref}
      role="tablist"
      aria-label={props.ariaDescription}
      aria-activedescendant={`secondary-tab-${activeIndex + 1}`}
      tabIndex={0}
      onClick={handleOnClick}
      onFocus={handleOnFocus}
      onBlur={() => setActiveIndex(-1)}
      onKeyDown={handleKeyDown}
      onMouseDown={(event) => event.preventDefault()}
      marginBottom={props.marginBottom}
    >
      {props.options.map((option, index) => (
        <TabsTabSecondaryStyled
          data-selector={`secondary-tab-${index}`}
          type="button"
          role="tab"
          key={`secondary-tab-${option.value}`}
          id={`secondary-tab-${index}`}
          aria-controls={props.contentPanelId}
          aria-selected={option.value === props.activeTab}
          isActive={option.value === props.activeTab}
          isFocused={activeIndex === index}
          tabIndex={-1}
          disabled={!!option.isDisabled}
          hasNotification={!!option.hasNotification}
        >
          {option.label}
        </TabsTabSecondaryStyled>
      ))}
    </TabsSecondaryStyled>
  );
};

export { TabsSecondary, useTabState };
