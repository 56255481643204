import { gql } from "@apollo/client";

export const GAME_PLAN_FEEDBACK_CONTRIBUTIONS_PROFILE_FRAGMENT = gql`
  fragment GamePlanFeedbackContributionsProfileFragment on Profile {
    _id
    finance {
      upfrontInvestment {
        formatted
        amount
      }
      lumpSumInvestments {
        amount {
          formatted
          amount
        }
        investedAt {
          formatted(format: "MMM YYYY")
          monthAndYear: formatted(format: "YYYY-MM")
        }
      }
      monthlyExpenseContributions {
        formatted
        amount
        periodInYear
      }
      expectedPassiveIncome
      expectedPassiveIncomeStartsAtYear
    }
  }
`;

export const GAME_PLAN_FEEDBACK_CONTRIBUTIONS_FRAGMENT = gql`
  fragment GamePlanFeedbackContributionsFragment on GamePlanFeedback {
    _id
    upfrontInvestment {
      amount
      formatted
    }
    lumpSumInvestments {
      amount {
        amount
        formatted
      }
      investedAt {
        monthAndYear: formatted(format: "YYYY-MM")
        year: formatted(format: "YYYY")
      }
    }
    monthlyExpenses {
      expense {
        amount
        formatted
      }
      periodInYear
    }
    expectedPassiveIncome
    expectedPassiveIncomeStartsAtYear
  }
`;
