import styled from "styled-components";
import { black, white } from "@mc/components/core/colours.styles";
export const Container = styled.div`
  padding: 10px;
  overflow-y: auto;
`;

export const Date = styled.div`
  width: 80px;
  font-size: 12px;
  color: ${black};
  text-align: right;
  padding-right: 10px;
`;

export const CircleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20px;
`;

export const VerticalLine = styled.div`
  position: absolute;
  top: 50%;
  bottom: -150%;
  width: 2px;
  background-color: ${black};
  z-index: -1;
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${white};
  border: 2px solid ${black};
  z-index: 1;
`;

export const Title = styled.div`
  font-size: 14px;
  color: ${black};
  padding-left: 10px;
  text-align: left;
  flex: 1;
`;
