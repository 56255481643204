import { Margin } from "@mc/components/core/spacing.styles";
import TooltipInfoModal from "@mc/components/tooltips/tooltip-info-modal";
import { PROPERTY_SCORE_HELP_TEXT } from "@mc/common/blocks/property-score/property-score.constants";
import { DataList } from "@mc/components/tables/data-list";
import { ButtonLink } from "@mc/components/buttons";
import { Badge } from "@mc/components/badge/badge.styles";
import { H4 } from "@mc/components/core/typography.styles";

const Tenants = () => {
  return (
    <div>
      <Margin bottom={24} top={24} left={24}>
        <H4>
          Tenants <TooltipInfoModal text={PROPERTY_SCORE_HELP_TEXT} />
        </H4>
      </Margin>

      <Margin left={24} right={24} bottom={24}>
        <DataList
          data={[
            {
              label: "Lease status",
              value: (
                <Badge colour={"green"} isSecondary={false}>
                  Perodic
                </Badge>
              ),
            },
            {
              label: "Commence date",
              value: "29 Apr, 2022",
            },
            {
              label: "Lease term",
              value: "12 months",
            },
            {
              label: "End date",
              value: "28 Apr, 2023",
            },
            {
              label: "Weekly rent",
              value: "$650",
            },
            {
              label: "Rent paid to",
              value: "31 May, 2024",
            },
            {
              label: "Rent review due",
              value: "1 Aug, 2024",
            },
            {
              label: "Next scheduled inspection",
              value: "15 Jul, 2024",
            },
            {
              label: "Bond paid",
              value: "$2,600",
            },
            {
              label: "Inspection report",
              value: <ButtonLink>View</ButtonLink>,
            },
            {
              label: "Lease agreement",
              value: <ButtonLink>View</ButtonLink>,
            },
            {
              label: "Council rate notice",
              value: <ButtonLink>View</ButtonLink>,
            },
            {
              label: "Landlord insurance policy",
              value: <ButtonLink>View</ButtonLink>,
            },
          ]}
        />
      </Margin>
    </div>
  );
};

export default Tenants;
