import { useQuery } from "@apollo/client";
import { ActivePortfolioQuery, ActivePortfolioQueryVariables } from "@mc/common/gql/types";
import { useParams } from "react-router-dom";
import { CLIENT_ACTIVE_PROPERTY } from "./active-portfolio.gql";

export default function useActiveProperty() {
  const { clientId } = useParams<{ clientId: string }>();

  const { data, loading } = useQuery<ActivePortfolioQuery, ActivePortfolioQueryVariables>(CLIENT_ACTIVE_PROPERTY, {
    variables: { clientId: clientId! },
    skip: !clientId,
  });

  return { loading, data };
}
