import { useLocation } from "react-router-dom";
import useLogout from "../hooks/use-logout";
import Logo from "../shared/logo/logo";
import * as Styled from "./navigation.styles";
import NavigationStaffProfile from "./navigation-staff-profile";
import { CLIENTS, COUPON, PROFESSIONAL_SERVICE, SUBURB } from "../routes.constants";

const NAV_ITEMS = [
  {
    name: "Home",
    pathname: CLIENTS,
    isActive: true,
  },
  {
    name: "Suburbs",
    pathname: SUBURB,
    isActive: true,
  },
  {
    name: "Coupons",
    pathname: COUPON,
    isActive: true,
  },
  {
    name: "Professional service",
    pathname: PROFESSIONAL_SERVICE,
    isActive: true,
  },
  // {
  //   name: "Markets",
  //   pathname: "/",
  //   isActive: false,
  // },
  // {
  //   name: "States",
  //   pathname: "/",
  //   isActive: false,
  // },
  // {
  //   name: "Game Plan settings",
  //   pathname: "/game-plan-settings",
  //   isActive: false,
  // },
  // {
  //   name: "Calculators",
  //   pathname: "/calculators",
  //   isActive: false,
  // },
  // {
  //   name: "Reporting",
  //   pathname: "/",
  //   isActive: false,
  // },
];

const Navigation = () => {
  const { logout } = useLogout();
  const { pathname: currentPathname } = useLocation();

  return (
    <Styled.Navigation>
      <Styled.NavigationHeader>
        <Logo />
      </Styled.NavigationHeader>
      <Styled.NavigationItems>
        {NAV_ITEMS.map(({ name, pathname, isActive }) => (
          <Styled.NavigationItem key={name} isCurrent={currentPathname === pathname} isActive={isActive}>
            <Styled.NavigationItemLink
              to={pathname}
              onClick={(e) => {
                if (!isActive) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              {name}
            </Styled.NavigationItemLink>
          </Styled.NavigationItem>
        ))}
        <Styled.NavigationItem>
          <Styled.NavigationItemButton type="button" onClick={() => logout()}>
            Sign out
          </Styled.NavigationItemButton>
        </Styled.NavigationItem>
      </Styled.NavigationItems>
      <NavigationStaffProfile />
    </Styled.Navigation>
  );
};

export default Navigation;
