import { Margin } from "@mc/components/core/spacing.styles";
import { H3, H4 } from "@mc/components/core/typography.styles";
import TooltipInfoModal from "@mc/components/tooltips/tooltip-info-modal";
import { PROPERTY_SCORE_HELP_TEXT } from "@mc/common/blocks/property-score/property-score.constants";
import { useState } from "react";
import { Switch } from "@mc/components/switch/switch";
import { Flex } from "@mc/components/grid/grid.styles";
import { DataList } from "@mc/components/tables/data-list";

const Ledger = () => {
  const [isActive, setIsActive] = useState(true);
  return (
    <div>
      <Margin top={24} left={24}>
        <H3>
          Here are all the important pieces of your rental puzzle. Understand your cash flow better by reviewing the
          real-time tenant ledger{" "}
        </H3>
      </Margin>
      <Margin bottom={24} top={24} left={24}>
        <H4>
          Disbursements <TooltipInfoModal text={PROPERTY_SCORE_HELP_TEXT} />
        </H4>
      </Margin>

      <Margin left={24} right={24} bottom={24}>
        <DataList
          data={[
            {
              label: "Disbursements",
              value: "at weekly intervals",
            },
            {
              label: "Last disbursement",
              value: "12 Feb, 2024",
            },
            {
              label: "Disbursement amount",
              value: "$800",
            },
            {
              label: "Next disbursement",
              value: "19 Feb, 2024",
            },
          ]}
        />
      </Margin>

      <Margin bottom={24} top={24} left={24}>
        <H4>
          Ledger <TooltipInfoModal text={PROPERTY_SCORE_HELP_TEXT} />
        </H4>
      </Margin>

      <Margin left={24} right={24} bottom={24}>
        <DataList
          data={[
            {
              label: "OPENING BALANCE",
              value: "$0",
              isEmphasised: true,
            },
            {
              label: "Money in",
              value: "$800",
            },
            {
              label: "Money out",
              value: "$0",
            },
            {
              label: "Bills pending",
              value: "$100",
            },
            {
              label: "Invoices pending",
              value: "$200",
            },
            {
              label: "Withhold",
              value: "$0",
            },
            {
              label: "EXPECTED DISBURSEMENT",
              value: "$500",
              isEmphasised: true,
            },
          ]}
        />
      </Margin>

      <Margin top={24} left={24}>
        <H3>
          Here are all the important pieces of your rental puzzle. Understand your cash flow better by reviewing the
          real-time tenant ledger{" "}
        </H3>
        <Flex justifyContent={"center"}>
          <Switch
            checked={isActive}
            onChange={(isActive) => setIsActive(isActive)}
            leftLabel="Chart"
            rightLabel="Table"
          />
        </Flex>
      </Margin>

      <Margin bottom={24} top={24} left={24}>
        <H4>
          Your bills <TooltipInfoModal text={PROPERTY_SCORE_HELP_TEXT} />
        </H4>
      </Margin>

      <Margin left={24} right={24} bottom={24}>
        <DataList
          data={[
            {
              label: "Council",
              valueSet: ["Jan 5, 24", "$450"],
            },
            {
              label: "Water",
              valueSet: ["Feb 25, 24", "$90"],
            },
            {
              label: "Insurance",
              valueSet: ["jun 1, 24", "$500"],
            },
            {
              label: "Strata",
              valueSet: ["Aug 5, 24", "$500"],
            },
            {
              label: "Gardening",
              valueSet: ["Dec 9, 24", "$150"],
            },
          ]}
        />
      </Margin>
    </div>
  );
};

export default Ledger;
