import styled from "styled-components/macro";
import { SpacingValue } from "../core/spacing.styles";
import { rem } from "../core/styles";

type MapContainerProps = {
  $height?: number;
  $marginBottom?: SpacingValue;
};

export const MapContainer = styled.div<MapContainerProps>`
  padding-top: 56%;
  position: relative;
  margin: 0 0 ${({ $marginBottom }) => ($marginBottom !== undefined ? rem($marginBottom) : rem(40))} 0;

  ${({ $height }) =>
    $height &&
    `
  height: ${rem($height)};
`};
`;

export const MapInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
