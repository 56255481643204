import { Props } from "./tabs.constants";
import { TabsStyled, TabsTabStyled } from "./tabs.style";
import { useTabLogic, useTabState } from "./tabs.hooks";

const Tabs = (props: Props) => {
  const { ref, activeIndex, handleOnClick, handleOnFocus, handleKeyDown, setActiveIndex } = useTabLogic(props);

  return (
    <TabsStyled
      ref={ref}
      role="tablist"
      aria-label={props.ariaDescription}
      aria-activedescendant={`tab-${activeIndex + 1}`}
      tabIndex={0}
      onClick={handleOnClick}
      onFocus={handleOnFocus}
      onBlur={() => setActiveIndex(-1)}
      onKeyDown={handleKeyDown}
      onMouseDown={(event) => event.preventDefault()}
      $marginBottom={props.marginBottom}
    >
      {props.options.map((option, index) => (
        <TabsTabStyled
          data-selector={`tab-${index}`}
          type="button"
          role="tab"
          key={`tab-${option.value}`}
          id={`tab-${index}`}
          aria-controls={`${props.contentPanelId}-${index}`}
          aria-selected={option.value === props.activeTab}
          isActive={option.value === props.activeTab}
          isFocused={activeIndex === index}
          tabIndex={-1}
          disabled={!!option.isDisabled}
          hasNotification={!!option.hasNotification}
        >
          {option.label}
          <div id={`${props.contentPanelId}-${index}`} hidden />
        </TabsTabStyled>
      ))}
    </TabsStyled>
  );
};

export { Tabs, useTabState };
