import { PROPERTY_SCORE_HELP_TEXT } from "@mc/common/blocks/property-score/property-score.constants";
import { Margin } from "@mc/components/core/spacing.styles";
import { H3, H4 } from "@mc/components/core/typography.styles";
import TooltipInfoModal from "@mc/components/tooltips/tooltip-info-modal";
import MaintenanceCard from "./maintenance-card";
import { DataList } from "@mc/components/tables/data-list";
import { Stack } from "@mc/components/stack/stack.styles";

const Maintenance = () => {
  return (
    <div>
      <Margin bottom={24} top={24} left={24}>
        <H4>
          Scheduled maintenance <TooltipInfoModal text={PROPERTY_SCORE_HELP_TEXT} />
        </H4>
      </Margin>

      <Margin left={24} right={24} bottom={24}>
        <DataList
          data={[
            {
              label: "Total scheduled",
              value: "$3,730",
            },
            {
              label: "Total completed",
              value: "$8,450",
            },
          ]}
        />
      </Margin>

      <Margin bottom={24} top={24} left={24}>
        <H3>Scheduled</H3>

        <Stack spacing={12}>
          <MaintenanceCard
            service={"Repaint the fence"}
            date={"Mar 2022"}
            amount={"$800"}
            status={{
              label: "Pending",
              colour: "black",
              isSecondary: true,
            }}
          />

          <MaintenanceCard
            service={"Replace the boiler"}
            date={"June 2022"}
            amount={"$2,500"}
            status={{
              label: "Pending",
              colour: "black",
              isSecondary: true,
            }}
          />

          <MaintenanceCard
            service={"Pest control"}
            date={"June 2022"}
            amount={"$280"}
            status={{
              label: "Pending",
              colour: "black",
              isSecondary: true,
            }}
          />

          <MaintenanceCard
            service={"Pool maintenance"}
            date={"Jun 2022"}
            amount={"$150"}
            status={{
              label: "Pending",
              colour: "black",
              isSecondary: true,
            }}
          />
        </Stack>
      </Margin>

      <Margin bottom={24} top={24} left={24}>
        <H3>Completed</H3>

        <Stack spacing={12}>
          <MaintenanceCard
            service={"Repaint the fence"}
            date={"19 Jan 2022"}
            amount={"$7,250.00"}
            status={{
              label: "Complete",
              colour: "scaleGreen",
              isSecondary: false,
            }}
            serviceProvider={"Mr. Fix it"}
          />

          <MaintenanceCard
            service={"Trim the hedge front and rear"}
            date={"June 2022"}
            amount={"$1,200.00"}
            status={{
              label: "Complete",
              colour: "scaleGreen",
              isSecondary: false,
            }}
            serviceProvider={"Jims Mowing Pvt. Ltd."}
          />
        </Stack>
      </Margin>

      <Margin bottom={24} top={24} left={24}>
        <H4>
          Repairs <TooltipInfoModal text={PROPERTY_SCORE_HELP_TEXT} />
        </H4>
      </Margin>

      <Margin left={24} right={24} bottom={24}>
        <DataList
          data={[
            {
              label: "Total scheduled",
              value: "$3,730",
            },
            {
              label: "Total completed",
              value: "$8,450",
            },
          ]}
        />
      </Margin>

      <Margin bottom={24} top={24} left={24}>
        <H3>Completed</H3>

        <Stack spacing={12}>
          <MaintenanceCard
            service={"Blocked drain in the bath"}
            date={"19 May 2022"}
            amount={"$338"}
            status={{
              label: "Complete",
              colour: "scaleGreen",
              isSecondary: false,
            }}
            serviceProvider={"Mr. Fix it"}
          />

          <MaintenanceCard
            service={"Broken window in lounge"}
            date={"4 Sep 2021"}
            amount={"$475"}
            status={{
              label: "Complete",
              colour: "scaleGreen",
              isSecondary: false,
            }}
            serviceProvider={"RJ Glass Pvt. Ltd."}
          />
        </Stack>
      </Margin>
    </div>
  );
};

export default Maintenance;
