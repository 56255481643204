import { useMutation, useQuery } from "@apollo/client";
import { GET_SETTING_GROUP, UPDATE_SINGLE_VALUE_SETTINGS } from "./setting.gql";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import useSetting from "./use-setting";
import {
  GetSettingGroupQuery,
  GetSettingGroupQueryVariables,
  UpdateSingleValueSettingsMutation,
  UpdateSingleValueSettingsMutationVariables,
} from "@mc/common/gql/types";

type SingleValueSettingFormData = { [key: string]: number };

export default function useSingleValueSetting({ keyName }: { keyName: string }) {
  const { handleSubmit, control } = useForm<SingleValueSettingFormData>();

  const [update, { loading: isUpdating }] = useMutation<
    UpdateSingleValueSettingsMutation,
    UpdateSingleValueSettingsMutationVariables
  >(UPDATE_SINGLE_VALUE_SETTINGS, {
    onCompleted: () => {
      setEditMode(false);
    },
  });

  const { data } = useQuery<GetSettingGroupQuery, GetSettingGroupQueryVariables>(GET_SETTING_GROUP, {
    variables: { key: keyName },
    onCompleted: () => {
      setSelectedFilterValues(filters.map((filter) => filter[0]));
    },
  });

  const {
    editMode,
    setEditMode,
    selectedFilterValues,
    setSelectedFilterValues,
    hasFilters,
    filters,
    onFilterUpdate,
  } = useSetting({ data });

  const filteredSettings = data?.settingGroup?.settings.filter((setting) =>
    selectedFilterValues.every((selectedValue) => setting.filters?.includes(selectedValue))
  );

  const onSubmit = handleSubmit(
    useCallback(
      async (formData: SingleValueSettingFormData) => {
        await update({
          variables: {
            input: Object.keys(formData).map((key) => ({
              key,
              value: formData[key],
            })),
          },
        });
      },
      [update]
    )
  );

  return {
    settingGroup: data?.settingGroup,
    settings: filteredSettings || [],
    hasFilters,
    filters,
    selectedFilterValues,
    onFilterUpdate,
    editMode,
    setEditMode,
    control,
    onSubmit,
    isUpdating,
  };
}
