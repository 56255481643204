import { gql } from "@apollo/client";

export const GET_CLIENT_KEY_DATES = gql`
  query GetKeyDates($clientId: String!) {
    client(id: $clientId) {
      _id
      createdAt {
        # sign up date
        formatted(format: "DD MMM YYYY")
      }
      onboardingCompletedAt {
        formatted(format: "DD MMM YYYY")
      }
      onboardingConfirmedAt {
        formatted(format: "DD MMM YYYY")
      }
      gamePlan {
        _id
        options {
          # array (get the first element of the array)
          _id
          publishedAt {
            formatted(format: "DD MMM YYYY")
          }
        }
        confirmedByClientAt {
          formatted(format: "DD MMM YYYY")
        }
      }
      purchases {
        # array (get the first element from the array)
        briefStartedAt {
          # Search commenced
          formatted(format: "DD MMM YYYY")
        }
        preApproval {
          _id
          loans {
            # array (get the first one)
            _id
            expiry {
              # Pre-approval expiry
              formatted(format: "DD MMM YYYY")
            }
          }
        }
        shortlistProperties(status: TOP_RESULT) {
          _id
          unconditionalExchange {
            _id
            unconditionalExchangedAt {
              formatted(format: "DD MMM YYYY")
            }
          }
          finalOutcome {
            _id
            settlementDate {
              formatted(format: "DD MMM YYYY")
            }
          }
        }
      }
    }
  }
`;
