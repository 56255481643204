import { useQuery } from "@apollo/client";
import { GetGamePlanQuery, GetGamePlanQueryVariables } from "@mc/common/gql/types";
import { ChartContainer } from "@mc/components/charts/chart";
import { Margin } from "@mc/components/core/spacing.styles";
import { useTabState } from "@mc/components/tabs/tabs.hooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GET_GAME_PLAN } from "../../../game-plan/game-plan.gql";
import { useAreaChart } from "@mc/components/charts/chart.hooks";
import { ChartAccessor } from "../../../game-plan/comparison/comparison.constants";
import { TabsSecondary } from "@mc/components/tabs/tabs-secondary";
import { H4, PStyled } from "@mc/components/core/typography.styles";
import HoldingCosts from "../overview/property-score";
import { Tabs } from "@mc/components/tabs/tabs";
import { DataList } from "@mc/components/tables/data-list";

const CashflowChart = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const { data: gamePlanData, loading } = useQuery<GetGamePlanQuery, GetGamePlanQueryVariables>(GET_GAME_PLAN, {
    variables: { clientId: clientId! },
  });

  const { gamePlan } = gamePlanData ?? {};

  const { chartRef } = useAreaChart({
    targetElementId: "cashflowChart",
    xAxis: gamePlan?.options[0].daisy?.summary.year,
    series: gamePlan?.options?.map((option: any) => ({
      name: option.name,
      data: option.daisy?.summary["totalEquity"! as ChartAccessor].map((num: any) => Math.round(num.amount)) ?? [],
    })),
  });

  useEffect(() => {
    chartRef?.current?.updateSeries(
      gamePlan?.options?.map((option) => ({
        name: option.name,
        data: option.daisy?.summary["totalEquity"! as ChartAccessor].map((num) => Math.round(num.amount)) ?? [],
      }))
    );
  }, [chartRef, gamePlan]);

  //   FOR TABS SECTION

  const TABS_CONTENT = {
    options: [
      {
        label: "FY 20",
        value: 1,
      },
      {
        label: "FY 21",
        value: 2,
      },
      {
        label: "FY 22",
        value: 3,
      },
      {
        label: "FY 23",
        value: 4,
      },
      {
        label: "FY 24",
        value: 5,
      },
      {
        label: "FY 25",
        value: 6,
      },
    ],
    contentPanelId: "content",
    ariaDescription: "Tabs example",
  };

  const { tab, setTab } = useTabState(TABS_CONTENT.options[0]);

  const tabData = {
    options: [
      {
        label: "Annual",
        value: 1,
      },
      {
        label: "Monthly",
        value: 2,
      },
    ],
    contentPanelId: "content",
    ariaDescription: "Tabs example",
    isSecondary: false,
  };
  const { tab: tabAnual, setTab: setTabAnual } = useTabState(tabData.options[0]);
  return (
    <div>
      <Margin bottom={24} top={24}>
        <H4>Cash flow</H4>
      </Margin>
      <Margin bottom={12} top={12} left={24} right={24}>
        <div>
          <Tabs {...tabData} activeTab={tabAnual?.value} onSelect={setTabAnual} />
          <div id="content">
            {tabAnual?.value === 1 && <PStyled>{tabAnual.label}</PStyled>}
            {tabAnual?.value === 2 && <PStyled>{tabAnual.label}</PStyled>}
          </div>
        </div>
      </Margin>
      <div>{!loading && <ChartContainer id="cashflowChart" />}</div>
      <Margin left={24} right={24} bottom={24}>
        <DataList
          data={[
            {
              label: "Total Equity",
              value: "N/A",
            },
            {
              label: "R.O.I",
              value: "N/A",
            },
            {
              label: "Available Equity",
              value: "N/A",
            },
            {
              label: "Asset Value",
              value: "N/A",
            },
            {
              label: "Debt",
              value: "N/A",
            },
            {
              label: "LVR",
              value: "N/A",
            },
          ]}
        />
      </Margin>
      <div>
        <TabsSecondary {...TABS_CONTENT} activeTab={tab?.value} onSelect={setTab} />
        <div id="content">
          {tab?.value === 1 && (
            <PStyled>
              <HoldingCosts />
            </PStyled>
          )}
          {tab?.value === 2 && <PStyled>{tab.label}</PStyled>}
          {tab?.value === 3 && <PStyled>{tab.label}</PStyled>}
          {tab?.value === 4 && <PStyled>{tab.label}</PStyled>}
          {tab?.value === 5 && <PStyled>{tab.label}</PStyled>}
          {tab?.value === 6 && <PStyled>{tab.label}</PStyled>}
        </div>
      </div>
    </div>
  );
};

export default CashflowChart;
