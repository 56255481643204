import { Children, useCallback, useRef } from "react";
import { ARROW_DOWN, ARROW_UP, HOME, END, ENTER } from "../keyboard/keyboard.constants";
import { AccordionContext, AccordionContextTypes, SelectableContext } from "./accordion.context";
import { AccordionProps as Props } from "./accordion.constants";
import AccordionToggle from "./accordion-toggle";
import { AccordionSurfaceCard } from "./accordion-surface-card";
import { AccordionStyled } from "./accordion.styles";

const Accordion = ({ id, children, className, hasBorderTop }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const getAccordionTriggers = useCallback(() => {
    if (ref && ref.current) {
      const nodes = ref.current.querySelectorAll(`.accordion-trigger`) as NodeListOf<HTMLElement>;
      return [].slice.call(nodes);
    }
    return [];
  }, []);

  const focusFirst = useCallback<(elements: HTMLElement[]) => void>((accordionTriggers) => {
    accordionTriggers[0].focus();
  }, []);

  const focusLast = useCallback<(elements: HTMLElement[]) => void>((accordionTriggers) => {
    accordionTriggers[accordionTriggers.length - 1].focus();
  }, []);

  const focusNext = useCallback<(index: number, elements: HTMLElement[]) => void>(
    (currentIndex, accordionTriggers) => {
      if (currentIndex === accordionTriggers.length - 1) {
        focusFirst(accordionTriggers);
        return;
      }
      accordionTriggers[currentIndex + 1].focus();
    },
    [focusFirst]
  );

  const focusPrevious = useCallback<(index: number, elements: HTMLElement[]) => void>(
    (currentIndex, accordionTriggers) => {
      if (currentIndex === 0) {
        focusLast(accordionTriggers);
        return;
      }
      accordionTriggers[currentIndex - 1].focus();
    },
    [focusLast]
  );

  const handleKeyDown = useCallback<AccordionContextTypes["onHeaderKeyDown"]>(
    (event) => {
      const accordionTriggers = getAccordionTriggers() as HTMLElement[];
      const { key, currentTarget } = event;

      switch (key) {
        case ARROW_DOWN: {
          event.preventDefault();
          const currentIndex = accordionTriggers.indexOf(currentTarget);
          focusNext(currentIndex, accordionTriggers);
          break;
        }
        case ARROW_UP: {
          event.preventDefault();
          const currentIndex = accordionTriggers.indexOf(currentTarget);
          focusPrevious(currentIndex, accordionTriggers);
          break;
        }
        case HOME: {
          event.preventDefault();
          focusFirst(accordionTriggers);
          break;
        }
        case END: {
          event.preventDefault();
          focusLast(accordionTriggers);
          break;
        }
        case ENTER: {
          // could use target.matches but for IE11
          if (event.currentTarget.classList.contains("accordion-trigger")) {
            event.preventDefault();
            const currentIndex = accordionTriggers.indexOf(currentTarget);
            accordionTriggers[currentIndex].click();
          }
          break;
        }
      }
    },
    [focusNext, focusPrevious, focusFirst, focusLast, getAccordionTriggers]
  );

  return (
    <AccordionContext.Provider
      value={{
        onHeaderKeyDown: handleKeyDown,
        id: id,
      }}
    >
      <AccordionStyled className={`accordion ${className}`} ref={ref} hasBorderTop={hasBorderTop}>
        {Children.toArray(children).map((child, idx) => (
          <SelectableContext.Provider key={`ctx.${idx}`} value={{ index: idx }}>
            {child}
          </SelectableContext.Provider>
        ))}
      </AccordionStyled>
    </AccordionContext.Provider>
  );
};

export { Accordion, AccordionToggle, AccordionSurfaceCard };
