import { useMutation, useQuery } from "@apollo/client";
import {
  GetClientNotesQuery,
  GetClientNotesQueryVariables,
  UpdateClientNotesInput,
  UpdateClientNotesMutation,
  UpdateClientNotesMutationVariables,
} from "@mc/common/gql/types";
import { Bouncer } from "@mc/components/bouncer/bouncer";
import { PrimaryLoadingButton } from "@mc/components/buttons";
import { darkGrey } from "@mc/components/core/colours.styles";
import { SpaceBetween } from "@mc/components/core/spacing.styles";
import { PStyledSecondary } from "@mc/components/core/typography.styles";
import { useForm } from "react-hook-form";
import { useMatch } from "react-router-dom";
import { NotesForm, TextAreaInputAlt } from "../client-widget.styles";
import { GET_CLIENT_NOTES, UPDATE_CLIENT_NOTES } from "./notes.gql";

const Notes = () => {
  const match = useMatch("/client/:clientId/*");
  const clientId = match?.params.clientId;

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<UpdateClientNotesInput>();

  const { data, loading } = useQuery<GetClientNotesQuery, GetClientNotesQueryVariables>(GET_CLIENT_NOTES, {
    skip: !clientId,
    variables: { clientId: clientId! },
    onCompleted: (data) => {
      if (data) {
        reset({
          notes: data.client.notes ?? "",
        });
      }
    },
  });

  const [update, { loading: updating }] = useMutation<UpdateClientNotesMutation, UpdateClientNotesMutationVariables>(
    UPDATE_CLIENT_NOTES,
    {
      onCompleted: (data) => {
        reset(
          {
            notes: data.updateClientNotes.notes ?? "",
          },
          { keepDirty: false }
        );
      },
    }
  );

  const onSubmit = handleSubmit((formData) => {
    if (clientId) {
      update({
        variables: {
          clientId,
          updateClientNotesInput: formData,
        },
      });
    }
  });

  // use command+enter or ctrl+enter to submit the form
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
      onSubmit();
    }
  };

  if (loading) return <Bouncer />;

  return (
    <NotesForm onSubmit={onSubmit}>
      <TextAreaInputAlt {...register("notes")} onKeyDown={handleKeyDown} />
      <SpaceBetween>
        <PStyledSecondary colour={darkGrey} marginBottom={0}>
          {data?.client.notesUpdatedAt && `Last updated: ${data.client.notesUpdatedAt.formatted}`}
        </PStyledSecondary>
        <PrimaryLoadingButton size={"small"} isLoading={updating} disabled={!isDirty}>
          Save
        </PrimaryLoadingButton>
      </SpaceBetween>
    </NotesForm>
  );
};

export default Notes;
