import { Margin } from "@mc/components/core/spacing.styles";
import ScaleBar from "@mc/components/scalebar/scale-bar";
import PerformanceChart from "./performance-chart";
import CashflowChart from "./cashflow-chart";
import { DataList } from "@mc/components/tables/data-list";
import { H4 } from "@mc/components/core/typography.styles";

const Performance = () => {
  return (
    <div>
      <PerformanceChart />

      <Margin left={24} right={24} bottom={24}>
        <DataList
          data={[
            {
              label: "Total Equity",
              value: "N/A",
            },
            {
              label: "R.O.I",
              value: "N/A",
            },
            {
              label: "Available Equity",
              value: "N/A",
            },
            {
              label: "Asset Value",
              value: "N/A",
            },
            {
              label: "Debt",
              value: "N/A",
            },
            {
              label: "LVR",
              value: "N/A",
            },
          ]}
        />
      </Margin>

      <label>Last appraisal — {"June 2022"}</label>

      <Margin bottom={24} top={48}>
        <CashflowChart />
      </Margin>

      <Margin bottom={24} top={48}>
        <H4>Cash flow buffers</H4>
      </Margin>

      <div>
        <Margin bottom={48} top={24}>
          <ScaleBar
            label={"Maintainance allowance"}
            leftText={"$800 / $3,000"}
            rightText={"26% spent"}
            percentage={0.26}
          />
        </Margin>
        <ScaleBar label={"Vacancy allowance"} leftText={"5 days / 14 days"} rightText={"35 %"} percentage={0.35} />
      </div>
    </div>
  );
};

export default Performance;
