import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";

import { black, tan } from "@mc/components/core/colours.styles";
import { rem, standardFocusState } from "@mc/components/core/styles";
import { fontSize16, fontWeightMedium } from "@mc/components/core/typography.styles";
import { AvatarStyled } from "@mc/components/avatar/avatar.styles";
import { LAYOUT_SIDE_DRAWER } from "./layout.constants";

export const Navigation = styled.nav`
  background-color: ${tan};
  position: fixed;
  padding-bottom: ${rem(140)};
  left: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  width: ${rem(LAYOUT_SIDE_DRAWER)};
`;

export const NavigationHeader = styled.div`
  display: flex;
  height: ${rem(64)};
  align-items: center;
  padding-left: ${rem(16)};
`;

export const NavigationItems = styled.ul`
  margin: 0;
  padding: 0;
`;

const NavLinkCss = css`
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  color: ${black};
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 ${rem(16)};
  text-decoration: none;
  text-align: left;
  width: 100%;

  ${standardFocusState}

  &:hover {
    cursor: pointer;
  }
`;

export const NavigationItemLink = styled(Link)`
  ${NavLinkCss}
`;

export const NavigationItemButton = styled.button`
  ${NavLinkCss}
  font-size: 100%;
`;

type NavigationItemProps = {
  isCurrent?: boolean;
  isActive?: boolean;
};
export const NavigationItem = styled.li<NavigationItemProps>`
  list-style: none;
  height: ${rem(48)};
  font-size: ${fontSize16};

  ${({ isCurrent }) =>
    isCurrent &&
    `
    ${NavigationItemLink} {
      background-color: rgba(0, 0, 0, 0.15);
    }
  `}

  ${({ isActive }) =>
    isActive === false &&
    `
      opacity: 0.3;
  `}
`;

export const NavigationBack = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  height: ${rem(60)};
  svg {
    margin-right: ${rem(8)};
  }
`;

export const NavigationProfile = styled.div`
  display: flex;
  align-items: center;
  padding: ${rem(16)};

  ${AvatarStyled} {
    margin-right: ${rem(8)};
    min-width: ${rem(32)};
  }
`;

export const NavigationProfileName = styled.span`
  font-weight: ${fontWeightMedium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NavigationSecondary = styled.div`
  background-color: ${tan};
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 0;
  left: 0;
  width: ${rem(LAYOUT_SIDE_DRAWER)};
`;
