import { PStyledSecondary } from "@mc/components/core/typography.styles";
import { FooterAnchor, GenericLogoContainer, LogoContainer } from "../generic-logo/generic-logo.styles";
import CoreLogicLogo from "./core-logic-logo.svg";

type Props = {
  center?: boolean;
};

const CoreLogic = ({ center }: Props): JSX.Element => {
  return (
    <GenericLogoContainer center={center}>
      <PStyledSecondary marginBottom={0}>
        Property data provided by{" "}
        <FooterAnchor href="/core-logic-disclaimer" target="_blank" rel="noopener noreferrer" $fontWeight="normal">
          CoreLogic
        </FooterAnchor>
      </PStyledSecondary>
      <LogoContainer>
        <img src={CoreLogicLogo} alt="Core Logic Logo" />
      </LogoContainer>
    </GenericLogoContainer>
  );
};

export default CoreLogic;
