import { useMemo } from "react";
import Breadcrumbs from "@mc/components/breadcrumbs/breadcrumbs";
import Layout, { LayoutTitle } from "../../layout/layout";
import * as NavStyled from "../../layout/navigation.styles";
import SingleValueSetting from "./single-value-setting";

const GamePlanSettings = () => {
  const PAGE_TITLE = "Game Plan settings";

  const items = useMemo(() => {
    return [
      {
        name: "Markets",
        key: "MARKETS",
      },
      {
        name: "Timeframes",
        key: "TIMEFRAMES",
      },
      {
        name: "Interest rate defaults",
        key: "INTEREST_RATE_DEFAULTS",
      },
      {
        name: "Residential investment loans",
        key: "RESIDENTIAL_INVESTMENT_LOANS",
      },
      {
        name: "Commercial investment loans",
        key: "COMMERCIAL_INVESTMENT_LOANS",
      },
      {
        name: "PPOR loans",
        key: "PPOR_LOANS",
      },
      {
        name: "LMI",
        key: "LMI",
      },
      {
        name: "LMI stamp duty",
        key: "LMI_STAMP_DUTY",
      },
      {
        name: "Investment property premium",
        key: "INVESTMENT_PROPERTY_PREMIUM",
      },
      {
        name: "Purchasing costs",
        key: "PURCHASING_COSTS",
      },
      {
        name: "Project costs",
        key: "PROJECT_COSTS",
      },
      {
        name: "Holding costs",
        key: "HOLDING_COSTS",
      },
    ];
  }, []);

  return (
    <Layout
      secondaryNav={
        <NavStyled.Navigation>
          <NavStyled.NavigationItems>
            {items.map((item) => (
              <NavStyled.NavigationItem key={item.key}>
                <NavStyled.NavigationItemButton
                  onClick={() => document.getElementById(item.key)?.scrollIntoView({ behavior: "smooth" })}
                >
                  {item.name}
                </NavStyled.NavigationItemButton>
              </NavStyled.NavigationItem>
            ))}
          </NavStyled.NavigationItems>
        </NavStyled.Navigation>
      }
    >
      <>
        <Breadcrumbs
          breadcrumbs={[
            {
              label: PAGE_TITLE,
              href: "",
            },
          ]}
        />
        <LayoutTitle title="Game Plan settings" />

        {items.map((item) => (
          <SingleValueSetting key={item.key} keyName={item.key} />
        ))}
      </>
    </Layout>
  );
};

export default GamePlanSettings;
