import { useContext, useState, useEffect, forwardRef } from "react";
import { ButtonIcon } from "../buttons";
import { AccordionToggleProps as Props } from "./accordion.constants";
import { AccordionContext, SelectableContext } from "./accordion.context";
import {
  AccordionPanelStyled,
  AccordionContentStyled,
  AccordionButtonIconStyled,
  AccordionButtonStyled,
  AccordionLabelStyled,
  AccordionHeadingStyled,
} from "./accordion.styles";

const AccordionToggle = forwardRef(
  (
    {
      title,
      children,
      ariaLabel,
      defaultExpanded = false,
      labelComponent,
      labelClassName,
      className,
      openOverride,
      onClick,
      margin,
      padding,
      $paddingBottom,
      hasBorderBottom,
    }: Props,
    ref
  ) => {
    const { id, onHeaderKeyDown } = useContext(AccordionContext);
    const { index } = useContext(SelectableContext);
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    useEffect(() => {
      // if openOverride is not set it shouldn't override default
      if (openOverride !== undefined) {
        setIsExpanded(openOverride);
      }
    }, [openOverride]);

    return (
      <AccordionPanelStyled className={isExpanded ? "expanded" : "collapsed"} hasBorderBottom={hasBorderBottom}>
        <AccordionHeadingStyled $padding={padding}>
          <AccordionButtonStyled
            ref={ref as React.RefObject<HTMLDivElement>}
            data-accordion-id={id}
            className={`accordion-trigger ${className}`}
            id={`${id}-header-${index}`}
            aria-controls={`${id}-content-${index}`}
            aria-label={ariaLabel || undefined}
            onClick={(e) => {
              setIsExpanded(!isExpanded);
              if (onClick) onClick(e, !isExpanded);
            }}
            onKeyDown={(e) => onHeaderKeyDown && onHeaderKeyDown(e)}
            tabIndex={0}
          >
            {labelComponent ? (
              labelComponent
            ) : (
              <AccordionLabelStyled className={labelClassName}>{title}</AccordionLabelStyled>
            )}
            <AccordionButtonIconStyled isExpanded={isExpanded}>
              <ButtonIcon
                noBorder
                tabIndex={-1}
                aria-expanded={isExpanded}
                iconId="chevron-down"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsExpanded(!isExpanded);
                  if (onClick) onClick(e, !isExpanded);
                }}
              />
            </AccordionButtonIconStyled>
          </AccordionButtonStyled>
        </AccordionHeadingStyled>
        <AccordionContentStyled
          id={`${id}-content-${index}`}
          role="region"
          aria-labelledby={`${id}-header-${index}`}
          isExpanded={isExpanded}
          tabIndex={-1}
          $margin={margin}
          $paddingBottom={$paddingBottom}
        >
          {children}
        </AccordionContentStyled>
      </AccordionPanelStyled>
    );
  }
);

AccordionToggle.displayName = "AccordionToggle";

export { AccordionToggle };

export default AccordionToggle;
