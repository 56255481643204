import { useState, useEffect } from "react";

export const useImageLoad = (imageUrl?: string | null) => {
  const [imageHasLoaded, setImageHasLoaded] = useState<boolean>(false);
  const [isLandscape, setIsLandscape] = useState<boolean>(false);

  useEffect(() => {
    if (imageUrl) {
      const handleLoad = () => {
        setImageHasLoaded(true);
        setIsLandscape(img.width > img.height);
      };
      setImageHasLoaded(false);
      const img = new Image();
      img.addEventListener("load", handleLoad, false);
      img.src = imageUrl;
      return () => {
        img.removeEventListener("load", handleLoad);
      };
    }
  }, [imageUrl]);

  return {
    imageHasLoaded,
    isLandscape,
  };
};
