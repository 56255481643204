import * as Styled from "./logo.styles";
import logo from "./logo.png";

const Logo = () => (
  <Styled.LoginLogo>
    <img src={logo} alt="Milk Chocolate" />
    <span>Admin</span>
  </Styled.LoginLogo>
);

export default Logo;
