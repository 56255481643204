import { useContext, useEffect } from "react";
import convertCoordinatesToGooglePaths from "./convertCoordinatesToGooglePaths";
import MapContext from "./map.context";

export type Coordinates = [number, number][][][];

type Props = {
  polygonOptions?: google.maps.PolygonOptions;
  isVisible?: boolean;
  coordinates: Coordinates;
};

const Polygon = ({ polygonOptions, isVisible = true, coordinates }: Props) => {
  const map = useContext(MapContext);

  useEffect(() => {
    new google.maps.Polygon({
      map: isVisible ? map : null,
      strokeColor: `#601EFD`,
      strokeWeight: 2,
      fillColor: `#bca0ff`,
      fillOpacity: 0.3,
      paths: convertCoordinatesToGooglePaths(coordinates),
      ...polygonOptions,
    });
  }, [map]);

  return null;
};

export default Polygon;
