import { ReactNode } from "react";
import { generatePath, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Row, Col, Container, Flex } from "@mc/components/grid/grid.styles";
import { Margin } from "@mc/components/core/spacing.styles";
import * as Styled from "./layout.styles";
import { GET_CLIENT_NAME } from "../pages/client/navigation/get-client-name.gql";
import { Breadcrumb } from "@mc/components/breadcrumbs/breadcrumb.constants";
import Breadcrumbs from "@mc/components/breadcrumbs/breadcrumbs";
import { CLIENT_PROFILE } from "../routes.constants";
import { GetClientNameQuery, GetClientNameQueryVariables } from "@mc/common/gql/types";
import { H1 } from "@mc/components/core/typography.styles";

type Props = {
  children: ReactNode;
  secondaryNav?: ReactNode;
  hasRightSidebar?: boolean;
  fullWidth?: boolean;
  $marginRight?: string | number;
};

const Layout = ({
  children,
  secondaryNav = false,
  hasRightSidebar = false,
  fullWidth = false,
  $marginRight,
}: Props) => (
  <Styled.LayoutContent
    as="main"
    hasLeftSidebar={!!secondaryNav}
    hasRightSidebar={hasRightSidebar}
    $marginRight={$marginRight}
  >
    {secondaryNav && <Styled.LayoutSecondaryNavigation>{secondaryNav}</Styled.LayoutSecondaryNavigation>}
    <Container>
      <Row>
        <Col xsOffset={fullWidth ? undefined : 1} xs={fullWidth ? 12 : 10}>
          {children}
        </Col>
      </Row>
    </Container>
  </Styled.LayoutContent>
);

export default Layout;

export const NewLayout = ({ children, secondaryNav = false, hasRightSidebar = false, $marginRight }: Props) => (
  <Styled.LayoutContent
    as="main"
    hasLeftSidebar={!!secondaryNav}
    hasRightSidebar={hasRightSidebar}
    $marginRight={$marginRight}
  >
    {secondaryNav && <Styled.LayoutSecondaryNavigation>{secondaryNav}</Styled.LayoutSecondaryNavigation>}
    <Container>{children}</Container>
  </Styled.LayoutContent>
);

type TitleProps = {
  title: string;
  extras?: ReactNode;
};

export const LayoutTitle = ({ title, extras }: TitleProps) => {
  return (
    <Margin bottom={64}>
      <Flex gap={24} alignItems="center">
        <H1 marginBottom={0}>{title}</H1>
        {extras}
      </Flex>
    </Margin>
  );
};

export const LayoutBreadcrumb = ({ crumbs }: { crumbs: Breadcrumb[] }) => {
  const { clientId } = useParams<{ clientId: string }>();

  const { data } = useQuery<GetClientNameQuery, GetClientNameQueryVariables>(GET_CLIENT_NAME, {
    skip: !clientId,
    variables: {
      id: clientId!,
    },
  });

  return (
    <Breadcrumbs
      breadcrumbs={[
        {
          label: data?.client.fullName || "",
          href: CLIENT_PROFILE,
        },
        ...crumbs,
      ].map((r) => {
        return {
          ...r,
          href: generatePath(r.href, {
            clientId: clientId,
          }),
        };
      })}
    />
  );
};
