import { gql } from "@apollo/client";

export const CLIENT_ACTIVE_PROPERTY = gql`
  query ActivePortfolio($clientId: String!) {
    activePortfolio(clientId: $clientId) {
      _id
      properties {
        _id
        details {
          id
          coreAttributes {
            id
            beds
            baths
            carSpaces
            landArea
          }
          location {
            id
            singleLine
          }
          images {
            id
            defaultImage {
              basePhotoUrl
            }
          }
        }
        purchaseId
      }
    }
  }
`;

export const GET_PURCHASES = gql`
  query GetPurchases($clientId: String!) {
    client(id: $clientId) {
      _id
      purchases {
        _id
        shortlistProperties(status: TOP_RESULT) {
          _id
          details {
            id
            location {
              id
              singleLine
            }
            images {
              id
              defaultImage {
                basePhotoUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PROPERTY = gql`
  mutation CreateActivePortfolioProperty($createActivePortfolioPropertyInput: CreateActivePortfolioPropertyInput!) {
    createActivePortfolioProperty(createActivePortfolioPropertyInput: $createActivePortfolioPropertyInput) {
      _id
    }
  }
`;
