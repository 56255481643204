import { black, darkGrey, lightGrey, scaleGreen } from "@mc/components/core/colours.styles";
import { rem } from "@mc/components/core/styles";
import { fontSize14, fontSize16 } from "@mc/components/core/typography.styles";
import { TableProps } from "@mc/components/tables/table.styles";
import styled from "styled-components/macro";

export const DataCardTable = styled.table<TableProps>`
  border-collapse: collapse;
  color: ${black};
  font-size: ${fontSize16};
  width: 100%;

  td,
  th {
    text-align: center;
    vertical-align: center;
    padding: ${rem(8)} ${rem(4)};
  }

  td {
    border: 1px solid ${lightGrey};
  }

  thead th {
    color: ${darkGrey};
    font-weight: normal;
    font-size: ${fontSize14};
  }
`;

export const DataPercentage = styled.span`
  color: ${scaleGreen};
  display: flex;
  justify-items: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  h2 {
    font-weight: 500;
    margin: 0 0 0 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #000;
  }
`;
