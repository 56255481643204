import { Badge, BadgeProps } from "@mc/components/badge/badge.styles";
import { fontSize13, PStyled } from "@mc/components/core/typography.styles";
import { SpaceBetween } from "@mc/components/core/spacing.styles";
import { darkGrey } from "@mc/components/core/colours.styles";
import { Card } from "@mc/components/card/card.styles";

type MaintenanceCardProp = {
  service: string;
  date: string;
  amount: string;
  serviceProvider?: string;
  status: BadgeProps & { label: string };
};

const MaintenanceCard = ({ service, date, amount, serviceProvider, status }: MaintenanceCardProp) => {
  return (
    <Card>
      <SpaceBetween>
        <PStyled fontSize={fontSize13} colour={darkGrey} marginBottom={0}>
          {date}
        </PStyled>
        <PStyled isItalic marginBottom={0}>
          {amount}
        </PStyled>
      </SpaceBetween>
      <PStyled marginBottom={0}>{service}</PStyled>
      <SpaceBetween>
        <PStyled fontSize={fontSize13} colour={darkGrey} marginBottom={0}>
          {serviceProvider}
        </PStyled>
        <Badge colour={status.colour} isSecondary={status.isSecondary}>
          {status.label}
        </Badge>
      </SpaceBetween>
    </Card>
  );
};

export default MaintenanceCard;
