import { white } from "@mc/components/core/colours.styles";
import { useState } from "react";
import { MdClose, MdEditNote } from "react-icons/md";
import { WidgetButton, WidgetContainer, WidgetContainerProps, WidgetControlButton } from "./client-widget.styles";
import { Dialog } from "@headlessui/react";
import { RightAlign } from "@mc/components/core/spacing.styles";
import { Stack } from "@mc/components/stack/stack.styles";
import { TabsSecondary } from "@mc/components/tabs/tabs-secondary";
import { RiPictureInPicture2Fill, RiPictureInPictureExitFill } from "react-icons/ri";
import Notes from "./notes/notes";
import { useMatch } from "react-router-dom";
import KeyDates from "./key-dates/key-dates";

type WidgetTab = "notes" | "key-dates";

const ClientWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [size, setSize] = useState<WidgetContainerProps["size"]>("small");
  const [tab, setTab] = useState<WidgetTab>("notes");
  const match = useMatch("/client/:clientId/*");

  if (match === null) return null;

  return (
    <>
      <WidgetButton onClick={() => setIsOpen(true)}>
        <MdEditNote size={48} color={white} />
      </WidgetButton>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <Dialog.Panel as={WidgetContainer} size={size}>
          <RightAlign>
            <Stack direction="horizontal" spacing={8}>
              {size === "large" && (
                <WidgetControlButton onClick={() => setSize("small")}>
                  <RiPictureInPicture2Fill size={20} />
                </WidgetControlButton>
              )}
              {size === "small" && (
                <WidgetControlButton onClick={() => setSize("large")}>
                  <RiPictureInPictureExitFill size={20} />
                </WidgetControlButton>
              )}
              <WidgetControlButton onClick={() => setIsOpen(false)}>
                <MdClose size={20} />
              </WidgetControlButton>
            </Stack>
          </RightAlign>

          <TabsSecondary
            activeTab={tab}
            options={[
              {
                label: "Notes",
                value: "notes",
              },
              {
                label: "Key dates",
                value: "key-dates",
              },
            ]}
            contentPanelId="client-widget-tabs"
            ariaDescription="Client widget tabs"
            onSelect={(option) => setTab(option.value as WidgetTab)}
            marginBottom={12}
          />

          {tab === "notes" && <Notes />}
          {tab === "key-dates" && <KeyDates />}
        </Dialog.Panel>
      </Dialog>
    </>
  );
};

export default ClientWidget;
