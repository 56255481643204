import { black } from "@mc/components/core/colours.styles";
import { rem } from "@mc/components/core/styles";
import { fontSize14 } from "@mc/components/core/typography.styles";
import styled from "styled-components/macro";

export const OneLineWithGap = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: ${rem(16)};
  }
`;

export const SettingTable = styled.table`
  border-spacing: 0;

  tr > td:first-child {
    min-width: 295px;
  }

  td,
  th {
    padding-right: ${rem(16)};
    padding-bottom: ${rem(16)};
  }
`;

export const SettingDate = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-size: ${fontSize14};

  > span {
    color: ${black};
  }
`;
