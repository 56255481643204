import { gql } from "@apollo/client";

export const LOGIN_STAFF = gql`
  mutation LoginStaff($input: LoginStaffInput!) {
    loginStaff(loginStaffInput: $input) {
      staff {
        _id
        email
        firstName
        lastName
      }
      token
    }
  }
`;
