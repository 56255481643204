import { ComponentType, lazy } from "react";
import Calculators from "./pages/settings/calculators";
import ClientNavigation from "./pages/client/navigation/client-navigation";
import SearchTrackerSidebar from "./pages/client/properties/purchase/search-tracker-sidebar";
import Navigation from "./layout/navigation";
import GamePlanSettings from "./pages/settings/game-plan-settings";
import Login from "./pages/login/login";
import Documents from "./pages/client/documents/documents";
import ClientSettings from "./pages/client/settings/client-settings";
import {
  CLIENTS,
  CLIENT_PROFILE,
  COUPON,
  GAME_PLAN,
  GAME_PLAN_BREAKDOWN,
  GAME_PLAN_COMPARISON,
  LOGIN,
  PREFERENCES_ROUTE,
  PROFESSIONAL_SERVICE,
  PROPERTIES,
  PROPERTIES_PURCHASE_PROPERTY_ROUTE,
  PROPERTIES_PURCHASE_ROUTE,
  SUBURB_ROUTE,
  ACTIVE_PORTFOLIO,
  ACTIVE_PORTFOLIO_PROPERTY_VIEW,
  NOTIFICATIONS,
  CLIENTS_OLD,
} from "./routes.constants";
import CoreLogicDisclaimer from "@mc/common/blocks/disclaimer/core-logic-disclaimer/core-logic-disclaimer";
import PurchaseTrackerSidebar from "./pages/client/properties/purchase/property/purchase-tracker-sidebar";
import ActivePortfolio from "./pages/client/active-portfolio/active-portfolio";
import Property from "./pages/client/active-portfolio/property/property";
import Notifications from "./pages/client/notifications/notifications";

interface IRoute {
  path: string;
  exact?: boolean;
  main: ComponentType;
  sidebar?: ComponentType;
}

const routes: Array<IRoute> = [
  {
    path: LOGIN,
    main: Login,
  },
  {
    path: CLIENTS,
    main: lazy(() => import("./pages/client/list/client-list")),
    sidebar: Navigation,
  },
  {
    path: CLIENTS_OLD,
    main: lazy(() => import("./pages/client/list/client-list-old")),
    sidebar: Navigation,
  },
  {
    path: CLIENT_PROFILE,
    main: lazy(() => import("./pages/client/account/account")),
    sidebar: ClientNavigation,
  },
  {
    path: PREFERENCES_ROUTE,
    main: lazy(() => import("./pages/client/preferences/preferences")),
    sidebar: ClientNavigation,
  },
  {
    path: GAME_PLAN_COMPARISON,
    main: lazy(() => import("./pages/client/game-plan/comparison/comparison")),
  },
  {
    path: GAME_PLAN_BREAKDOWN,
    main: lazy(() => import("./pages/client/game-plan/breakdown/breakdown")),
  },
  {
    path: GAME_PLAN,
    main: lazy(() => import("./pages/client/game-plan/game-plan/game-plan")),
    sidebar: ClientNavigation,
  },
  {
    path: PROPERTIES_PURCHASE_PROPERTY_ROUTE,
    main: lazy(() => import("./pages/client/properties/purchase/property/property-routes")),
    sidebar: PurchaseTrackerSidebar,
  },
  {
    path: PROPERTIES_PURCHASE_ROUTE,
    main: lazy(() => import("./pages/client/properties/purchase/purchase-routes")),
    sidebar: SearchTrackerSidebar,
  },
  {
    path: PROPERTIES,
    main: lazy(() => import("./pages/client/properties/list/properties-list")),
    sidebar: ClientNavigation,
  },
  {
    path: ACTIVE_PORTFOLIO,
    main: ActivePortfolio,
    sidebar: ClientNavigation,
  },
  {
    path: ACTIVE_PORTFOLIO_PROPERTY_VIEW,
    main: Property,
    sidebar: ClientNavigation,
  },
  {
    path: NOTIFICATIONS,
    main: Notifications,
    sidebar: ClientNavigation,
  },
  {
    path: "/client/:clientId/documents",
    main: Documents,
    sidebar: ClientNavigation,
  },
  {
    path: "/client/:clientId/settings",
    main: ClientSettings,
    sidebar: ClientNavigation,
  },
  {
    path: "/game-plan-settings",
    main: GamePlanSettings,
    sidebar: Navigation,
  },
  {
    path: "/calculators",
    main: Calculators,
    sidebar: Navigation,
  },
  {
    path: "/core-logic-disclaimer",
    main: CoreLogicDisclaimer,
  },
  {
    path: SUBURB_ROUTE,
    main: lazy(() => import("./pages/suburb/suburb-routes")),
    sidebar: Navigation,
  },
  {
    path: COUPON,
    main: lazy(() => import("./pages/coupon/coupon-home")),
    sidebar: Navigation,
  },
  {
    path: PROFESSIONAL_SERVICE,
    main: lazy(() => import("./pages/professional-service/professional-service")),
    sidebar: Navigation,
  },
];

export default routes;
