import styled from "styled-components/macro";
import { rem, standardFocusState } from "../../core/styles";
import { fontSize14, fontWeightMedium } from "../../core/typography.styles";
import { formControlStyles } from "../inputs.styles";

export const PasswordInputWrapperStyled = styled.div`
  position: relative;
`;

export const PasswordInputSecondaryStyled = styled.div`
  position: absolute;
  right: 0;
  top: -${rem(22)};
`;

export const PasswordInputShowButtonStyled = styled.button`
  font-size: ${fontSize14};
  font-weight: ${fontWeightMedium};
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }

  ${standardFocusState};
`;

export const PasswordInputStyled = styled.input`
  ${formControlStyles}
`;
