import { useQuery } from "@apollo/client";
import { ClientNotificationsQuery, ClientNotificationsQueryVariables } from "@mc/common/gql/types";
import { useParams } from "react-router-dom";
import { CLIENT_NOTIFICATION } from "./client-notifications.gql";

export default function useClientNotifications() {
  const { clientId } = useParams<{ clientId: string }>();
  const { data, loading } = useQuery<ClientNotificationsQuery, ClientNotificationsQueryVariables>(CLIENT_NOTIFICATION, {
    variables: {
      clientId: clientId!,
    },
  });
  return { loading, data };
}
