import { useQuery } from "@apollo/client";
import { GetGamePlanQuery, GetGamePlanQueryVariables } from "@mc/common/gql/types";
import { ChartContainer, ChartTabs } from "@mc/components/charts/chart";
import { Margin } from "@mc/components/core/spacing.styles";
import { useTabState } from "@mc/components/tabs/tabs.hooks";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { GET_GAME_PLAN } from "../../../game-plan/game-plan.gql";
import { useAreaChart } from "@mc/components/charts/chart.hooks";
import { ChartAccessor } from "../../../game-plan/comparison/comparison.constants";
import { CHART_TAB_OPTIONS } from "./performance.constants";
import { H4 } from "@mc/components/core/typography.styles";

const PerformanceChart = () => {
  const chartTabOptions = useMemo(() => CHART_TAB_OPTIONS, []);
  const { tab: chartTab, setTab: setChartTab } = useTabState(chartTabOptions[0]);

  const { clientId } = useParams<{ clientId: string }>();

  const { data: gamePlanData } = useQuery<GetGamePlanQuery, GetGamePlanQueryVariables>(GET_GAME_PLAN, {
    variables: { clientId: clientId! },
  });

  const { gamePlan } = gamePlanData ?? {};

  const { chartRef } = useAreaChart({
    targetElementId: "chart",
    xAxis: gamePlan?.options[0].daisy?.summary.year,
    series: gamePlan?.options?.map((option: any) => ({
      name: option.name,
      data: option.daisy?.summary[chartTab?.value! as ChartAccessor].map((num: any) => Math.round(num.amount)) ?? [],
    })),
  });

  useEffect(() => {
    chartRef?.current?.updateSeries(
      gamePlan?.options?.map((option) => ({
        name: option.name,
        data: option.daisy?.summary[chartTab?.value! as ChartAccessor].map((num) => Math.round(num.amount)) ?? [],
      }))
    );
  }, [chartTab, chartRef, gamePlan]);

  return (
    <div>
      <Margin bottom={24} top={24}>
        <H4>Performance</H4>
      </Margin>

      <div>
        <ChartTabs options={chartTabOptions} currentTab={chartTab} onSelect={(option) => setChartTab(option)} />
        <ChartContainer />
      </div>
    </div>
  );
};

export default PerformanceChart;
