import { useQuery } from "@apollo/client";
import { GetShortlistPropertyQuery, GetShortlistPropertyQueryVariables } from "@mc/common/gql/types";
import { useParams } from "react-router-dom";
import { GET_SHORTLIST_PROPERTY } from "./property.gql";

type UsePropertyArgs = {
  onCompleted?: (data: GetShortlistPropertyQuery) => void;
};

export default function useShortlistProperty(args?: UsePropertyArgs) {
  const { onCompleted } = args ?? {};

  const { purchaseId, shortlistPropertyId } = useParams<{ purchaseId: string; shortlistPropertyId: string }>();

  const queryResult = useQuery<GetShortlistPropertyQuery, GetShortlistPropertyQueryVariables>(GET_SHORTLIST_PROPERTY, {
    variables: {
      purchaseId: purchaseId!,
      shortlistPropertyId: shortlistPropertyId!,
    },
    skip: !purchaseId || !shortlistPropertyId,
    onCompleted,
  });

  return queryResult;
}
