import styled from "styled-components/macro";
import { black, darkGrey } from "../core/colours.styles";
import { rem, baseFocusState } from "../core/styles";
import { fontSize16 } from "../core/typography.styles";

export const RadioInput = styled.input.attrs<JSX.IntrinsicElements["input"]>(() => ({ type: "radio" }))`
  margin-top: ${rem(6)};
  margin-right: ${rem(12)};
  display: inline-block;
  /* Fallback list of props depending on browser support */
  vertical-align: top;
  vertical-align: sub;
  vertical-align: text-bottom;
`;

export const RadioInputWrapper = styled.label`
  display: block;
  font-size: ${fontSize16};
  margin-bottom: ${rem(16)};
  padding-left: ${rem(30)};
  position: relative;

  ${RadioInput} {
    font-weight: normal;
    height: 0;
    opacity: 0;
    position: absolute;
    text-transform: none;
    width: 0;
  }

  ${RadioInput} + span:before {
    content: "";
    border-radius: 20px;
    border: 2px solid ${black};
    display: inline-block;
    left: 0;
    position: absolute;
    height: ${rem(16)};
    width: ${rem(16)};
    top: 0;
  }

  ${RadioInput}:checked + span:after {
    position: absolute;
    content: "";
    background-color: ${black};
    border-radius: 10px;
    height: ${rem(10)};
    width: ${rem(10)};
    left: ${rem(5)};
    top: ${rem(5)};
  }

  ${RadioInput}:focus + span:before {
    ${baseFocusState};
  }

  ${RadioInput}:disabled + span {
    color: ${darkGrey};
    &:hover {
      cursor: not-allowed;
    }
  }
  ${RadioInput}:disabled + span:before,
  ${RadioInput}:disabled + span:after {
    border-color: ${darkGrey};
  }
  ${RadioInput}:disabled + span:after {
    background-color: ${darkGrey};
  }
`;
