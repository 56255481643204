import { useQuery } from "@apollo/client";
import { GetPurchasesQuery, GetPurchasesQueryVariables } from "@mc/common/gql/types";
import { useParams } from "react-router-dom";
import { GET_PURCHASES } from "./active-portfolio.gql";

export default function useGetPurchases() {
  const { clientId } = useParams<{ clientId: string }>();

  const { data, loading } = useQuery<GetPurchasesQuery, GetPurchasesQueryVariables>(GET_PURCHASES, {
    variables: { clientId: clientId! },
    skip: !clientId,
  });

  return { loading, data };
}
