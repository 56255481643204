import { gql } from "@apollo/client";

const CLIENT_NOTES_FRAGMENT = gql`
  fragment ClientNotesFragment on Client {
    _id
    notes
    notesUpdatedAt {
      formatted(format: "DD/MM/YYYY HH:mm")
    }
  }
`;

export const GET_CLIENT_NOTES = gql`
  query GetClientNotes($clientId: String!) {
    client(id: $clientId) {
      ...ClientNotesFragment
    }
  }
  ${CLIENT_NOTES_FRAGMENT}
`;

export const UPDATE_CLIENT_NOTES = gql`
  mutation UpdateClientNotes($clientId: String!, $updateClientNotesInput: UpdateClientNotesInput!) {
    updateClientNotes(clientId: $clientId, updateClientNotesInput: $updateClientNotesInput) {
      ...ClientNotesFragment
    }
  }
  ${CLIENT_NOTES_FRAGMENT}
`;
