import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOGIN_STAFF } from "./login-staff.gql";
import { Container, Row, Col } from "@mc/components/grid/grid.styles";
import { EmailInput, InputLabel } from "@mc/components/inputs/inputs.styles";
import { ErrorMessage } from "@mc/components/inputs/errors.styles";
import { PrimaryLoadingButton } from "@mc/components/buttons";
import { Margin } from "@mc/components/core/spacing.styles";
import { H1 } from "@mc/components/core/typography.styles";
import PasswordInput from "@mc/components/inputs/password/password-input";
import Logo from "../../shared/logo/logo";
import * as Styled from "./login.styles";
import { setUserData } from "../../shared/auth-token";
import houseHug from "./house-hug.png";
import { useState } from "react";
import { Stack } from "@mc/components/stack/stack.styles";
import { LoginStaffMutation, LoginStaffMutationVariables } from "@mc/common/gql/types";

const EMAIL_FIELD = "email";
const PASSWORD_FIELD = "password";

interface LoginFormInput {
  [EMAIL_FIELD]: string;
  [PASSWORD_FIELD]: string;
}

const LoginPage = () => {
  const state = useLocation().state as { from?: string; redirectReason?: string };
  const navigate = useNavigate();
  const [serverError, setServerError] = useState("");

  const formMethods = useForm<LoginFormInput>();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods;

  const [login, { loading }] = useMutation<LoginStaffMutation, LoginStaffMutationVariables>(LOGIN_STAFF, {
    onCompleted: () => setServerError(""),
    onError: (err) => setServerError(err?.message),
  });

  const onSubmit = async (data: LoginFormInput) => {
    setServerError("");
    const response = await login({ variables: { input: data } });

    if (response.data?.loginStaff.token) {
      setUserData(response.data?.loginStaff.staff, response.data?.loginStaff.token);
      navigate(state?.from || "/clients");
    }
  };

  return (
    <Styled.LoginContainer>
      <Container>
        <Row centerXs>
          <Col sm={6} md={4} mdOffset={2} lastXs firstSm>
            <Margin bottom={56}>
              <Logo />
            </Margin>
            <Margin bottom={64}>
              <H1>Sign in</H1>
            </Margin>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Stack spacing={20}>
                  <div>
                    <InputLabel htmlFor={EMAIL_FIELD}>Username</InputLabel>
                    <EmailInput
                      autoFocus
                      id={EMAIL_FIELD}
                      {...register(EMAIL_FIELD, {
                        required: "Please enter your username.",
                        onChange: () => setServerError(""),
                      })}
                    />
                    <ErrorMessage error={errors[EMAIL_FIELD]} />
                  </div>
                  <div>
                    <InputLabel htmlFor={PASSWORD_FIELD}>Password</InputLabel>
                    <PasswordInput name={PASSWORD_FIELD} onChange={() => setServerError("")} />
                    <ErrorMessage error={errors[PASSWORD_FIELD]} />
                  </div>

                  <PrimaryLoadingButton isDisabled={loading} isLoading={loading}>
                    Sign in
                  </PrimaryLoadingButton>

                  {serverError && <ErrorMessage error={serverError} />}
                </Stack>
              </form>
            </FormProvider>
          </Col>
          <Col sm={6} md={6} firstXs lastSm>
            <Styled.LoginImage>
              <img src={houseHug} alt="" />
            </Styled.LoginImage>
          </Col>
        </Row>
      </Container>
    </Styled.LoginContainer>
  );
};

export default LoginPage;
