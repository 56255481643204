import { Tabs } from "@mc/components/tabs/tabs";
import { TabOption } from "@mc/components/tabs/tabs.constants";
import { ChartContainerStyled, ChartTabsStyled } from "./chart.styles";
import { useAreaChart } from "./chart.hooks";
import { AnchorOriginalStyled, PStyledSecondary } from "../core/typography.styles";

type Props = {
  options: TabOption[];
  currentTab?: TabOption;
  onSelect: (option: TabOption) => void;
};

export const ChartTabs = ({ options, currentTab, onSelect }: Props) => {
  return (
    <ChartTabsStyled>
      <Tabs
        activeTab={currentTab?.value}
        options={options}
        contentPanelId="content"
        onSelect={onSelect}
        ariaDescription="Account management tabs"
      />
    </ChartTabsStyled>
  );
};

type ChartProps = {
  id?: string;
};
export const ChartContainer = ({ id = "chart" }: ChartProps) => (
  <ChartContainerStyled>
    <div id={id} />
    <PStyledSecondary isItalic>
      It is crucial to recognise that these projections are based on evolving assumptions, subject to change.{" "}
      <AnchorOriginalStyled href="/chart-disclaimer" target="_blank">
        Find out more.
      </AnchorOriginalStyled>
    </PStyledSecondary>
  </ChartContainerStyled>
);

export { useAreaChart };
