import styled from "styled-components/macro";
import { rem } from "@mc/components/core/styles";
import { AnchorStyled } from "@mc/components/core/typography.styles";
import { LAYOUT_SIDE_DRAWER } from "../../../../../layout/layout.constants";
import Icon from "@mc/components/icons/Icon";
import { ButtonPrimary } from "@mc/components/buttons";

export const Nav = styled.nav`
  background-color: rgba(0, 0, 0, 0.05);
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${rem(LAYOUT_SIDE_DRAWER)};
`;

export const NavIcon = styled(Icon)`
  margin-right: ${rem(16)};
`;

export const NavAnchor = styled(AnchorStyled)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NavHeader = styled.div`
  display: flex;
  height: ${rem(64)};
  align-items: center;
  padding-left: ${rem(16)};
`;

export const PublishButton = styled(ButtonPrimary)`
  width: 100%;
  margin-top: ${rem(8)};
  margin-bottom: ${rem(4)};
`;
