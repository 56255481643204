import { BadgeProps } from "@mc/components/badge/badge.styles";

export function getPropertyScoreLabel({
  propertyScoreValue,
  propertyScoreOverallValue,
}: {
  propertyScoreValue?: number;
  propertyScoreOverallValue?: number;
}): string {
  if (propertyScoreValue) {
    if (propertyScoreValue >= 15) {
      return "Excellent";
    } else if (propertyScoreValue >= 10) {
      return "Good";
    } else if (propertyScoreValue >= 5) {
      return "Average";
    } else {
      return "Poor";
    }
  }

  if (propertyScoreOverallValue) {
    if (propertyScoreOverallValue >= 75) {
      return "Excellent";
    } else if (propertyScoreOverallValue >= 50) {
      return "Good";
    } else if (propertyScoreOverallValue >= 25) {
      return "Average";
    } else {
      return "Poor";
    }
  }

  return "";
}

export function getPropertyScoreBadge({
  propertyScoreValue,
  propertyScoreOverallValue,
}: {
  propertyScoreValue?: number;
  propertyScoreOverallValue?: number;
}): { badgeProps: BadgeProps; label: string } {
  if (propertyScoreValue) {
    if (propertyScoreValue >= 15) {
      return { badgeProps: { colour: "scaleGreen" }, label: getPropertyScoreLabel({ propertyScoreValue }) };
    } else if (propertyScoreValue >= 10) {
      return { badgeProps: { colour: "scaleLightGreen" }, label: getPropertyScoreLabel({ propertyScoreValue }) };
    } else if (propertyScoreValue >= 5) {
      return { badgeProps: { colour: "scaleYellow" }, label: getPropertyScoreLabel({ propertyScoreValue }) };
    } else {
      return { badgeProps: { colour: "scaleRed" }, label: getPropertyScoreLabel({ propertyScoreValue }) };
    }
  }

  if (propertyScoreOverallValue) {
    if (propertyScoreOverallValue >= 75) {
      return { badgeProps: { colour: "scaleGreen" }, label: getPropertyScoreLabel({ propertyScoreOverallValue }) };
    } else if (propertyScoreOverallValue >= 50) {
      return { badgeProps: { colour: "scaleLightGreen" }, label: getPropertyScoreLabel({ propertyScoreOverallValue }) };
    } else if (propertyScoreOverallValue >= 25) {
      return { badgeProps: { colour: "scaleYellow" }, label: getPropertyScoreLabel({ propertyScoreOverallValue }) };
    } else {
      return { badgeProps: { colour: "scaleRed" }, label: getPropertyScoreLabel({ propertyScoreOverallValue }) };
    }
  }

  return { badgeProps: {}, label: "" };
}
