import Breadcrumbs from "@mc/components/breadcrumbs/breadcrumbs";
import { useMemo } from "react";
import Layout, { LayoutTitle } from "../../layout/layout";
import * as NavStyled from "../../layout/navigation.styles";
import MultipleRangesSetting from "./multiple-ranges-setting";
import MultipleValuesSetting from "./multiple-values-setting";
import SingleValueSetting from "./single-value-setting";

const Calculators = () => {
  const PAGE_TITLE = "Calculators";

  const items = useMemo(() => {
    return [
      {
        name: "Stamp duty",
        key: "STAMP_DUTY",
        component: MultipleRangesSetting,
      },
      {
        name: "Foreign buyers duty",
        key: "FOREIGN_BUYERS_STAMP_DUTY_FIXED_RATE",
        component: SingleValueSetting,
      },
      {
        name: "Assumed capital growth",
        key: "ASSUMED_CAPITAL_GROWTH",
        component: MultipleValuesSetting,
      },
      {
        name: "Average annual rental increase",
        key: "AVERAGE_ANNUAL_RENTAL_INCREASE",
        component: MultipleValuesSetting,
      },
      {
        name: "Banks discount prediction",
        key: "BANKS_DISCOUNT_PREDICTION",
        component: SingleValueSetting,
      },
      {
        name: "Land value",
        key: "LAND_VALUE",
        component: MultipleRangesSetting,
      },
      {
        name: "Land tax",
        key: "LAND_TAX",
        component: MultipleRangesSetting,
      },
      {
        name: "Sqm rate",
        key: "SQM_RATE",
        component: MultipleValuesSetting,
      },
      {
        name: "Residential council rates",
        key: "RESIDENTIAL_COUNCIL_RATES",
        component: MultipleRangesSetting,
      },
      {
        name: "Commercial council rates",
        key: "COMMERCIAL_COUNCIL_RATES",
        component: MultipleRangesSetting,
      },
      {
        name: "Water rates",
        key: "WATER_RATES",
        component: MultipleValuesSetting,
      },
      {
        name: "Default residential rental yield",
        key: "DEFAULT_RESIDENTIAL_RENTAL_YIELD",
        component: MultipleRangesSetting,
      },
      {
        name: "Default commercial rental yield",
        key: "DEFAULT_COMMERCIAL_RENTAL_YIELD",
        component: MultipleRangesSetting,
      },
      {
        name: "Default rental yield",
        key: "DEFAULT_RENTAL_YIELD",
        component: SingleValueSetting,
      },
      {
        name: "RBA cash rate",
        key: "RBA_CASH_RATE",
        component: SingleValueSetting,
      },
      {
        name: "Strategy age threshold",
        key: "STRATEGY_AGE_THRESHOLD",
        component: SingleValueSetting,
      },
    ];
  }, []);

  return (
    <Layout
      secondaryNav={
        <NavStyled.Navigation>
          <NavStyled.NavigationItems>
            {items.map((item) => (
              <NavStyled.NavigationItem key={item.key}>
                <NavStyled.NavigationItemButton
                  onClick={() => document.getElementById(item.key)?.scrollIntoView({ behavior: "smooth" })}
                >
                  {item.name}
                </NavStyled.NavigationItemButton>
              </NavStyled.NavigationItem>
            ))}
          </NavStyled.NavigationItems>
        </NavStyled.Navigation>
      }
    >
      <>
        <Breadcrumbs breadcrumbs={[{ label: PAGE_TITLE, href: "" }]} />

        <LayoutTitle title={PAGE_TITLE} />

        {items.map((item) => {
          const Comp = item.component;
          return <Comp key={item.key} keyName={item.key} />;
        })}
      </>
    </Layout>
  );
};

export default Calculators;
