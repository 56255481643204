import { useQuery } from "@apollo/client";
import { ActivePortfolioPropertyQuery, ActivePortfolioPropertyQueryVariables } from "@mc/common/gql/types";
import { useParams } from "react-router-dom";
import { PROPERTY_DETAILS } from "./property.gql";

export default function usePropertyDetails() {
  const { propertyId } = useParams<{ propertyId: string }>();

  const { data, loading } = useQuery<ActivePortfolioPropertyQuery, ActivePortfolioPropertyQueryVariables>(
    PROPERTY_DETAILS,
    {
      variables: { propertyId: propertyId! },
      skip: !propertyId,
    }
  );

  return { loading, data };
}
