import { gql } from "@apollo/client";

const SINGLE_VALUE_SETTING_FRAGMENT = gql`
  fragment SingleValueSettingFragment on SingleValueSetting {
    _id
    key
    name
    filters
    updatedAt {
      formatted
    }
    value
    unit
    formattedValue
  }
`;

const MULTIPLE_VALUES_SETTING_FRAGMENT = gql`
  fragment MultipleValuesSettingFragment on MultipleValuesSetting {
    _id
    key
    name
    filters
    updatedAt {
      formatted
    }
    values {
      key
      name
      value
      unit
      formattedValue
    }
  }
`;

const MULTIPLE_RANGES_SETTING_FRAGMENT = gql`
  fragment MultipleRangesSettingFragment on MultipleRangesSetting {
    _id
    key
    name
    filters
    updatedAt {
      formatted
    }
    ranges {
      from
      to
      value
      unit
      formattedFrom
      formattedTo
      formattedValue
    }
  }
`;

export const GET_SETTING_GROUP = gql`
  query GetSettingGroup($key: String!) {
    settingGroup(key: $key) {
      _id
      key
      name
      settings {
        ... on SingleValueSetting {
          ...SingleValueSettingFragment
        }

        ... on MultipleValuesSetting {
          ...MultipleValuesSettingFragment
        }

        ... on MultipleRangesSetting {
          ...MultipleRangesSettingFragment
        }
      }
    }
  }
  ${SINGLE_VALUE_SETTING_FRAGMENT}
  ${MULTIPLE_VALUES_SETTING_FRAGMENT}
  ${MULTIPLE_RANGES_SETTING_FRAGMENT}
`;

export const UPDATE_SINGLE_VALUE_SETTINGS = gql`
  mutation UpdateSingleValueSettings($input: [UpdateSingleValueSettingInput!]!) {
    updateSingleValueSettings(updateSingleValueSettingsInput: $input) {
      ...SingleValueSettingFragment
    }
  }
  ${SINGLE_VALUE_SETTING_FRAGMENT}
`;

export const UPDATE_MULTIPLE_VALUES_SETTINGS = gql`
  mutation UpdateMultipleValuesSettings($input: [UpdateMultipleValuesSettingInput!]!) {
    updateMultipleValuesSettings(updateMultipleValuesSettingsInput: $input) {
      ...MultipleValuesSettingFragment
    }
  }
  ${MULTIPLE_VALUES_SETTING_FRAGMENT}
`;

export const UPDATE_MULTIPLE_RANGES_SETTINGS = gql`
  mutation UpdateMultipleRangesSettings($input: [UpdateMultipleRangesSettingInput!]!) {
    updateMultipleRangesSettings(updateMultipleRangesSettingsInput: $input) {
      ...MultipleRangesSettingFragment
    }
  }
  ${MULTIPLE_RANGES_SETTING_FRAGMENT}
`;
