import { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { fontFamilyBase } from "../core/typography.styles";
import { darkGrey, highlight, lightGrey, lightPink, purple, red } from "../core/colours.styles";
import { BREAKPOINT_SM } from "../core/styles";
import { formatCurrency, formatPercentage } from "@mc/common/utils";

type Props = {
  targetElementId: string;
  xAxis: NonNullable<ApexOptions["xaxis"]>["categories"];
  series: ApexOptions["series"];
  yAxisFormatter?: "currency" | "percentage" | "plain";
};

const formatFn = (yAxisFormatter: Props["yAxisFormatter"]) => (value: number) => {
  switch (yAxisFormatter) {
    case "currency":
      if (value < 5000) {
        return formatCurrency(value, { maximumFractionDigits: 2 });
      } else if (value < 1000000) {
        return formatCurrency(value / 1000, { maximumFractionDigits: 2 }).concat("k");
      } else {
        return formatCurrency(value / 1000000, { maximumFractionDigits: 2 }).concat("m");
      }

    case "percentage":
      return formatPercentage(value);

    case "plain":
    default:
      return value.toString();
  }
};

export function useAreaChart({ targetElementId, xAxis = [], series = [], yAxisFormatter = "currency" }: Props) {
  const chartRef = useRef<any>(null);

  useEffect(() => {
    const colours = series.length === 1 ? [highlight] : [lightPink, highlight, purple, red];

    const setChartData = () => {
      const options: ApexOptions = {
        chart: {
          type: "area",
          fontFamily: fontFamilyBase,
          toolbar: {
            show: false,
          },
          height: "400px",
        },
        grid: {
          borderColor: lightGrey,
        },
        fill: {
          type: ["solid"],
          opacity: 0.2,
          colors: colours,
        },
        tooltip: {
          marker: {
            fillColors: colours,
          },
        },
        series: series,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          colors: colours,
          width: 2,
          dashArray: 3,
        },
        legend: {
          markers: {
            width: 16,
            height: 16,
            fillColors: colours,
          },
          itemMargin: {
            vertical: 20,
            horizontal: 10,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
        },
        markers: {
          colors: colours,
        },
        xaxis: {
          type: "category",
          tickAmount: 5,
          categories: xAxis,
          labels: {
            rotate: 0,
            rotateAlways: false,
            style: {
              fontSize: "11px",
              fontFamily: fontFamilyBase,
              fontWeight: 400,
            },
          },
          axisTicks: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            offsetY: -10,
            style: {
              fontSize: "11px",
              fontFamily: fontFamilyBase,
              fontWeight: 400,
            },
            formatter: formatFn(yAxisFormatter),
          },
        },
        responsive: [
          {
            breakpoint: BREAKPOINT_SM,
            options: {
              chart: {
                height: "300px",
              },
              xaxis: {
                tickAmount: 5,
                type: "categories",
              },
            },
          },
        ],
      };

      const el = document.getElementById(targetElementId);
      if (el) {
        chartRef.current = new ApexCharts(el, options);
        chartRef.current.render();
      }
    };

    setChartData();
  }, [targetElementId, xAxis, series]);

  return { chartRef };
}

export function useLineChart({ targetElementId, xAxis = [], series = [], yAxisFormatter = "currency" }: Props) {
  const chartRef = useRef<any>(null);

  useEffect(() => {
    const colours = series.length === 1 ? [highlight] : [highlight, lightGrey, darkGrey];

    const setChartData = () => {
      const options: ApexOptions = {
        chart: {
          type: "line",
          fontFamily: fontFamilyBase,
          toolbar: {
            show: false,
          },
          height: "400px",
        },
        grid: {
          borderColor: lightGrey,
        },
        tooltip: {
          marker: {
            fillColors: colours,
          },
        },
        series: series,
        stroke: {
          colors: colours,
          width: 2,
        },
        legend: {
          markers: {
            width: 16,
            height: 16,
            fillColors: colours,
          },
          itemMargin: {
            vertical: 20,
            horizontal: 10,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
        },
        markers: {
          colors: colours,
        },
        xaxis: {
          type: "category",
          categories: xAxis,
          labels: {
            rotate: 0,
            rotateAlways: false,
            style: {
              fontSize: "11px",
              fontFamily: fontFamilyBase,
              fontWeight: 400,
            },
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            offsetY: -10,
            style: {
              fontSize: "11px",
              fontFamily: fontFamilyBase,
              fontWeight: 400,
            },
            formatter: formatFn(yAxisFormatter),
          },
        },
        responsive: [
          {
            breakpoint: BREAKPOINT_SM,
            options: {
              chart: {
                height: "300px",
              },
              xaxis: {
                tickAmount: 2,
                type: "categories",
              },
            },
          },
        ],
      };

      const el = document.getElementById(targetElementId);
      if (el) {
        chartRef.current = new ApexCharts(el, options);
        chartRef.current.render();
      }
    };

    if (!chartRef.current && xAxis.length > 0) {
      setChartData();
    }
  }, [targetElementId, xAxis, series]);

  return { chartRef };
}

export function useBarChart({ targetElementId, xAxis = [], series = [], yAxisFormatter = "currency" }: Props) {
  const chartRef = useRef<any>(null);

  useEffect(() => {
    const colours = [highlight, lightGrey, darkGrey];

    const setChartData = () => {
      const options: ApexOptions = {
        chart: {
          type: "bar",
          fontFamily: fontFamilyBase,
          toolbar: {
            show: false,
          },
          height: "400px",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
            columnWidth: "30%",
          },
        },
        colors: colours,
        grid: {
          borderColor: lightGrey,
        },
        series: series,
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          type: "category",
          categories: xAxis,
          labels: {
            rotate: 0,
            rotateAlways: false,
            style: {
              fontSize: "11px",
              fontFamily: fontFamilyBase,
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          labels: {
            offsetY: -10,
            style: {
              fontSize: "11px",
              fontFamily: fontFamilyBase,
              fontWeight: 400,
            },
            formatter: formatFn(yAxisFormatter),
          },
        },
        responsive: [
          {
            breakpoint: BREAKPOINT_SM,
            options: {
              chart: {
                height: "300px",
              },
            },
          },
        ],
      };

      const el = document.getElementById(targetElementId);
      if (el) {
        chartRef.current = new ApexCharts(el, options);
        chartRef.current.render();
      }
    };

    if (!chartRef.current && xAxis.length > 0) {
      setChartData();
    }
  }, [targetElementId, xAxis, series]);

  return { chartRef };
}
