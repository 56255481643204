import * as Styled from "./icon-success.styles";
import { MdCheck } from "react-icons/md";

const IconSuccess = () => (
  <Styled.IconSuccess>
    <MdCheck />
  </Styled.IconSuccess>
);

export default IconSuccess;
