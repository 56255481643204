import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { CarouselImageContainer } from "./image-carousel.styles";

type Props = {
  images: (string | null | undefined)[];
};

const ImageCarousel = ({ images }: Props) => {
  return (
    <Carousel showArrows showIndicators={false} showThumbs={false} infiniteLoop>
      {images.map((image) => (
        <CarouselImageContainer key={image}>
          <img src={image ?? ""} alt="" />
        </CarouselImageContainer>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
