import { gql } from "@apollo/client";

export const CLIENT_NOTIFICATION = gql`
  query ClientNotifications($clientId: String!) {
    client(id: $clientId) {
      _id
      notifications {
        _id
        type
        subject
        body
        sentAt {
          formatted
        }
      }
    }
  }
`;
