import styled from "styled-components";
import { darkGrey, white } from "../core/colours.styles";
import { rem } from "../core/styles";

export const Card = styled.div`
  background-color: ${white};
  border: 1px solid ${darkGrey};
  border-radius: ${rem(8)};
  padding: ${rem(24)};
`;
