import { generatePath, useParams } from "react-router-dom";
import { PROPERTIES_PURCHASE_PROPERTY } from "../../../../../routes.constants";

export default function usePropertyPath(shortlistPropertyId?: string) {
  const { clientId, purchaseId } = useParams<{
    clientId: string;
    purchaseId: string;
  }>();

  if (!clientId || !purchaseId || !shortlistPropertyId) return null;

  const purchaseUrl = generatePath(PROPERTIES_PURCHASE_PROPERTY, {
    clientId,
    purchaseId,
    shortlistPropertyId,
  });

  return purchaseUrl;
}
