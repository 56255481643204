import { createContext, KeyboardEventHandler } from "react";

export type AccordionContextTypes = {
  onHeaderKeyDown: KeyboardEventHandler<HTMLDivElement>;
  id: string;
};

export const AccordionContext = createContext<AccordionContextTypes>({
  id: "",
  onHeaderKeyDown: () => {
    return;
  },
});

type SelectableContextType = {
  index: number;
};

export const SelectableContext = createContext<SelectableContextType>({
  index: 0,
});
