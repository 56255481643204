import styled from "styled-components/macro";
import { rem } from "../core/styles";
import { red } from "../core/colours.styles";
import { fontSize14 } from "../core/typography.styles";

const ErrorMessageStyled = styled.div`
  color: ${red};
  font-size: ${fontSize14};
  margin-top: ${rem(4)};
`;

type Props = {
  error?: any;
};

export const ErrorMessage = ({ error }: Props) => {
  if (typeof error === "string") {
    return <ErrorMessageStyled>{error}</ErrorMessageStyled>;
  } else if (error) {
    return <ErrorMessageStyled>{error.message}</ErrorMessageStyled>;
  }
  return null;
};
