import { ActivePortfolioPropertyQuery } from "@mc/common/gql/types";
import { PropertyCard } from "@mc/common/blocks/property-cards";
import { Margin } from "@mc/components/core/spacing.styles";
import { ButtonSecondaryLink } from "@mc/components/buttons";
import { DataCardTable, DataPercentage } from "./overview.style";
import CoreLogic from "@mc/common/blocks/core-logic/core-logic";
import * as Map from "@mc/components/maps";
import PropertyScore from "./property-score";
import { DataList } from "@mc/components/tables/data-list";
import { H4 } from "@mc/components/core/typography.styles";

const Overview = (property: ActivePortfolioPropertyQuery) => {
  return (
    <div>
      <PropertyCard
        key={property?.activePortfolioProperty?._id}
        images={(property.activePortfolioProperty?.details?.images.allImages ?? []).map((i) => i.largePhotoUrl)}
        addressFormatted={property?.activePortfolioProperty?.details?.location.singleLine}
        bedrooms={property?.activePortfolioProperty?.details?.coreAttributes.beds}
        bathrooms={property?.activePortfolioProperty?.details?.coreAttributes.baths}
        carSpaces={property?.activePortfolioProperty?.details?.coreAttributes.carSpaces}
        landSize={property?.activePortfolioProperty?.details?.coreAttributes.landArea}
      />

      <Margin left={24} bottom={24} right={24}>
        <ButtonSecondaryLink
          to={property.activePortfolioProperty?.purchaseShortlistProperty?.photos?.link!}
          target="_blank"
          $width={"100%"}
        >
          View photos & Videos
        </ButtonSecondaryLink>
      </Margin>

      <Margin left={24} bottom={24} right={24} top={48}>
        <DataCardTable>
          <tbody>
            <tr>
              <td>
                <label>Current value</label>
                <DataPercentage>
                  <h2>$1234,567</h2>
                  <span>(+5%)</span>
                </DataPercentage>
              </td>
              <td>
                <label>Current rent</label>
                <DataPercentage>
                  <h2>$900</h2>
                  <span>(+5%)</span>
                </DataPercentage>
              </td>
              <td>
                <label>ROI</label>
                <DataPercentage>
                  <h2>115%</h2>
                  <span>(+5%)</span>
                </DataPercentage>
              </td>
              <td>
                <label>Capital growth</label>
                <DataPercentage>
                  <h2>20%</h2>
                </DataPercentage>
              </td>
              <td>
                <label>Current net yield</label>
                <DataPercentage>
                  <h2>25%</h2>
                </DataPercentage>
              </td>
              <td>
                <label>Current vacancy rate</label>
                <DataPercentage>
                  <h2>3%</h2>
                </DataPercentage>
              </td>
            </tr>
          </tbody>
        </DataCardTable>
      </Margin>

      <Margin left={24} bottom={24} right={24}>
        <label>Last updated: {property.activePortfolioProperty?.updatedAt.formatted}</label>
      </Margin>

      <Margin left={24} bottom={24} right={24} top={48}>
        <DataList
          data={[
            {
              label: "Property type",
              value: `${property.activePortfolioProperty?.details?.coreAttributes.propertyType}`,
            },
            {
              label: "Period",
              value: "N/A",
            },
            {
              label: "Architectural style",
              value: "N/A",
            },
            {
              label: "Year built",
              value: `${property.activePortfolioProperty?.details?.additionalAttributes?.yearBuilt || "N/A"}`,
            },
            {
              label: "Sale price",
              value: "N/A",
            },
            {
              label: "Sale date",
              value: "N/A",
            },
            {
              label: "Internal floor size",
              value: "N/A",
            },
            {
              label: "Zoning",
              value: `${property.activePortfolioProperty?.details?.lastSales.lastSale?.zoneDescriptionLocal || "N/A"}`,
            },
          ]}
        />
      </Margin>

      <Margin left={24} bottom={24} right={24} top={24}>
        <CoreLogic center />
      </Margin>

      <Margin left={24} bottom={24} right={24} top={48}>
        <PropertyScore />
      </Margin>

      <Margin bottom={24} top={40}>
        <H4>Suburb summary</H4>
      </Margin>

      <Margin left={24} bottom={24} right={24} top={48}>
        {property.activePortfolioProperty?.details?.location?.latitude &&
        property.activePortfolioProperty?.details?.location?.longitude ? (
          <Map.Canvas
            lat={property.activePortfolioProperty.details.location.latitude}
            lng={property.activePortfolioProperty.details.location.longitude}
            zoom={18}
          >
            <Map.Marker
              lat={property.activePortfolioProperty.details.location.latitude}
              lng={property.activePortfolioProperty.details.location.longitude}
              title={property.activePortfolioProperty.details.location.singleLine}
            />
          </Map.Canvas>
        ) : (
          ""
        )}
      </Margin>
    </div>
  );
};

export default Overview;
