import { gql } from "@apollo/client";
import { CORE_LOGIC_PROPERTY_DETAILS_FRAGMENT } from "@mc/common/blocks/onboarding/existing-properties/existing-properties.gql";
import { PURCHASE_FRAGMENT, DOMAIN_LISTING_FRAGMENT } from "../../properties.gql";

export const SHORTLIST_PROPERTY_STAGES_FRAGMENT = gql`
  fragment ShortlistPropertyStagesFragment on ShortlistProperty {
    stages {
      number
      type
      label
      items {
        type
        label
        slug
        status
      }
    }
  }
`;

export const SHORTLIST_PROPERTY_FRAGMENT = gql`
  fragment ShortlistPropertyFragment on ShortlistProperty {
    _id
    status
    isOffMarket
    askingPrice {
      max {
        amount
      }
      min {
        amount
      }
    }
    shortlistReasons
    shortlistNotes
    propertyScore {
      _id
      location
      optionsAvailable
      character
      property
    }
    inspectionDate {
      origin
      formatted(format: "YYYY-MM-DDTHH:mm")
    }
    saleMethod
    saleOfferType
    auctionDate {
      origin
      formatted(format: "YYYY-MM-DDTHH:mm")
      alt: formatted(format: "DD MMM YYYY")
    }
    details {
      ...CoreLogicPropertyDetailsFragment
    }
    domainListing {
      ...DomainListingFragment
    }
    floodOverlayCheck

    ...ShortlistPropertyStagesFragment
  }
  ${SHORTLIST_PROPERTY_STAGES_FRAGMENT}
  ${CORE_LOGIC_PROPERTY_DETAILS_FRAGMENT}
  ${DOMAIN_LISTING_FRAGMENT}
`;

export const GET_CORELOGIC_PROPERTY_DETAILS = gql`
  query GetCoreLogicPropertyDetails($purchaseId: String!, $coreLogicPropertyId: Int!) {
    coreLogicPropertyDetails(propertyId: $coreLogicPropertyId) {
      ...CoreLogicPropertyDetailsFragment
    }
    purchase(purchaseId: $purchaseId) {
      ...PurchaseFragment
    }
  }
  ${PURCHASE_FRAGMENT}
  ${CORE_LOGIC_PROPERTY_DETAILS_FRAGMENT}
`;

export const GET_SHORTLIST_PROPERTY = gql`
  query GetShortlistProperty($purchaseId: String!, $shortlistPropertyId: String!) {
    purchase(purchaseId: $purchaseId) {
      _id
      dataSource
      shortlistProperty(shortlistPropertyId: $shortlistPropertyId) {
        ...ShortlistPropertyFragment
      }
    }
  }
  ${SHORTLIST_PROPERTY_FRAGMENT}
`;

export const UPDATE_PURCHASE_TRACKER_PROPERTY_STAGE_OR_ITEM_STATUS = gql`
  mutation UpdatePurchaseTrackerPropertyStageOrItemStatus(
    $shortlistPropertyId: String!
    $itemStatus: PurchaseTrackerItemStatus!
    $stageType: PurchaseTrackerStageType
    $itemType: PurchaseTrackerItemType
  ) {
    updatePurchaseTrackerPropertyStageOrItemStatus(
      shortlistPropertyId: $shortlistPropertyId
      itemStatus: $itemStatus
      stageType: $stageType
      itemType: $itemType
    ) {
      ...ShortlistPropertyFragment
    }
  }
  ${SHORTLIST_PROPERTY_FRAGMENT}
`;
