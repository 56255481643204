import { gql } from "@apollo/client";

export const GET_CORELOGIC_PROPERTY_IMAGES = gql`
  query GetCoreLogicPropertyImages($propertyId: Int!) {
    coreLogicPropertyimages(propertyId: $propertyId) {
      id
      allImages {
        largePhotoUrl
        mediumPhotoUrl
      }
    }
  }
`;
