import { GetPurchasesQuery } from "@mc/common/gql/types";
import { Label, RadioButton, Image } from "./purchase.styles";
import { UseFormRegister } from "react-hook-form";
import { Margin } from "@mc/components/core/spacing.styles";
import { FormData } from "./active-portfolio";

interface Props {
  data: GetPurchasesQuery["client"]["purchases"] | undefined;
  register: UseFormRegister<FormData>;
}

const PurchaseRadio: React.FC<Props> = ({ data, register }) => {
  const hasProperties = data?.some((purchase) => purchase.shortlistProperties[0]);

  const withRadio = (
    <>
      {data &&
        data.map((purchase) => {
          return (
            purchase.shortlistProperties[0] && (
              <Margin bottom={10} key={purchase._id}>
                <RadioButton
                  id={`create-property-purchase-${purchase._id}`}
                  {...register(`property`, { required: true })}
                  value={purchase._id}
                />
                <Label htmlFor={`create-property-purchase-${purchase._id}`}>
                  {purchase.shortlistProperties[0].details?.images.defaultImage.basePhotoUrl && (
                    <Image src={purchase.shortlistProperties[0].details.images.defaultImage.basePhotoUrl} alt="" />
                  )}
                  {purchase.shortlistProperties[0].details?.location.singleLine}
                </Label>
              </Margin>
            )
          );
        })}
    </>
  );

  const withoutRadio = <>There are no available property for active portfolio.</>;

  return hasProperties ? withRadio : withoutRadio;
};

export default PurchaseRadio;
