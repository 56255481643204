import styled from "styled-components/macro";
import { rem, mediaUp } from "@mc/components/core/styles";
import { TabsStyled, TabsTabStyled } from "@mc/components/tabs/tabs.style";
import { AnchorOriginalStyled, fontSize12, PStyledSecondary } from "../core/typography.styles";

export const ChartContainerStyled = styled.div`
  margin-left: -${rem(24)};
  margin-right: -${rem(24)};
  margin-bottom: ${rem(64)};

  ${mediaUp.md`
    margin-left: 0;
    margin-right: 0;
  `}

  > ${PStyledSecondary} {
    margin-left: ${rem(16)};
    margin-right: ${rem(16)};
    font-size: ${fontSize12};
  }

  ${AnchorOriginalStyled} {
    font-size: ${fontSize12};
  }
`;

export const ChartTabsStyled = styled.div`
  margin-left: -${rem(24)};
  margin-right: -${rem(24)};
  margin-bottom: ${rem(12)};
  padding-bottom: ${rem(16)};
  overflow-x: auto;

  ${mediaUp.md`
    margin-left: 0;
    margin-right: 0;
  `}

  ${TabsStyled} {
    box-sizing: border-box;
    margin-bottom: 0;
    padding-left: ${rem(20)};

    ${mediaUp.md`
    padding-left: 0;
    padding-right: 0;
    `}
  }

  ${TabsTabStyled} {
    white-space: nowrap;
  }
`;
