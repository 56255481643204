import { ReactNode, useState, useCallback, useRef, MouseEventHandler } from "react";
import ReactModal from "react-modal";
import { ButtonLink, ButtonIcon } from "@mc/components/buttons";
import { H2, PStyled } from "../core/typography.styles";
import { Col, Row } from "../grid/grid.styles";
import {
  TooltipWrapperStyled,
  TooltipModalStyled,
  TooltipModalContentStyled,
  TooltipModalCloseStyled,
  TooltipModalCloseLinkStyled,
} from "./tooltip.style";

type Props = {
  text: ReactNode;
  heading?: string;
  multiline?: boolean;
  children: ReactNode;
};

const TooltipModal = ({ text, heading, children }: Props) => {
  const tooltipWrapper = useRef<HTMLSpanElement | null>();

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleOnClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
      setIsVisible(true);
    },
    [setIsVisible]
  );

  return (
    <>
      {isVisible && (
        <ReactModal
          isOpen={true}
          style={{
            content: {
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              border: "1px solid #ccc",
              opacity: "0.95",
              maxWidth: "100%",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: "0",
              outline: "none",
              padding: "0",
            },
          }}
          onRequestClose={() => setIsVisible(false)}
        >
          <TooltipModalStyled>
            <TooltipModalCloseStyled>
              <ButtonIcon noBorder iconId="close" onClick={() => setIsVisible(false)} />
            </TooltipModalCloseStyled>
            <TooltipModalContentStyled>
              <Row>
                <Col>
                  {heading && <H2>{heading}</H2>}
                  {text && <PStyled as="div">{text}</PStyled>}
                  <TooltipModalCloseLinkStyled>
                    <ButtonLink type="button" onClick={() => setIsVisible(false)}>
                      Close
                    </ButtonLink>
                  </TooltipModalCloseLinkStyled>
                </Col>
              </Row>
            </TooltipModalContentStyled>
          </TooltipModalStyled>
        </ReactModal>
      )}
      <TooltipWrapperStyled
        ref={(ref) => {
          tooltipWrapper.current = ref;
        }}
        onClick={handleOnClick}
      >
        {children}
      </TooltipWrapperStyled>
    </>
  );
};

export default TooltipModal;
