import { useQuery } from "@apollo/client";
import {
  GetSettingGroupQuery,
  GetSettingGroupQueryVariables,
  MultipleRangesSettingFragmentFragment,
} from "@mc/common/gql/types";
import { GET_SETTING_GROUP } from "./setting.gql";
import useSetting from "./use-setting";

export default function useMultipleRangesSetting({ keyName }: { keyName: string }) {
  const { data } = useQuery<GetSettingGroupQuery, GetSettingGroupQueryVariables>(GET_SETTING_GROUP, {
    variables: { key: keyName },
    onCompleted: () => {
      setSelectedFilterValues(filters.map((filter) => filter[0]));
    },
  });

  const {
    editMode,
    setEditMode,
    selectedFilterValues,
    setSelectedFilterValues,
    hasFilters,
    filters,
    onFilterUpdate,
  } = useSetting({ data });

  const foundSetting = data?.settingGroup?.settings.find(
    (setting) =>
      (selectedFilterValues.length === 0 ||
        selectedFilterValues.every((selectedValue) => setting.filters?.includes(selectedValue))) &&
      setting.__typename === "MultipleRangesSetting"
  );

  return {
    settingGroup: data?.settingGroup,
    setting: foundSetting as MultipleRangesSettingFragmentFragment,
    hasFilters,
    filters,
    selectedFilterValues,
    editMode,
    setEditMode,
    onFilterUpdate,
  };
}
