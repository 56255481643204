import { AvatarStyled, AvatarSizes } from "./avatar.styles";

type Props = {
  firstName?: string;
  lastName?: string;
  name?: string;
  size?: AvatarSizes;
  onSelect?: () => void;
};

const Avatar = ({ firstName, lastName, name, size, onSelect }: Props) => (
  <AvatarStyled onClick={onSelect} as={onSelect ? "button" : "div"} $size={size}>
    {name ? (
      <>
        {name.split(" ")[0]?.charAt(0)}
        {name.split(" ")[1]?.charAt(0)}
      </>
    ) : (
      <>
        {firstName?.charAt(0)}
        {lastName?.charAt(0)}
      </>
    )}
  </AvatarStyled>
);

export default Avatar;
