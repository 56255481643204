import styled from "styled-components";

export const CarouselImageContainer = styled.div`
  height: 100%;

  & > img {
    border-radius: 4px;
    height: 100%;
    object-fit: cover;
  }
`;
