import { gql } from "@apollo/client";
import { EXISTING_PROPERTIES_FRAGMENT } from "@mc/common/blocks/onboarding/existing-properties/existing-properties.gql";
import {
  PERSONAL_DETAILS_CLIENT_FRAGMENT,
  PERSONAL_DETAILS_PROFILE_FRAGMENT,
} from "@mc/common/blocks/onboarding/personal-details/personal-details.gql";
import { INVESTMENT_GOALS_FRAGMENT } from "@mc/common/blocks/onboarding/investment-goals/investment-goals.gql";
import {
  HOME_PURCHASE_DETAILS_CLIENT_FRAGMENT,
  HOME_PURCHASE_DETAILS_PROFILE_FRAGMENT,
} from "@mc/common/blocks/onboarding/home-purchase-details/home-purchase-details.gql";
import { PREFERRED_OPTION_FRAGMENT } from "@mc/common/blocks/game-plan-feedback/preferred-option/preferred-option.gql";
import { GAME_PLAN_FEEDBACK_CONTRIBUTIONS_FRAGMENT } from "@mc/common/blocks/game-plan-feedback/contributions/contributions.gql";
import {
  GAME_PLAN_FEEDBACK_HOME_PURCHASE_DETAILS_FRAGMENT,
  GAME_PLAN_FEEDBACK_HOME_PURCHASE_DETAILS_PROFILE_FRAGMENT,
} from "@mc/common/blocks/game-plan-feedback/home-purchase-details/home-purchase-details.gql";
import { OTHER_REQUIREMENTS_FRAGMENT } from "@mc/common/blocks/onboarding/other-requirements/other-requirements.gql";

const PROFILE_FRAGMENT = gql`
  fragment PreferencesProfileFragment on Profile {
    _id
    ...PersonalDetailsProfileFragment
    ...ExistingPropertiesFragment
    ...InvestmentGoalsFragment
    ...HomePurchaseDetailsProfileFragment
    ...OtherRequirementsFragment
    services
    status
    callbackURL
    createdAt {
      formatted
    }
    completeSection {
      agreement
      existingProperties
      homePurchaseDetails
      investmentGoals
      payment
      personalDetails
      otherRequirements
    }
    agreement {
      changingGamePlan
      changingPropertyBrief
      termsAndConditions
    }
    isFamilyHomePurchasedFirst
  }
  ${PERSONAL_DETAILS_PROFILE_FRAGMENT}
  ${EXISTING_PROPERTIES_FRAGMENT}
  ${INVESTMENT_GOALS_FRAGMENT}
  ${HOME_PURCHASE_DETAILS_PROFILE_FRAGMENT}
  ${OTHER_REQUIREMENTS_FRAGMENT}
`;

const PREFERENCES_CLIENT_FRAGMENT = gql`
  fragment PreferencesClientFragment on Client {
    _id
    ...PersonalDetailsClientFragment
    ...HomePurchaseDetailsClientFragment
    firstName
    lastName
    isOnboardingConfirmed
    status
    profile(status: ACTIVE) {
      ...PreferencesProfileFragment
    }
  }
  ${PROFILE_FRAGMENT}
  ${PERSONAL_DETAILS_CLIENT_FRAGMENT}
  ${HOME_PURCHASE_DETAILS_CLIENT_FRAGMENT}
`;

export const GET_PROFILE_DETAILS = gql`
  query GetProfileDetails($id: String!) {
    client(id: $id) {
      ...PreferencesClientFragment
      profiles(statuses: [ACTIVE, ARCHIVED]) {
        ...PreferencesProfileFragment
      }
    }
  }
  ${PREFERENCES_CLIENT_FRAGMENT}
  ${PROFILE_FRAGMENT}
`;

export const PREFERENCES_UPDATE_CLIENT = gql`
  mutation PreferencesUpdateClientById($clientId: String!, $input: UpdateClientInput!) {
    updateClientById(clientId: $clientId, updateClientInput: $input) {
      ...PreferencesClientFragment
    }
  }
  ${PREFERENCES_CLIENT_FRAGMENT}
`;

export const PREFERENCES_UPDATE_PROFILE = gql`
  mutation PreferencesUpdateProfileById($profileId: String!, $input: ProfileInput!) {
    updateProfileById(profileId: $profileId, profileInput: $input) {
      ...PreferencesClientFragment
      profiles {
        ...PreferencesProfileFragment
      }
    }
  }
  ${PREFERENCES_CLIENT_FRAGMENT}
  ${PROFILE_FRAGMENT}
`;

export const PREFERENCES_CONFIRM_ONBOARDING = gql`
  mutation PreferencesConfirmOnboarding($clientId: String!, $startAt: DateTime!, $endAt: DateTime!) {
    confirmOnboarding(clientId: $clientId, startAt: $startAt, endAt: $endAt) {
      _id
      isOnboardingConfirmed
      status
      profiles {
        ...PreferencesProfileFragment
      }
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const GAME_PLAN_FEEDBACK_FRAGMENT = gql`
  fragment GamePlanFeedbackFragment on GamePlanFeedback {
    _id
    ...GamePlanFeedbackContributionsFragment
    ...GamePlanFeedbackHomePurchaseDetailsFragment
    round
    updatedAt {
      formatted
    }
    preferredOptions
    accumulatedInvestmentAmounts {
      formatted
    }
    comments
    dislikeReasons
  }
  ${GAME_PLAN_FEEDBACK_CONTRIBUTIONS_FRAGMENT}
  ${GAME_PLAN_FEEDBACK_HOME_PURCHASE_DETAILS_FRAGMENT}
`;

export const GET_GAME_PLAN_FEEDBACKS = gql`
  query GetGamePlanFeedbacks($clientId: String!, $round: Int) {
    gamePlanFeedbacks(clientId: $clientId, round: $round) {
      ...GamePlanFeedbackFragment
    }
    gamePlan(clientId: $clientId) {
      _id
      options {
        _id
        ...PreferredOptionFragment
      }
    }
    client(id: $clientId) {
      _id
      profile(status: ACTIVE) {
        _id
        ...GamePlanFeedbackHomePurchaseDetailsProfileFragment
        existingProperties {
          _id
          equityToUse {
            amount
          }
        }
      }
    }
  }
  ${GAME_PLAN_FEEDBACK_FRAGMENT}
  ${PREFERRED_OPTION_FRAGMENT}
  ${GAME_PLAN_FEEDBACK_HOME_PURCHASE_DETAILS_PROFILE_FRAGMENT}
`;

export const UPDATE_GAME_PLAN_FEEDBACK = gql`
  mutation UpdateGamePlanFeedback($gamePlanFeedbackId: String!, $gamePlanFeedbackInput: GamePlanFeedbackInput!) {
    updateGamePlanFeedback(gamePlanFeedbackId: $gamePlanFeedbackId, gamePlanFeedbackInput: $gamePlanFeedbackInput) {
      ...GamePlanFeedbackFragment
    }
  }
  ${GAME_PLAN_FEEDBACK_FRAGMENT}
`;
