import styled, { keyframes } from "styled-components/macro";
import { darkGrey, lightGrey } from "@mc/components/core/colours.styles";
import { rem } from "@mc/components/core/styles";

const bounceAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(0);
  }
`;

type BouncerGlobalWrapperProps = {
  isGlobal: boolean;
};

export const BouncerGlobalWrapperStyled = styled.div<BouncerGlobalWrapperProps>`
  ${({ isGlobal }) =>
    isGlobal &&
    `
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: ${darkGrey};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 100;
  `}
`;

export const BouncerWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
`;

export const MessageStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rem(24)};
`;

type BouncerDotProps = {
  light?: boolean;
};

export const BouncerDotStyled = styled.div<BouncerDotProps>`
  width: ${rem(16)};
  height: ${rem(16)};
  background: ${({ light }) => (light ? "white" : lightGrey)};
  border-radius: 50%;
  margin: 0 ${rem(6)};
  animation: ${bounceAnimation} 1.2s ease-in-out infinite;
  &:nth-of-type(2) {
    animation-delay: 0.3s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.6s;
  }
`;

export const VisuallyHiddenText = styled.p`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
