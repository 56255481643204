import { LoginStaffMutation } from "@mc/common/gql/types";

const KEY_NAME = process.env.REACT_APP_STAFF_TOKEN_KEY_NAME_IN_LOCALSTORAGE as string;

export function getAuthToken() {
  const data = getUserData();
  if (data) {
    return data.token;
  }
}

export function setAuthToken(token: string | undefined): void {
  if (!token) return;
  const data = getUserData();
  if (data) {
    setUserData(data.staff, token);
  }
}

export function removeAuthToken(): void {
  window.localStorage.removeItem(KEY_NAME);
}

export function setUserData(staff: LoginStaffMutation["loginStaff"]["staff"], token: string | undefined) {
  if (!token) return;
  window.localStorage.setItem(
    KEY_NAME,
    JSON.stringify({
      token,
      staff: {
        ...staff,
      },
    })
  );
}

export function getUserData(): LoginStaffMutation["loginStaff"] | undefined {
  const data = window.localStorage.getItem(KEY_NAME);
  if (data) {
    return JSON.parse(data);
  }
  return undefined;
}
