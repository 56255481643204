import { GetKeyDatesQuery, GetKeyDatesQueryVariables } from "@mc/common/gql/types";
import { Circle, CircleContainer, Container, Date, Title, VerticalLine } from "./key-dates.styles";

import { useMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_CLIENT_KEY_DATES } from "./key-dates.gql";
import { Bouncer } from "@mc/components/bouncer/bouncer";
import { Flex } from "@mc/components/grid/grid.styles";
import { Margin } from "@mc/components/core/spacing.styles";

const KeyDates = () => {
  const match = useMatch("/client/:clientId/*");
  const clientId = match?.params.clientId;

  const { data, loading } = useQuery<GetKeyDatesQuery, GetKeyDatesQueryVariables>(GET_CLIENT_KEY_DATES, {
    variables: {
      clientId: clientId!,
    },
  });

  if (loading) return <Bouncer />;

  const keyDates = [
    {
      date: data?.client?.createdAt?.formatted ?? "N/A",
      title: "Signed up",
    },
    {
      date: data?.client?.onboardingCompletedAt?.formatted ?? "N/A",
      title: "Onboarding complete",
    },
    {
      date: data?.client.onboardingConfirmedAt?.formatted ?? "N/A",
      title: "Onboarding confirmed",
    },
    {
      date: data?.client?.gamePlan?.options[0]?.publishedAt?.formatted ?? "N/A",
      title: "Game Plan published",
    },
    {
      date: data?.client?.gamePlan?.confirmedByClientAt?.formatted ?? "N/A",
      title: "Game Plan approved",
    },
    {
      date: data?.client?.purchases[0]?.briefStartedAt?.formatted ?? "N/A",
      title: "Search commenced",
    },
    {
      date: data?.client?.purchases[0]?.preApproval?.loans[0]?.expiry?.formatted ?? "N/A",
      title: "Pre-Approval expiry date",
    },
    {
      date:
        data?.client?.purchases[0]?.shortlistProperties[0]?.unconditionalExchange?.unconditionalExchangedAt
          ?.formatted ?? "N/A",
      title: "Unconditional exchanged",
    },
    {
      date: data?.client?.purchases[0]?.shortlistProperties[0]?.finalOutcome?.settlementDate?.formatted ?? "N/A",
      title: "Settled",
    },
  ];
  return (
    <Container>
      <Margin top={20}>
        <Flex direction="column" gap={20}>
          {keyDates.map((item, index) => (
            <Flex key={index}>
              <Date>{item.date}</Date>
              <CircleContainer>
                <Circle />
                {index !== keyDates.length - 1 && <VerticalLine />}
              </CircleContainer>
              <Title>{item.title}</Title>
            </Flex>
          ))}
        </Flex>
      </Margin>
    </Container>
  );
};

export default KeyDates;
