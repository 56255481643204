import { Fragment, ReactNode } from "react";
import { Switch as HeadlessSwitch } from "@headlessui/react";
import * as Styles from "./switch.styles";
import { FieldValues, RegisterOptions, useController, useFormContext, UseControllerProps } from "react-hook-form";

type SwitchButtonProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  name?: string;
  isDisabled?: boolean;
  isNeutral?: boolean;
};

export const SwitchButton = ({ checked, onChange, name, isDisabled, isNeutral }: SwitchButtonProps) => {
  return (
    <HeadlessSwitch
      as={Fragment}
      checked={checked}
      onChange={(value) => {
        if (isDisabled) return;
        onChange(value);
      }}
      name={name}
    >
      {({ checked }) => (
        <Styles.SwitchButton checked={checked} isDisabled={isDisabled} isNeutral={isNeutral}>
          <span />
        </Styles.SwitchButton>
      )}
    </HeadlessSwitch>
  );
};

type SwitchProps = {
  leftLabel?: ReactNode;
  rightLabel?: ReactNode;
  name?: string;
} & SwitchButtonProps;

export const Switch = ({ leftLabel, rightLabel, ...props }: SwitchProps) => {
  return (
    <HeadlessSwitch.Group as={Styles.SwitchWrapper}>
      {leftLabel && <HeadlessSwitch.Label>{leftLabel}</HeadlessSwitch.Label>}
      <SwitchButton {...props} />
      {rightLabel && <HeadlessSwitch.Label>{rightLabel}</HeadlessSwitch.Label>}
    </HeadlessSwitch.Group>
  );
};

type ControlledSwitchProps<FormData extends FieldValues> = {
  name: UseControllerProps<FormData>["name"];
  label: ReactNode;
  registerOptions?: RegisterOptions<FormData>;
};

export const ControlledSwitch = <FormData extends FieldValues>({
  name,
  label,
  registerOptions,
}: ControlledSwitchProps<FormData>) => {
  const { control } = useFormContext<FormData>();

  const { field } = useController({ control, name, rules: registerOptions ?? {} });

  return (
    <Switch
      name={name}
      checked={field.value as boolean}
      onChange={(value) => field.onChange(value)}
      rightLabel={label}
    />
  );
};
