import { gql } from "@apollo/client";

export const FILE_PREVIEW_FRAGMENT = gql`
  fragment FilePreviewFragment on File {
    _id
    url
    pdfPreviewUrl
    originalFileName
    externalId
  }
`;

const VOID_FRAGMENT = gql`
  fragment VoidFragment on Void {
    success
    message
  }
`;

export const UPLOAD_FILE = gql`
  mutation UploadFile($fileInput: FileInput!) {
    uploadFile(fileInput: $fileInput) {
      ...FilePreviewFragment
    }
  }
  ${FILE_PREVIEW_FRAGMENT}
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($fileId: String!) {
    deleteFile(fileId: $fileId) {
      ...VoidFragment
    }
  }
  ${VOID_FRAGMENT}
`;

export const SCHOOL_FRAGMENT = gql`
  fragment SchoolFragment on School {
    id
    schoolName
    schoolType
    icseaPercentile
    yearRange
    totalEnrolments
    boysEnrolled
    girlsEnrolled
    latitude
    longitude
  }
`;
